import {Autocomplete, TextField} from "@mui/material";

const __PLACEHOLDER__ = 'Estado civil';
const __AUTOCOMPLETE_ID__ = 'marital-status-combo-box';
const options = [
    {
        label: 'Soltero/a',
        value: 'single',
    },{
        label: 'Casado/a',
        value: 'married'
    }
]
const MaritalStatusSelect = ({ defaultValue, onChange, disabled, width = 0 }:{ defaultValue:any, onChange:any, disabled: boolean, width?:number }) => {
    const loading = false;

    return(
        <>
            <Autocomplete
                disabled={ disabled }
                disablePortal
                sx={{
                    width: width ? width : '100%'
                }}
                loading={ loading }
                loadingText="Cargando..."
                defaultValue={ defaultValue ? defaultValue : undefined }
                onChange={(event: any, newValue:any) => {
                    if(newValue) {
                        onChange( newValue.value );
                    }
                }}
                renderOption={( props:any, option ) => {
                    return(   <li {...props}>
                        {option.label}
                    </li> )
                }}
                id={ __AUTOCOMPLETE_ID__ }
                options={ options }
                renderInput={(params) => <TextField {...params} label={ __PLACEHOLDER__ } />}
            />
        </>
    )
}

export default MaritalStatusSelect;