import { Container, Grid, Paper, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { fetchRecords } from "../../actions/fetchRecords";
import PropertyType from "../../types/PropertyType";
import {
  Add,
  ChevronLeft,
  Delete,
  Edit,
  PhotoAlbum,
  PriceChange,
} from "@mui/icons-material";
import { BreadcrumbsBar } from "../../components/common/BreadcrumbsBar";
import StyledButton from "../../styled/StyledButton";
import {
  DeleteModal,
  useModalDelete,
} from "../../components/common/DeleteModal";
import { updateRecord } from "../../actions/updateRecord";

const componentDefaultProps = {
  title: "Propiedades",
  resource: "properties",
  addButtonText: "Nueva propiedad",
  formPath: "properties",
};

const __RESOURCE__ = "properties";

const PropertiesList = ({
  properties,
  breadcrumbs,
  title,
  loading,
  ActionButtons,
  ConfirmDialog,
  MainActionButton,
}: {
  loading?: boolean;
  ActionButtons?: any;
  properties?: Array<PropertyType>;
  breadcrumbs?: any;
  title?: string;
  ConfirmDialog?: any;
  MainActionButton?: any;
}) => {
  const [loadingHandler, setLoadingHandler] = useState<boolean>(
    loading ? loading : false
  );
  const [results, setResults] = useState<PropertyType[]>([]);
  const {
    handleModal,
    openModal,
    handleDelete,
    modalTitle,
    setModalTitle,
    setUid,
  } = useModalDelete(componentDefaultProps.resource);
  const navigate = useNavigate();

  useEffect(() => {
    getRecords().then();
  }, []);

  useEffect(() => {
    if (loading) {
      setLoadingHandler(loading);
    }
  }, [loading]);

  async function getRecords() {
    if (properties) {
      setResults(properties);
    } else {
      const result = await fetchRecords(componentDefaultProps.resource);

      if (result.error) {
        setLoadingHandler(false);
        return;
      }

      if (result.properties) {
        setLoadingHandler(false);
        setResults(result.properties);
      }
    }
  }

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nombre",
      flex: 1,
      editable: true,
    },
    {
      field: "isVisible",
      headerName: "Visible",
      renderCell: (cellValues) => {
        const currentRecords = [...results];

        const index = currentRecords.findIndex((item) =>
          item._id ? item._id === cellValues.id : item.id === cellValues.id
        );
        const handleChange = async () => {
          await updateRecord(
            __RESOURCE__,
            {
              isVisible:
                cellValues.value === "undefined" ? true : !cellValues.value,
            },
            cellValues.id
          );

          console.log("currentRecords ", currentRecords[index]);
          currentRecords[index].isVisible = !cellValues.value;

          setResults(currentRecords);
        };

        return (
          <Box
            sx={{
              width: "100%",
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            <Switch
              checked={results[index]?.isVisible}
              disabled={cellValues.value === "undefined"}
              onChange={handleChange}
            />
          </Box>
        );
      },
    },
    {
      field: "uid",
      headerName: "Acciones",
      width: 600,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <Box
            sx={{
              width: "100%",
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            {ActionButtons ? (
              ActionButtons(cellValues)
            ) : (
              <>
                <StyledButton
                  size="small"
                  color="primary"
                  variant="outlined"
                  startIcon={<PriceChange />}
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate(
                      `/dashboard/${componentDefaultProps.resource}/${cellValues.id}/zones`
                    );
                  }}
                >
                  Zonas/Etapas
                </StyledButton>
                <StyledButton
                  size="small"
                  color="primary"
                  variant="outlined"
                  startIcon={<PriceChange />}
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate(
                      `/dashboard/${componentDefaultProps.resource}/${cellValues.id}/financiamiento`
                    );
                  }}
                >
                  Financiamiento
                </StyledButton>
                <StyledButton
                  size="small"
                  color="primary"
                  variant="outlined"
                  startIcon={<PhotoAlbum />}
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate(
                      `/dashboard/${componentDefaultProps.resource}/${cellValues.id}/gallery`
                    );
                  }}
                >
                  Galería
                </StyledButton>
                <StyledButton
                  size="small"
                  color="primary"
                  variant="outlined"
                  startIcon={<Edit />}
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate(
                      `/dashboard/${componentDefaultProps.resource}/edit/${cellValues.id}`
                    );
                  }}
                >
                  Editar
                </StyledButton>
                <StyledButton
                  size="small"
                  color="error"
                  variant="outlined"
                  startIcon={<Delete />}
                  onClick={(event) => {
                    event.stopPropagation();

                    setModalTitle(cellValues.row.name); //Set modal title

                    setUid(cellValues.id.toString()); //Set-up the uid to delete

                    handleModal(); //Open modal
                  }}
                >
                  Eliminar
                </StyledButton>
              </>
            )}
          </Box>
        );
      },
    },
  ];

  console.log("DeleteModal ", DeleteModal);
  return (
    <>
      {ConfirmDialog !== undefined ? (
        ConfirmDialog()
      ) : (
        <DeleteModal
          handleDelete={handleDelete}
          handleModal={handleModal}
          openModal={openModal}
          modalTitle={modalTitle}
          getRecords={getRecords}
        />
      )}

      <Paper
        sx={{
          pt: {
            xs: 10,
            md: 12,
          },
        }}
        elevation={0}
      >
        <Container>
          <Grid
            alignContent="center"
            justifyContent="space-between"
            sx={{ mb: 4 }}
            container
          >
            <Grid
              xs={12}
              md={6}
              sx={{
                alignItems: "center",
                display: "flex",
                pb: {
                  xs: 4,
                  md: 0,
                },
              }}
              item
            >
              <BreadcrumbsBar
                currentPath={
                  breadcrumbs
                    ? breadcrumbs
                    : [{ label: `${componentDefaultProps.title}` }]
                }
              />
            </Grid>
            <Grid xs={12} md={6} item>
              {MainActionButton ? (
                MainActionButton()
              ) : (
                <StyledButton
                  fullWidth
                  variant="contained"
                  startIcon={<Add />}
                  onClick={() =>
                    navigate(`/dashboard/${componentDefaultProps.resource}/new`)
                  }
                >
                  {componentDefaultProps.addButtonText}
                </StyledButton>
              )}
            </Grid>
          </Grid>
          <Box sx={{ width: "100%", height: "100%" }}>
            <DataGrid
              loading={loadingHandler}
              autoHeight
              rows={results}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              getRowId={(row: any) => (row?._id ? row._id : row.id)}
            />
          </Box>
          <Grid
            sx={{
              mt: {
                xs: 8,
                md: 4,
              },
            }}
            justifyContent="center"
            container
          >
            <Grid xs={8} md={4} item>
              <StyledButton
                fullWidth
                sx={{ textTransform: "none" }}
                startIcon={<ChevronLeft />}
                variant="outlined"
                onClick={() => navigate("/dashboard")}
              >
                Volver
              </StyledButton>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </>
  );
};

export default PropertiesList;
