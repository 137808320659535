import { Container, Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import {useNavigate, useParams} from "react-router-dom";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {useEffect, useState} from "react";
import {fetchRecords} from "../../actions/fetchRecords";
import PropertyType from "../../types/PropertyType";
import {Add, ChevronLeft, Delete, Edit } from "@mui/icons-material";
import {BreadcrumbsBar} from "../../components/common/BreadcrumbsBar";
import StyledButton from "../../styled/StyledButton";
import {DeleteModal, useModalDelete} from "../../components/common/DeleteModal";

const componentDefaultProps = {
    title: 'Galería',
    resource: 'files',
    addButtonText: 'Subir imagen',
    formPath: 'gallery'
}

const GalleryList = () => {

    const [ loading, setLoading ] = useState<boolean>(true);
    const [ results, setResults ] = useState<PropertyType[]>([]);
    const {handleModal, openModal, handleDelete, modalTitle, setModalTitle, setUid, selectedItems, setSelectedItems, multipleDeletes } = useModalDelete( componentDefaultProps.resource );
    const navigate = useNavigate();
    const params = useParams();

    useEffect( () => {
        getRecords().then()
    },[]);

    async function getRecords() {
        try {
            const result = await fetchRecords( 'files/byProperty/' + params.uid );

            if(result.error) {
                setLoading(false);
                return;
            }

            if(result.files) {
                setLoading(false);
                setResults(result.files);
            }
        }catch (e) {
            console.log(e)
        }

    }

    const columns: GridColDef[] = [
        {
            field: 'url',
            flex: 1,
            headerName: 'Preview',
            renderCell: (cellValues) => {
                return (
                    <img src={ cellValues.row.url } width={ 200 } height={ 200 }/>
                );
            }
        },
        {
            field: 'uid',
            headerName: 'Acciones',
            width: 250,
            headerAlign: "center",
            renderCell: (cellValues) => {
                return (
                    <Box sx={{width: '100%', justifyContent: 'space-around', display: 'flex' }}>
                        <StyledButton
                            size="small"
                            color="primary"
                            variant="outlined"
                            startIcon={ <Edit/> }
                            onClick={(event) => {
                                event.stopPropagation();
                                navigate(`/dashboard/properties/${params.uid}/zones/edit/${cellValues.id}`)
                            }}
                        >
                            Editar
                        </StyledButton>
                        <StyledButton
                            size="small"
                            color="error"
                            variant="outlined"
                            startIcon={ <Delete/> }
                            onClick={(event) => {
                                event.stopPropagation();

                                setModalTitle( cellValues.row.name ); //Set modal title

                                setUid(cellValues.id.toString()); //Set-up the uid to delete

                                handleModal(); //Open modal
                            }}
                        >
                            Eliminar
                        </StyledButton>
                    </Box>
                );
            }
        }
    ];

    return(
        <>
            <DeleteModal
                handleDelete={handleDelete}
                handleModal={handleModal}
                openModal={openModal}
                modalTitle={modalTitle}
                getRecords={getRecords}
                selectedItems={ selectedItems }
                multipleDeletes={ multipleDeletes }
            />
            <Paper
                sx={{
                    pt: {
                        xs: 10,
                        md: 12
                    }
                }}
                elevation={ 0 }
            >
                <Container>
                    <Grid alignContent="center" justifyContent="space-between" sx={{ mb: 4 }} container>
                        <Grid
                            xs={ 12 }
                            md={ 8 }
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                pb: {
                                    xs: 4,
                                    md: 0
                                }
                            }}
                            item
                        >
                            <BreadcrumbsBar currentPath={[
                                { to: '/dashboard', label: 'Inicio' },
                                { label: `${componentDefaultProps.title}` },
                            ]}/>
                        </Grid>
                        <Grid xs={ 12 } md={ 4 } item>
                            <StyledButton
                                fullWidth
                                variant="contained"
                                startIcon={ <Add/> }
                                onClick={ () => navigate(  `/dashboard/properties/${ params.uid }/gallery/new` ) }
                            >
                                { componentDefaultProps.addButtonText }
                            </StyledButton>
                        </Grid>
                    </Grid>
                    <Box sx={{ width: '100%', height: '100%' }}>
                        {
                            selectedItems.length > 0 && (
                                <Box sx={{ width: '100%', height: 40, display: 'flex', justifyContent: 'right' }}>
                                    <StyledButton
                                        startIcon={<Delete/>}
                                        size="small"
                                        color="error"
                                        variant="contained"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleModal(); //Open modal
                                        }}
                                    >
                                        Eliminar seleccionados ({ selectedItems.length })
                                    </StyledButton>
                                </Box>
                            )
                        }
                        <DataGrid
                            loading={loading}
                            autoHeight
                            rows={results}
                            columns={columns}
                            pageSize={ 10 }
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                            checkboxSelection
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectedItems(newSelectionModel);
                            }}
                            selectionModel={selectedItems}
                        />
                    </Box>
                    <Grid sx={{
                        mt: {
                            xs: 8,
                            md: 4
                        }
                    }} justifyContent="center" container>
                        <Grid xs={ 8 } md={ 4 } item>
                            <StyledButton
                                fullWidth
                                sx={{ textTransform: 'none' }}
                                startIcon={ <ChevronLeft/> }
                                variant="outlined"
                                onClick={ () => navigate( '/dashboard' ) }
                            >
                                Volver
                            </StyledButton>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </>
    )
}

export default GalleryList;