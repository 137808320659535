import ClientsType from "../types/ClientsType";
import {useEffect, useState} from "react";
import {fetchRecord} from "../actions/fetchRecord";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import SellersType from "../types/SellersType";
import ContractsType from "../types/ContractsType";

interface ContractHTMLProps {
    sellers: SellersType[],
    contractId?: string,
    clientId: string,
    setHtmlData:any,
    htmlData: string,
    setContractData:any,
    editorData: any,
    setEditorData: any,

}

export const useContractHTML = () => {
    const [contractData, setContractData] = useState<ContractsType>();
    const [editorData, setEditorData] = useState<any>(undefined);
    const [htmlData, setHtmlData] = useState<string>(``);

    return {
        contractData,
        htmlData,
        setHtmlData,
        setContractData,
        editorData,
        setEditorData
    };
};

export const ContractHTML = ({
                                 sellers,
                                 clientId,
                                 setHtmlData,
                                 htmlData,
                                 contractId,
                                 editorData,
                                 setContractData,
                                 setEditorData
                             }: ContractHTMLProps) => {
    const [sellersData, setSellersData] = useState<SellersType[]>(sellers);
    const [clientData, setClientData] = useState<ClientsType | null>(null);

    const [sellersName, setSellersName] = useState<string>("");

    console.log('sellersData ', sellersData.length);
    useEffect(() => {
        updateHtmlContent();
    }, [sellers, sellersData, clientData]);

    useEffect(() => {
        getContract().then((contract: ContractsType) => {
            if (contract) {
                setContractData(contract);
                if (contract?.content) {
                    setHtmlData(contract.content);
                }
            }
        });
    }, [contractId]);

    const getContract = async () => {
        console.log('contractId ', contractId)
        try {
            const {contract} = await fetchRecord('contracts/getRecord', contractId);
            return contract;
        } catch (e) {
            console.log('error ', e);
        }
    };

    const sellersNameFormat = (sellers: SellersType[]) => {
        let name: string = '';
        if (sellers.length === 1) {
            return `<b>${sellers[0].name + sellers[0].parentalLastName + sellers[0].maternalLastName}</b>`;
        }
        sellers.map((seller, index) => {
            name = name + (`<b>${seller?.name.toUpperCase()} ${seller?.parentalLastName?.toUpperCase()} ${seller?.maternalLastName?.toUpperCase()}</b>` + (index === sellers.length - 2 ? ' y ' : ','));
        });

        setSellersName(name);
    };
    useEffect(() => {
        if (sellers) {
            sellers.map((seller: SellersType) => {
                if (seller?.uid) {
                    getSeller(seller.uid).then()
                }
            });
        }
    }, [sellers]);

    useEffect(() => {
        if (clientId && !contractId) {
            getClient().then();
            updateHtmlContent()
        }
    }, [clientId]);

    const getSeller = async (sellerId: string) => {
        const {seller} = await fetchRecord('sellers', sellerId);
        if (seller) {
            setSellersData(seller);
        }
    };

    const getClient = async () => {
        const {client} = await fetchRecord('clients', clientId);
        if (client) {
            setClientData(client);
        }
    };

    const updateHtmlContent = () => {
        const html = `<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="es" lang="es">
                        <head>
                          <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                          <title>6e6825bb-b12e-41d6-ac85-3c48b6d2311a</title>
                          <meta name="author" content=""/>
                          <style> * {
                            margin: 0;
                            padding: 0;
                            text-indent: 0;
                          }
                        
                          h1 {
                            color: black;
                            font-family: Arial, sans-serif;
                            font-style: normal;
                            font-weight: bold;
                            text-decoration: none;
                            font-size: 11pt;
                          }
                        
                          .p, p {
                            color: black;
                            font-family: Arial, sans-serif;
                            font-style: normal;
                            font-weight: normal;
                            text-decoration: none;
                            font-size: 11pt;
                            margin: 0pt;
                          }
                        
                          .s1 {
                            color: black;
                            font-family: Arial, sans-serif;
                            font-style: normal;
                            font-weight: bold;
                            text-decoration: underline;
                            font-size: 11pt;
                          }
                        
                          .s2 {
                            color: #0462C1;
                            font-family: Arial, sans-serif;
                            font-style: normal;
                            font-weight: bold;
                            text-decoration: none;
                            font-size: 11pt;
                          }
                        
                          .s3 {
                            color: black;
                            font-family: Arial, sans-serif;
                            font-style: normal;
                            font-weight: bold;
                            text-decoration: none;
                            font-size: 11pt;
                          }
                        
                          .s4 {
                            color: black;
                            font-family: Arial, sans-serif;
                            font-style: normal;
                            font-weight: bold;
                            text-decoration: none;
                            font-size: 7pt;
                            vertical-align: 3pt;
                          }
                        
                          .s5 {
                            color: black;
                            font-family: Arial, sans-serif;
                            font-style: normal;
                            font-weight: normal;
                            text-decoration: none;
                            font-size: 11pt;
                          }
                        
                          .s6 {
                            color: black;
                            font-family: Arial, sans-serif;
                            font-style: normal;
                            font-weight: normal;
                            text-decoration: none;
                            font-size: 1pt;
                          }
                        
                          table, tbody {
                            vertical-align: top;
                            overflow: visible;
                          }
                          </style>
                        </head>
                        <body><p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br/></p>
                        <h1 style="padding-left: 30pt;text-indent: 0pt;text-align: left;">CONTRATO PRIVADO DE CESIÓN DE DERECHOS DE POSESIÓN A
                          TITULO ONEROSO</h1>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p>
                        <p style="padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;">QUE CELEBRAN: POR UNA PARTE, ${
                                    sellersData.length > 1 ? 'LOS' : 'EL'
                                }
                        
                          ${sellersName}, QUIEN PARA EFECTOS DE ESTE CONTRATO SE LE DENOMINARÁ
                          COMO LA PARTE VENDEDORA”, Y, POR LA OTRA PARTE, EL <b>${clientData && clientData.name}, </b>QUIEN COMPARECE EN ESTE
                          ACTO POR SU PROPIO DERECHO, Y QUIEN EN LO SUCESIVO SE LE DENOMINARÁ &quot;LA PARTE COMPRADORA”; AL TENOR DE LAS
                          SIGUIENTES DECLARACIONES Y CLAUSULAS:</p>
                        <p class="s1" style="padding-top: 7pt;padding-left: 189pt;text-indent: 0pt;text-align: justify;">D E C L A R A C I O N E
                          S:</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p>
                        <h1 style="padding-left: 8pt;text-indent: 0pt;text-align: justify;">I.- <span class="p">Declara la parte “</span>LA
                          PARTE VENDEDORA<span class="p">”</span>, <span class="p">Bajo protesta de decir verdad</span>:</h1>
                        <p style="padding-top: 12pt;text-indent: 0pt;text-align: left;"><br/></p>
                        <p style="padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;">Que son personas físicas, con
                          personalidad jurídica propia, el cual se identifica mediante su credencial de elector expedida por el INSTITUTO
                          NACIONAL ELECTORAL, con número de folio <b></b> para <b>C. ABIMAEL ZAVALETA CUMPLIDO</b>, de estado civil
                          soltero y de ocupación Ingeniero y <b>C. CHRISTOPHER DÍAZ RAMOS, </b>de estado civil soltero y de ocupación
                          Ingeniero civil, con número de folio 1510090853359 expedido por el C. Edmundo Jacobo Molina en su calidad se
                          secretario General de dicho Instituto.</p>
                        <p style="padding-left: 8pt;text-indent: 0pt;line-height: 13pt;text-align: justify;">Los cuales, por sus generales,
                          tienen su domicilio en:</p>
                        <p style="padding-top: 6pt;padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: left;">C. ABIMAEL ZAVALETA
                          CUMPLIDO calle triunfo No. 475, fracc. Costa chica C.P. 71980, Municipio de Santa Pedro Mixtepec, Oaxaca.</p>
                        <p style="padding-left: 8pt;text-indent: 0pt;line-height: 149%;text-align: left;">C. CHRISTOPHER DÍAZ RAMOS calle
                          porvenir No. 113, fracc. Costa chica C.P. 71980, Municipio de San Pedro Mixtepec, Oaxaca.</p>
                        <p style="padding-top: 6pt;text-indent: 0pt;text-align: left;"><br/></p>
                        <p style="padding-left: 8pt;text-indent: 0pt;text-align: justify;">Declara la parte <b>VENDEDORA</b>: <b>${sellersNameFormat(sellers)}</b></p>
                        <h1 style="padding-top: 6pt;padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;">RAMOS<span
                                class="p">, que se ostenta para la celebración del presente contrato con el carácter de </span><u>Legítimos</u>
                          <u>propietarios y poseedores, </u><span class="p">del inmueble ubicado en la Comunidad de Loma Bonita, ubicado en la cabecera municipal de Santa María Colotepec, Pochutla, Oaxaca, fracción que se acredita del acta de posesión con número de </span>folio
                          9047 <span
                                  class="p">y expedida por el comisariado de Bienes Comunales de Santa María Colotepec, Pochutla, Oaxaca.</span>
                        </h1>
                        <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br/></p>
                        <h1 style="padding-left: 8pt;text-indent: 0pt;text-align: justify;">II.- <span class="p">Declara el </span>C. FRANCISCO
                          JAVIER JARQUÍN LÓPEZ <span class="p">en su carácter del “</span>COMPRADOR<span class="p">”</span>:</h1>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p>
                        <p style="padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;">Que es una persona física, con
                          personalidad jurídica propia, de estado civil <b>SOLTERO, </b>con número de teléfono <b>(+52)-954-588-82-49 </b><a
                                  href="mailto:jarquinfj@hotmail.com"
                                  style=" color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt;"
                                  target="_blank">y correo electrónico para recibir notificaciones </a><span
                                  style=" color: #0462C1; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: underline; font-size: 11pt;">jarquinfj@hotmail.com</span><span
                                  class="s2"> </span>y de ocupación <b>comerciante; </b>la cual se identifica mediante su credencial de
                          elector expedida por el INSTITUTO NACIONAL ELECTORAL, con número de folio 1508115373185, expedido por el C. Edmundo
                          Jacobo Molina en su calidad se secretario General de dicho Instituto.</p>
                        <p style="padding-top: 6pt;text-indent: 0pt;text-align: left;"><br/></p>
                        <p style="padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;">El cual tiene su domicilio en San
                          Andrés Copala S/N, Localidad de San Andrés Copala, c.p. 71981 y señala como referencia familiar a las C. Janai
                          Shadai Olguin Loaeza con número de contacto (+52)- 954-123-74-05 y C. Serena López García con número de contacto
                          (+52)-954-123-32-34.</p>
                        <p style="padding-top: 6pt;text-indent: 0pt;text-align: left;"><br/></p>
                        <h1 style="padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;">III. - <span class="p">Declaran las partes contar con la capacidad suficiente para la realización del acto jurídico consignado en el presente documento, y que se reconocen la personalidad con la se ostentan y conocer el inmueble sobre el cual se adquirirán los derechos parcelarios, por lo que manifiestan su conformidad con el otorgamiento del mismo y que además convienen en sujetarse a las siguientes: - - - - - - - - - - - -</span>
                        </h1>
                        <p style="padding-top: 6pt;text-indent: 0pt;text-align: left;"><br/></p>
                        <h1 style="padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;">IV.- <span class="p">En la celebración de este contrato no existe, dolo, error, mala fe o lesión a derecho alguno, “La parte compradora”, reconoce haber leído y entendido los alcances legales del presente contrato, sus derechos y obligaciones, ha leído el presente en presencia de los testigos y manifiestan bajo protesta de decir verdad que no se queda con interrogante algún sobre el predio, fracción o lote materia del presente, su ubicación, medidas, colindancias, legal procedencia, documentación y todo aquello que resulta de trascendencia para la legalidad y rescisión del presente instrumento.- Ambas partes establecen que en caso de fallecimiento de “La parte compradora”, antes del cumplimiento de todas y cada una de las obligaciones aquí contraída, este está de acuerdo con ceder los derechos y obligación a la C. Janai Shadai Olguin Loaeza con número de contacto (+52)-954-123-74-05 y C. Serena López García con número de contacto (+52)-954-123-32-34., quien podra aceptar y dar cumplimiento total del presente documento sin necesidad de mediar poder notarial o carta, y “La parte vendedora” deberá extender la CESIÓN DE DERECHOS POSESIONARIOS A TÍTULO ONEROSO, a su favor y nombre, al finiquitar el precio pactado por el inmueble objeto del presente instrumento jurídico.----------------------</span>
                        </h1>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p>
                        <h1 style="padding-left: 2pt;text-indent: 0pt;text-align: center;">C L A U S U L A S:</h1>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p>
                        <h1 style="padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;"><u>PRIMERA. - OBJETO:</u> <span
                                class="p">El presente contrato tiene como objeto la enajenación de derechos parcelarios, los cuales se trasladarán de la parte vendedora y la cual DECLARA Y OTORGA, QUE SE OBLIGA Y COMPROMETE A VENDER, ÚNICA, EXCLUSIVA E IRREVOCABLEMENTE en favor de la parte</span>
                        </h1>
                        <p style="padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;">compradora, los derechos
                          parcelarios que ampara el lote de terreno identificado como, <b>lote número 13 de la manzana 3, </b>el cual forma
                          parte de la <b>LOTIFICACIÓN AMANECER, </b>Ubicado en Loma Bonita Santa María Colotepec, Pochutla, Oaxaca, y cual se
                          señala en el denominado anexo identificado como número 1 A.</p>
                        <p style="padding-top: 7pt;text-indent: 0pt;text-align: left;"><br/></p>
                        <table style="border-collapse:collapse;margin-left:8.85pt" cellspacing="0">
                          <tr style="height:19pt">
                            <td style="width:166pt;border-top-style:solid;border-top-width:1pt;border-top-color:#DBDBDB;border-left-style:solid;border-left-width:1pt;border-left-color:#DBDBDB;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#C8C8C8;border-right-style:solid;border-right-width:1pt;border-right-color:#DBDBDB">
                              <p class="s3" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">L13, Manzana 3</p></td>
                            <td style="width:166pt;border-top-style:solid;border-top-width:1pt;border-top-color:#DBDBDB;border-left-style:solid;border-left-width:1pt;border-left-color:#DBDBDB;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#C8C8C8;border-right-style:solid;border-right-width:1pt;border-right-color:#DBDBDB">
                              <p style="text-indent: 0pt;text-align: left;"><br/></p></td>
                            <td style="width:166pt;border-top-style:solid;border-top-width:1pt;border-top-color:#DBDBDB;border-left-style:solid;border-left-width:1pt;border-left-color:#DBDBDB;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#C8C8C8;border-right-style:solid;border-right-width:1pt;border-right-color:#DBDBDB">
                              <p class="s3" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">213.67 m<span class="s4">2</span>
                              </p></td>
                          </tr>
                          <tr style="height:19pt">
                            <td style="width:166pt;border-top-style:solid;border-top-width:2pt;border-top-color:#C8C8C8;border-left-style:solid;border-left-width:1pt;border-left-color:#DBDBDB;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#DBDBDB;border-right-style:solid;border-right-width:1pt;border-right-color:#DBDBDB">
                              <p class="s3" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Al norte mide 10.01 metros</p>
                            </td>
                            <td style="width:166pt;border-top-style:solid;border-top-width:2pt;border-top-color:#C8C8C8;border-left-style:solid;border-left-width:1pt;border-left-color:#DBDBDB;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#DBDBDB;border-right-style:solid;border-right-width:1pt;border-right-color:#DBDBDB">
                              <p class="s5" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">y colinda con</p></td>
                            <td style="width:166pt;border-top-style:solid;border-top-width:2pt;border-top-color:#C8C8C8;border-left-style:solid;border-left-width:1pt;border-left-color:#DBDBDB;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#DBDBDB;border-right-style:solid;border-right-width:1pt;border-right-color:#DBDBDB">
                              <p class="s5" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Calle amanecer</p></td>
                          </tr>
                          <tr style="height:19pt">
                            <td style="width:166pt;border-top-style:solid;border-top-width:1pt;border-top-color:#DBDBDB;border-left-style:solid;border-left-width:1pt;border-left-color:#DBDBDB;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#DBDBDB;border-right-style:solid;border-right-width:1pt;border-right-color:#DBDBDB">
                              <p class="s3" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Al sur mide 10.00 metros</p></td>
                            <td style="width:166pt;border-top-style:solid;border-top-width:1pt;border-top-color:#DBDBDB;border-left-style:solid;border-left-width:1pt;border-left-color:#DBDBDB;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#DBDBDB;border-right-style:solid;border-right-width:1pt;border-right-color:#DBDBDB">
                              <p class="s5" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Y colinda con</p></td>
                            <td style="width:166pt;border-top-style:solid;border-top-width:1pt;border-top-color:#DBDBDB;border-left-style:solid;border-left-width:1pt;border-left-color:#DBDBDB;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#DBDBDB;border-right-style:solid;border-right-width:1pt;border-right-color:#DBDBDB">
                              <p class="s5" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">L-12</p></td>
                          </tr>
                          <tr style="height:19pt">
                            <td style="width:166pt;border-top-style:solid;border-top-width:1pt;border-top-color:#DBDBDB;border-left-style:solid;border-left-width:1pt;border-left-color:#DBDBDB;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#DBDBDB;border-right-style:solid;border-right-width:1pt;border-right-color:#DBDBDB">
                              <p class="s3" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Al oriente mide 20.60 metros</p>
                            </td>
                            <td style="width:166pt;border-top-style:solid;border-top-width:1pt;border-top-color:#DBDBDB;border-left-style:solid;border-left-width:1pt;border-left-color:#DBDBDB;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#DBDBDB;border-right-style:solid;border-right-width:1pt;border-right-color:#DBDBDB">
                              <p class="s5" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Y colinda con</p></td>
                            <td style="width:166pt;border-top-style:solid;border-top-width:1pt;border-top-color:#DBDBDB;border-left-style:solid;border-left-width:1pt;border-left-color:#DBDBDB;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#DBDBDB;border-right-style:solid;border-right-width:1pt;border-right-color:#DBDBDB">
                              <p class="s5" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Calle Brisas</p></td>
                          </tr>
                          <tr style="height:38pt">
                            <td style="width:166pt;border-top-style:solid;border-top-width:1pt;border-top-color:#DBDBDB;border-left-style:solid;border-left-width:1pt;border-left-color:#DBDBDB;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#DBDBDB;border-right-style:solid;border-right-width:1pt;border-right-color:#DBDBDB">
                              <p class="s3" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Al poniente mide 21.64</p>
                              <p class="s3" style="padding-top: 6pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">metros</p></td>
                            <td style="width:166pt;border-top-style:solid;border-top-width:1pt;border-top-color:#DBDBDB;border-left-style:solid;border-left-width:1pt;border-left-color:#DBDBDB;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#DBDBDB;border-right-style:solid;border-right-width:1pt;border-right-color:#DBDBDB">
                              <p class="s5" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Y colinda con</p></td>
                            <td style="width:166pt;border-top-style:solid;border-top-width:1pt;border-top-color:#DBDBDB;border-left-style:solid;border-left-width:1pt;border-left-color:#DBDBDB;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#DBDBDB;border-right-style:solid;border-right-width:1pt;border-right-color:#DBDBDB">
                              <p class="s5" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">L-14</p></td>
                          </tr>
                        </table>
                        <p style="padding-top: 6pt;text-indent: 0pt;text-align: left;"><br/></p>
                        <h1 style="padding-left: 8pt;text-indent: 0pt;line-height: 149%;text-align: justify;">SEGUNDA<span class="p">. – </span>DEL
                          PRECIO<span class="p">. el precio de la venta, lo constituye la cantidad total de $128,202.00 (CIENTO VEINTIOCHO MIL DOSCIENTOS DOS PESOS 00/100 M.N.) importe que &quot;la parte</span>
                        </h1>
                        <p style="padding-left: 8pt;text-indent: 0pt;text-align: justify;">compradora&quot;, deberá pagar precisamente en el
                          plazo establecido en la siguiente clausula.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p>
                        <h1 style="padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;">TERCERA. - SEGURIDAD Y GARANTÍA DE
                          LA OBLIGACIÓN DE PAGO<span class="p">. para seguridad y garantía de la obligación de pago que adquiere en este acto, &quot;la parte compradora&quot;, se obliga y compromete a entregar a &quot;la parte vendedora&quot;, en los plazos siguientes: El monto cual se señala en el denominado anexo identificado como número 1 B.</span>
                        </h1>
                        <p style="padding-top: 6pt;text-indent: 0pt;text-align: left;"><br/></p>
                        <p style="padding-left: 8pt;text-indent: 0pt;text-align: justify;">El pago de su primera mensualidad se realizará con
                          fecha: 28 de marzo de 2023.</p>
                        <p style="padding-top: 6pt;padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;">entregado
                          directamente en el domicilio de la “Inmobiliaria Puerto Escondido” ubicada en Calle Segunda norte #306, Sector
                          Reforma B, Centro, Puerto Escondido Oaxaca 71980.</p>
                        <p style="padding-top: 6pt;text-indent: 0pt;text-align: left;"><br/></p>
                        <h1 style="padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;">CUARTA. - RESCISIÓN A PRETEXTO DE
                          LESIÓN<span class="p">. La compraventa a que este contrato se refiere, no podrá ser rescindida en ningún tiempo a pretexto de lesión, en virtud de que el precio y formas de otorgarlo ha sido pactado y acordado por las partes de común acuerdo, previendo cualquier situación futura que pudiera poner en riesgo el acto jurídico que se consigna en el presente documento. ----------</span>
                        </h1>
                        <p style="padding-top: 6pt;text-indent: 0pt;text-align: left;"><br/></p>
                        <h1 style="padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;">QUINTA. - ENTREGA<span class="p">. &quot;la parte vendedora&quot; se obliga y compromete a entregar a &quot;la parte compradora&quot;, la posesión jurídica del lote o inmueble objeto de la presente, lote </span>01
                          de la manzana 3 , <span class="p">de la lotificación </span>AMANECER <span class="p">en el momento que se liquide la cantidad estipulada en la cláusula segunda, se obliga a cubrir todos los gastos, costos, derechos, impuestos, que se generen con motivo de la posesión de este a partir de que obtenga la posesión total, real y material de los lotes. “La parte vendedora” se compromete a delimitar los terrenos, objeto de este presente contrato respetando las respectivas mojoneras a fin de evitar invasiones u otro tipo de situaciones que pudieran llegar a ocurrir.</span>
                        </h1>
                        <p style="padding-top: 6pt;text-indent: 0pt;text-align: left;"><br/></p>
                        <h1 style="padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;">SEXTA. – DEL ACTA DE POSESIÓN<span
                                class="p">. Las partes contratantes pactan que el acta de posesión se expedirá una vez que se finiquite el pago del lote de terreno, y una vez hecho esto, se acudirá ante las autoridades Comunales de Santa María, Colotepec, y previo pago de derechos de la parte vendedora, se firmara de conformidad de la parte vendedora ante las autoridades comunales de dicho núcleo comunal y se procederá a expedir en favor de la parte compradora el acta de posesión definitiva en favor del comprador.</span>
                        </h1>
                        <p style="padding-top: 6pt;text-indent: 0pt;text-align: left;"><br/></p>
                        <h1 style="padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;">SÉPTIMA. - SANEAMIENTO<span
                                class="p">. &quot;la parte vendedora&quot; responderá en términos de ley a &quot;la parte compradora”, del saneamiento para el caso de evicción.</span>
                        </h1>
                        <p style="padding-top: 6pt;text-indent: 0pt;text-align: left;"><br/></p>
                        <h1 style="padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;">OCTAVA. - PENA CONVENCIONAL<span
                                class="p">. En caso de no hacer el respectivo pago, en la fecha señalada el pago generará una penalización del 5% de interés moratorio y deberá pagarse antes del vencimiento de la siguiente mensualidad. Igualmente, ambas partes de común acuerdo establecen para el caso de incumplimiento de las obligaciones que respectivamente les corresponde, acuerdan que: en caso de que &quot;la parte compradora&quot;, incumpla con alguna de sus obligaciones, especialmente para el caso de que no hubiese realizado dos pagos consecutivos de los que se refiere la cláusula tercera o no quisiere</span>
                        </h1>
                        <p style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><br/></p>
                        <p style="padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;">recibir el lote o inmueble objeto
                          de la presente, este contrato de compraventa quedara sin efecto legal alguno, teniendo la facultad &quot;la parte
                          vendedora&quot; de enajenar a título oneroso el lote inmueble objeto de la presente a un tercero, quien será
                          adquirente de buena fe, con la única obligación por parte de &quot;la parte vendedora&quot; de realizar la
                          devolución de las cantidades entregadas por &quot;la parte compradora&quot;, excepto la cantidad establecida como
                          pena convencional siendo ésta la equivalente al veinticinco por ciento del valor de la operación total. asimismo,
                          &quot;la parte compradora&quot; acepta, que por ningún motivo podrá reclamar daños y perjuicios o cualquier otra
                          prestación diversa, por lo que, se hace constar que para el caso de que &quot;la parte compradora&quot; rehusare
                          recibir el excedente de las cantidades entregadas, &quot;la parte vendedora&quot; únicamente tendrá la obligación de
                          consignar en el juzgado respectivo a favor de &quot;la parte compradora&quot;, la cantidad que le corresponda de
                          acuerdo a la presente cláusula y notificada que sea, dicha consignación se tendrá por ambas partes como finiquito de
                          cuentas y sin derecho a reclamación alguna por parte de los aquí contratantes, es decir, no tendrán cosa ni cantidad
                          alguna que reclamar en materia agraria, civil, penal, mercantil, o de cualquier otra índole, y en razón de los
                          términos a que se contrae esta cláusula.</p>
                        <p style="padding-top: 6pt;text-indent: 0pt;text-align: left;"><br/></p>
                        <h1 style="padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;">NOVENA. - <span class="p">Las partes que intervienen en el presente contrato, se someten a la jurisdicción y Tribunales de Puerto Escondido, Mixtepec, Juquila, Oaxaca, renunciando al que por domicilio en el futuro les corresponda, en todo lo relacionado a la interpretación y al cumplimiento del mismo. - - - - - - - - - - - - -</span>
                        </h1>
                        <p style="padding-top: 6pt;text-indent: 0pt;text-align: left;"><br/></p>
                        <p style="padding-left: 8pt;text-indent: 0pt;line-height: 150%;text-align: justify;">Ambas partes estando conformes con
                          el contenido y clausulado del presente contrato, el cual ratifican en todo su contenido, por no contener cláusula
                          contraria a la moral y al derecho y para constancia lo firman ante la presencia de los testigos, el día 05 de abril
                          dos mil veintitres al margen en cada una de sus hojas y al calce en esta última para todos los efectos legales a que
                          haya lugar.</p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p>
                        <h1 style="padding-left: 2pt;text-indent: 0pt;text-align: center;">VENDEDOR VENDEDOR</h1>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p>
                        <p class="s6" style="padding-left: 8pt;text-indent: 0pt;line-height: 1pt;text-align: left;"></p>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p>
                        <h1 style="padding-top: 4pt;padding-left: 19pt;text-indent: 0pt;text-align: left;">C. ABIMAEL ZAVALETA CUMPLIDO</h1>
                        <h1 style="padding-top: 6pt;padding-left: 19pt;text-indent: 0pt;text-align: left;">C. CHRISTOPHER DÍAZ RAMOS</h1>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p>
                        <h1 style="padding-left: 62pt;text-indent: 0pt;text-align: left;">COMPRADOR TESTIGO</h1>
                        <p style="text-indent: 0pt;text-align: left;"><br/></p>
                        <p class="s6" style="padding-left: 8pt;text-indent: 0pt;line-height: 1pt;text-align: left;"></p>
                        <h1 style="padding-top: 7pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">C. FRANCISCO JAVIER JARQUÍN LOPEZ</h1>
                        <h1 style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">C. FRANCISCO MARTINEZ CORTES</h1>
                        </body>
                    </html>`;
        setHtmlData(html);
    };

    console.log('htmlData ', htmlData);
    return (
        <>
            {
                htmlData && (
                    <CKEditor
                        // @ts-ignore
                        editor={Editor}
                        data={htmlData}

                        //data={ContractHTML}
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            setEditorData(editor);
                        }}
                        onChange={(event, editor) => {
                            //setEditorData(editor);
                            console.log(event);
                        }}
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                        }}
                    />
                )
            }

        </>
    );
};

export default ContractHTML;