import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import {Paper} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import formatNumberToCurrency from "../helpers/formatNumberToCurrency";
import React, {useEffect, useState} from "react";
import PaymentsPlanType from "../types/PaymentPlansType";
import dayjs from "dayjs";
import PropertyType from "../types/PropertyType";
import {fetchRecord} from "../actions/fetchRecord";
import ItemsSelect from "./common/ItemsSelect";
import { updateRecord } from "../actions/updateRecord";
import { postRecord } from "../actions/postRecord";
import PaymentsType from "../types/PaymentsType";
import PaymentPlansType from "../types/PaymentPlansType";

type CreatablePaymentsPlanType = {
    propertyId: string,
    paymentsPlan: any,
    setPaymentsPlan: any,
    financingPlan: any,
    setFinancingPlan: any,
    propertyData: any,
    setPropertyData: any
}

export const useCreatablePaymentsPlan = () => {
    const [paymentsPlan, setPaymentsPlan] = useState<any>();
    const [financingPlan, setFinancingPlan] = useState<any>();
    const [propertyData, setPropertyData] = useState<PropertyType | null>();
    const [totalPrice, setTotalPrice] = useState<any>();
    const valueFrom =  dayjs(Date.now());
    useEffect(() => {
        calculateMonthlyPayments()
    }, [financingPlan]);

    const savePaymentPlan = async (contractId:string, paymentPlanId?:string) => {
        console.log('contractId ', contractId)

        if(!contractId || !propertyData?.id) {
            return;
        }
        let result:PaymentPlansType | null = null;
        const data:PaymentsPlanType = {
            contract: contractId,
            payments: paymentsPlan,
            property: propertyData.id,
            total_financing_months: financingPlan.total_financing_months,
            total_price: totalPrice
        }

        try {
            if(paymentPlanId) {
                result = await updateRecord('paymentPlans', data , paymentPlanId);
            } else {
                result = await postRecord('paymentPlans', data);
            }
        } catch (e) {
            console.log(e)
        }

        return result;
    }

    const calculateMonthlyPayments = () => {
        if(!financingPlan) {
            return;
        }
        let arrayMonths: PaymentsType[] = [];
        let currentDate: dayjs.Dayjs | undefined = valueFrom;
        let pricePerSquareMeter = financingPlan.price_per_square_meter;
        let propertyArea = propertyData?.area ? propertyData.area : 0;
        let totalPrice = pricePerSquareMeter * propertyArea;
        setTotalPrice(totalPrice);
        let remainingAmount = totalPrice;
        let amount =  totalPrice / financingPlan.total_financing_months;
        for (let index = 1; index <= financingPlan?.total_financing_months; index++) {
            remainingAmount = remainingAmount - amount

            arrayMonths.push({
                payment_number: index,
                due_payment_date: currentDate?.toString(),
                amount,
                remaining_amount: remainingAmount
            });
            currentDate = currentDate?.add(1,'month')
        }

        setPaymentsPlan(arrayMonths);
    }

    return {
        paymentsPlan,
        setPaymentsPlan,
        financingPlan,
        setFinancingPlan,
        propertyData,
        setPropertyData,
        savePaymentPlan
    }
}

export const CreatablePaymentsPlan = ({
                                          propertyId,
                                          paymentsPlan,
                                          setPaymentsPlan,
                                          financingPlan,
                                          setFinancingPlan,
                                          propertyData,
                                          setPropertyData
                                      }:CreatablePaymentsPlanType) => {
    const [checkIcons, setCheckIcons] = useState<string | null>(null);

    useEffect(() => {
        getRecord().then();
    }, [propertyId]);

    const getRecord = async () => {
           const {property} = await fetchRecord('properties', propertyId);
           if(property) {
               setPropertyData(property)
           }
    }

    const setWrittenPrice = () => {
    };
    return(
        <>
            {
                propertyData && (
                    <ItemsSelect defaultValue={undefined}
                                 onChange={(e:any) => {
                                     setFinancingPlan(e);
                                 }}
                                 disabled={false}
                                 resource={""}
                                 items={propertyData?.financing_plans}
                                 placeholder="Plan de pagos a elegir"
                                 autocompleteId="meses"
                                 field="name"
                                 setCompleteObject
                    />
                )
            }
            {
                paymentsPlan && (
                    <>
                        <Typography>Esquema de pagos</Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 400}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Pago No.</TableCell>
                                        <TableCell>Fecha</TableCell>
                                        <TableCell>A pagar</TableCell>
                                        <TableCell>Restante</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paymentsPlan.map((selectableMonth: any, index: number) => (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                "&:last-child td, &:last-child th": {
                                                    border: 0,
                                                },
                                            }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {selectableMonth?.payment_number
                                                    && selectableMonth?.payment_number
                                                }
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {selectableMonth?.due_payment_date
                                                    && selectableMonth?.due_payment_date
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {selectableMonth?.months === 0
                                                    ? "N/A"
                                                    : formatNumberToCurrency?.format(selectableMonth?.amount)}
                                            </TableCell>
                                            <TableCell>
                                                <p>{
                                                    formatNumberToCurrency?.format(selectableMonth?.remaining_amount)
                                                }</p>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )
            }

        </>
    )
}