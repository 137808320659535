import { OverridableStringUnion } from '@mui/types';
import { Box, Fab, FabPropsColorOverrides, PropTypes } from '@mui/material';
import React from 'react';


const FloatingButton = ({mainAction, Icon, color, ariaLabel, right}:{mainAction:() => void, Icon:React.ElementType, color?: OverridableStringUnion<PropTypes.Color, FabPropsColorOverrides>, ariaLabel:string, right?: number}) => {
  const style = {
    margin: 0,
    top: 'auto',
    right: right ? right : 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
};

  return (
    <Box sx={{ '& > :not(style)': { m: 1 } }}>
        <Fab
            sx={style}
            onClick={mainAction}
            color={color}
            aria-label={ariaLabel}
        >
            <Icon/>
        </Fab>
    </Box>
  )
}

export default FloatingButton;
