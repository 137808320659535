import { Route } from 'react-router-dom';
import * as React from 'react';
import ReceiptsList from "../pages/accounting/receipts/ReceiptsList";
import ReceiptsForm from "../pages/accounting/receipts/ReceiptsForm";
import Receipt from '../pages/accounting/receipts/Receipt';

export const useReceiptsRoutes = () => (
    <React.Fragment>
        <Route path="/contabilidad/recibos" element={<ReceiptsList/>}/>
        <Route path="/contabilidad/recibos/:uid" element={<Receipt/>}/>
        <Route path="/contabilidad/recibos/nuevo" element={<ReceiptsForm/>}/>
        <Route path="/contabilidad/recibos/editar/:uid" element={<ReceiptsForm/>}/>
    </React.Fragment>
)