import {MonthlyPaymentsType} from "../types/MonthlyPaymentsType";

const calculateMonthlyPayments = (area:number, selectableMonthsArray:any, withFormat:boolean = false) => {
    const monthly_payments:Array<MonthlyPaymentsType> = [];

    selectableMonthsArray.map((months:any) => {
        const ppsm:number = parseFloat( months.price_per_square_meter );
        let amount:string;
        if( months.selectable_months !== 0 ) {
            amount = ((ppsm * area) / months.selectable_months).toFixed(2)
        }else{
           amount = (ppsm * area).toFixed(2)
        }

        monthly_payments.push({
            months: months.selectable_months,
            amount: parseFloat( amount )
        });
    })

    return monthly_payments
}

export default calculateMonthlyPayments;