import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Stack} from "@mui/material";
import { deleteRecord } from "../../actions/deleteRecord";
import {useState} from "react";
import {DeleteForever} from "@mui/icons-material";
import StyledButton from "../../styled/StyledButton";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        md: 700,
        xs: 350
    },
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};


export const useModalForm = ( resource:string ) => {
    const [ openModalForm, setOpenModalForm ] = React.useState(false);
    const [ modalTitle, setModalTitle ] = useState<string>(' ');
    const [ uid, setUid ] = useState<string | null>(null);
    const handleModalForm = () => setOpenModalForm(!openModalForm);

    const handleDelete = async () => {
        if(uid) {
            await deleteRecord( resource, uid )
            setOpenModalForm(false); //Close modal if is open
        }
    }

    return {
        openModalForm,
        handleModalForm,
        handleDelete,
        modalTitle,
        setModalTitle,
        setUid
    }
}

type ModalFormType = {
    handleModalForm: any;
    openModalForm: any;
    handleDelete: any;
    modalTitle: string;
    getRecords: any;
    ChildModalForm: React.ReactElement;
    customDismiss?: any;
}

export const ModalForm = ({
    handleDelete,
    handleModalForm,
    openModalForm,
    modalTitle,
    getRecords,
    ChildModalForm,
    customDismiss
}:ModalFormType) => {

    return(
        <Modal
            open={openModalForm}
            onClose={handleModalForm}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography
                    sx={{ mb: 4 }}
                    align="center"
                    variant="h5"
                >
                    { modalTitle }
                </Typography>
                { /* TO DO: Implement custom dismiss for modal form */ }
                { ChildModalForm  }
            </Box>
        </Modal>
    )
}