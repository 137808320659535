import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import {Card, CardContent, IconButton, Stack} from "@mui/material";
import ClientsForm from "../pages/clients/ClientsForm";
import {Add, Delete, Edit} from "@mui/icons-material";
import {blue, red} from "@mui/material/colors";
import StyledButton from "../styled/StyledButton";
import {useState} from "react";
import BeneficiariesType from "../types/BeneficiariesType";
import {fetchRecords} from "../actions/fetchRecords";
import axios, {AxiosRequestConfig, AxiosRequestHeaders} from "axios";
const { REACT_APP_API_HOST } = process.env;

type BeneficiaryShapeType = {
    data: BeneficiariesType;
    discardOnCancel: boolean;
    redirect?: boolean;
    contract?: string,
    isBeneficiary: boolean,
    editMode: boolean;
}
export const useBeneficiaries = () => {
    const [beneficiaries, setBeneficiaries] = useState<Array<BeneficiaryShapeType>>([]);
    const uploadToServer = async (contractId:string) => {
        const beneficiariesUidArray:Array<string> = [];
        const requests:Array<any> = [];
        const authToken = localStorage.getItem('token');
        if(!authToken || contractId) {
            return [];
        }
        beneficiaries.map((beneficiary:BeneficiaryShapeType) => {

            beneficiary.contract = contractId;
            beneficiary.isBeneficiary = true;

            const headers:AxiosRequestHeaders = {
                "Content-Type": "application/json",
                "x-token": authToken
            };

            requests.push({
                endpoint: `${ REACT_APP_API_HOST }clients`,
                data: beneficiary,
                config: {
                    headers
                },
            })
            return requests;
        })
        try {
            const result = await axios.all(requests.map((req:any) => axios.post(
                req.endpoint,
                req.data,
                req.config
            )));

            result.map((response:any) => {
                if(response.status === 200) {
                    const { uid } = response.data.client;
                    beneficiariesUidArray.push( uid );
                }
            });
        }catch (e) {
            console.log('Error while creating new beneficiaries ', e);
        }

        return beneficiariesUidArray;
    }
    return {
        beneficiaries,
        setBeneficiaries,
        uploadToServer
    }
}

export const Beneficiaries = ({
                                  beneficiaries,
                                  setBeneficiaries,
                                  redirect
}:{
    redirect:boolean,
    beneficiaries:any,
    setBeneficiaries:any
}) => {
    const theme = useTheme();
    const fileName = '';
    const [enableAddBeneficiaryButton, setEnableAddBeneficiaryButton] = useState<boolean>(true);

    const saveBeneficiaryInfo = (beneficiaryData:any, index:number) => {
        const beneficiariesArray = [...beneficiaries];
        beneficiaryData.editMode = false;
        beneficiariesArray[index] = beneficiaryData;
        setBeneficiaries(beneficiariesArray);
        setEnableAddBeneficiaryButton(true)
    }

    const toggleEditMode = (index:number) => {
        const beneficiariesArray = [...beneficiaries];

        if( beneficiariesArray[index].discardOnCancel ) {
            setEnableAddBeneficiaryButton(true);
            beneficiariesArray.pop();
        } else {
            setEnableAddBeneficiaryButton(false);
            beneficiariesArray[index].editMode = !beneficiariesArray[index].editMode;
        }
        setBeneficiaries(beneficiariesArray);
    }

    async function getBeneficiaries() {
        const { clients } = await fetchRecords( `clients/isBeneficiary` );

        const myClients = clients.map(( client:any, index:number ) => {
            return client.editMode = false
        });

        setBeneficiaries(myClients);
    }

    const addBeneficiary = () => {
        setEnableAddBeneficiaryButton(false);
        setBeneficiaries((prevState:any) => [
            ...prevState,
            {
                data: {
                    name: '',
                    parentalLastName: '',
                    maternalLastName: '',
                    homeAddress: '',
                },
                editMode: true,
                discardOnCancel: true,
            }
        ])
    }

    const handleDelete = (index:number) => {
        const beneficiariesArray = [...beneficiaries];
        beneficiariesArray.splice(index,1);
        setBeneficiaries(beneficiariesArray);
    }
    const ContentCardBeneficiary = ({...props}) => {
        const beneficiaryObject = props.beneficiaryObject;
        const index = props.index;

        return (
        <Card  sx={{ width: '100%', position: 'relative', overflow: 'unset' }}>
            <CardContent>
                {
                    !beneficiaryObject?.editMode &&
                    <Box sx={{
                        position: 'absolute',
                        top: -5,
                        right: -5,
                        zIndex: 10
                    }}>
                        <Stack spacing={ 2 } direction="row">
                            <IconButton
                                onClick={
                                    () => toggleEditMode(index)
                                }
                                sx={{backgroundColor: blue[700], color: 'white'}}
                                size="small">
                                <Edit/>
                            </IconButton>
                            <IconButton
                                onClick={
                                    () => handleDelete(index)
                                }
                                sx={{backgroundColor: red[700], color: 'white'}} size="small">
                                <Delete/>
                            </IconButton>
                        </Stack>
                    </Box>
                }

                {
                    beneficiaryObject?.editMode ? (
                        <ClientsForm customDismiss={() => toggleEditMode(index)}
                                     getRecords={getBeneficiaries}
                                     customSave={(data:any) => saveBeneficiaryInfo(data, index)}
                                     embedded
                                     isBeneficiary/>
                    ) : (
                        <>
                            <p>
                                { beneficiaryObject.name }
                            </p>
                        </>
                    )

                }
            </CardContent>
        </Card>
    )}
    return(
        <Stack
            sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'end',
                flexDirection: 'column',
                p: 3,
                borderWidth: 2,
                borderRadius: 2,
                height: '100%',
                borderColor: theme.palette.primary.main,
                borderStyle: fileName ? 'solid' : 'dashed',

            }}
            spacing={ 2 }
        >
            {
                beneficiaries.map((beneficiary:any, index: number) => {
                    return <ContentCardBeneficiary key={ index } index={index} beneficiaryObject={beneficiary}/>
                })
            }

            {
                enableAddBeneficiaryButton && (
                    <StyledButton
                        onClick={
                            () => addBeneficiary()
                        }
                        sx={{
                            width: beneficiaries.length > 0 ? 400 : '100%'
                        }}
                        variant="outlined"
                        startIcon={
                            <Add/>
                        }
                    >
                        {
                            beneficiaries.length === 0 ? 'Añadir beneficiario' : 'Añadir otro beneficiario'
                        }
                    </StyledButton>
                )
            }

        </Stack>
    )
}