import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from "@mui/icons-material/Home";
import {
    Link as RouterLink,
} from 'react-router-dom';

export const BreadcrumbsBar = ({ currentPath }:{ currentPath:any }) => {

    return(
        <Breadcrumbs separator="›" aria-label="breadcrumb">

            <Link underline="hover"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="inherit"
                  to="/dashboard"
                  component={ RouterLink }
            >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
            </Link>
            { currentPath.map( (item:any, index:number ) => {

                if( item.to ) {
                    return  (
                        <Link underline="hover"
                              sx={{ display: 'flex', alignItems: 'center' }}
                              color="inherit"
                              to={ item.to }
                              component={ RouterLink }
                        >
                            { item.label }
                        </Link>
                    )
                }else {
                    return  <Typography key={ index } color="text.primary">{ item.label }</Typography>
                }
             } ) }
        </Breadcrumbs>
    )
}