import {Route} from "react-router-dom";
import ClientsForm from "../pages/clients/ClientsForm";
import SellersList from "../pages/accounting/sellers/SellersList";
import SellersForm from "../pages/accounting/sellers/SellersForm";

export const useSellerRoutes = () => {
    return(
        <>
            <Route path="/contabilidad/vendedores" element={<SellersList/>}/>
            <Route path="/contabilidad/vendedores/nuevo" element={<SellersForm/>}/>
            <Route path="/contabilidad/vendedores/editar/:uid" element={<SellersForm/>}/>
        </>
    )
}