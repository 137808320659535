import React from "react";
import PropertyType from "../../types/PropertyType";
import { fetchRecords } from "../../actions/fetchRecords";
import { useParams } from "react-router-dom";
import PropertiesList from "../properties/PropertiesList";
import { dashboardUrlBase } from "../../constants/site";
import { categoriesBaseUrl } from "../../routes/useCategoriesRoutes";
import { fetchRecord } from "../../actions/fetchRecord";
import CategoryType from "../../types/CategoryType";
import StyledButton from "../../styled/StyledButton";
import { useModalDelete } from "../../components/common/DeleteModal";
import {
  ConfirmationModal,
  useConfirmationModal,
} from "../../components/common/ConfirmationModal";
import { Add, Remove } from "@mui/icons-material";
import { updateRecord } from "../../actions/updateRecord";
import { Box } from "@mui/material";
import {
  PropertiesSelect,
  usePropertiesSelect,
} from "../../components/common/PropertiesSelect";

const CategoriesPropertiesList = () => {
  const [propertiesArray, setPropertiesArray] =
    React.useState<PropertyType[]>();

  const confirmationModalProps = useConfirmationModal();
  const propertiesSelectProp = usePropertiesSelect();

  const [categoryData, setCategoryData] = React.useState<CategoryType>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [selectedProperty, setSelectedProperty] = React.useState();

  const { category } = useParams();
  React.useEffect(() => {
    if (category) {
      getCategoryData().then();
    }
  }, []);

  const getCategoryData = async () => {
    const result = await fetchRecord("categories", category);

    if (result?.category?.child_properties) {
      setPropertiesArray(result?.category?.child_properties);
    } else {
      const { properties } = await fetchRecords(
        `properties/byCategory/${category}`
      );

      const propertiesIdsArray = extractIdsFromProperties(properties);
      await updateRecord(
        "categories",
        {
          ...result?.category,
          child_properties: propertiesIdsArray,
        },
        result?.category?.uid
      );

      setPropertiesArray(properties);
    }
    setCategoryData(result.category);

    setLoading(false);
  };

  const extractIdsFromProperties = (properties: PropertyType[]) => {
    const propertiesIdsTempArray: string[] = [];

    properties?.map((property) => {
      const propertyId = property?.id || property?._id;

      if (propertyId) {
        propertiesIdsTempArray.push(propertyId);
      }
    });

    return propertiesIdsTempArray;
  };
  const handleAddPropertyToCategory = async (
    propertyToAdd: PropertyType | undefined
  ) => {
    if (!propertyToAdd) {
      return;
    }
    await updateRecord(
      "properties",
      {
        category: categoryData?.id,
      },
      propertyToAdd
    );
    if (propertiesArray) {
      const childProperties = extractIdsFromProperties(propertiesArray);
      await updateRecord(
        "categories",
        {
          ...categoryData,
          child_properties: [...childProperties, propertyToAdd],
        },
        categoryData?.uid
      );

      window.location.reload();
    }
  };

  const handleRemovePropertyFromCategory = async () => {
    if (propertiesArray) {
      const childProperties = extractIdsFromProperties(propertiesArray);
      if (childProperties && selectedProperty) {
        const index = childProperties.findIndex(
          (item) => item === selectedProperty
        );

        childProperties.splice(index, 1);
      }

      await updateRecord(
        "categories",
        {
          ...categoryData,
          child_properties: childProperties,
        },
        categoryData?.uid
      );
    }
  };
  const DeleteModal = () => (
    <ConfirmationModal
      handleAction={handleRemovePropertyFromCategory}
      getRecords={getCategoryData}
      actionButtonTitle="Quitar de la categoria"
      color="error"
      {...confirmationModalProps}
    />
  );

  const MainActionButton = () => (
    <Box display="flex" sx={{}}>
      <PropertiesSelect
        customLabel="Propiedades"
        onChange={(e) => propertiesSelectProp.setSelectedProperty(e)}
        disabled={false}
      />
      <StyledButton
        onClick={() =>
          handleAddPropertyToCategory(propertiesSelectProp.selectedProperty)
        }
        fullWidth
        variant="contained"
        startIcon={<Add />}
      >
        Agregar propiedad a categoria
      </StyledButton>
    </Box>
  );
  const ActionButtons = (rowData: any) => (
    <>
      <StyledButton
        size="small"
        color="error"
        variant="outlined"
        startIcon={<Remove />}
        onClick={(event) => {
          event.stopPropagation();
          const propertyId = rowData?.row?._id || rowData?.row?.id;

          setSelectedProperty(propertyId);
          confirmationModalProps.setModalTitle(
            `¿Está seguro que desea quitar la propiedad ${
              rowData?.row?.name
            } de la categoría ${categoryData && categoryData.name}`
          );
          confirmationModalProps.handleModal(); //Open modal
        }}
      >
        Quitar de la categoría
      </StyledButton>
    </>
  );
  return (
    <>
      {propertiesArray && (
        <PropertiesList
          properties={propertiesArray}
          loading={false}
          breadcrumbs={[
            {
              to: `/${dashboardUrlBase}/${categoriesBaseUrl}`,
              label: "Categorias",
            },
            { label: `Propiedades de ${categoryData?.name}` },
          ]}
          ActionButtons={ActionButtons}
          ConfirmDialog={DeleteModal}
          MainActionButton={MainActionButton}
        />
      )}
    </>
  );
};

export default CategoriesPropertiesList;
