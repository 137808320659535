import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { fetchRecords } from "../../actions/fetchRecords";
import { useTheme } from "@mui/material/styles";
import PropertyType from "../../types/PropertyType";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const usePropertiesSelect = () => {
  const [selectedProperty, setSelectedProperty] = useState<PropertyType>();
  return {
    selectedProperty,
    setSelectedProperty,
  };
};

export const PropertiesSelect = ({
  defaultValue,
  onChange,
  disabled,
  customLabel,
  multiple = false,
  selectedProperty,
}: {
  defaultValue?: any;
  onChange: any;
  disabled: boolean;
  customLabel?: string;
  multiple?: boolean;
  selectedProperty?: PropertyType;
}) => {
  const [options, setOptions] = useState<[]>([]);
  const [property, setProperty] = useState<any>(
    defaultValue ? [defaultValue?.uid] : []
  );
  const theme = useTheme();

  useEffect(() => {
    getRecords().then(() => {});
  }, []);

  const handleChange = (event: SelectChangeEvent<typeof property>) => {
    const {
      target: { value },
    } = event;

    setProperty(typeof value === "string" ? value.split(",") : value);
    onChange(typeof value === "string" ? value.split(",") : value);
  };

  async function getRecords() {
    const { properties } = await fetchRecords("properties");

    const optionsArray: any = [];

    properties.map((item: any) =>
      optionsArray.push({
        label: item.name,
        uid: item.uid,
      })
    );

    setOptions(optionsArray);
  }

  const findLabel = (selectedUId: any) => {
    if (!selectedUId) {
      return;
    }
    let index = options.findIndex(function (option: any) {
      return option.uid === selectedUId;
    });

    const { label } = options[index];

    return label;
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel id="property-chip-label">
        {customLabel ? customLabel : "Propiedades relacionadas"}
      </InputLabel>
      <Select
        disabled={disabled}
        fullWidth
        labelId="property-chip-label"
        id="property-multiple-chip"
        multiple={multiple}
        value={property}
        onChange={handleChange}
        defaultValue={defaultValue}
        input={<OutlinedInput id="select-multiple-chip" label="Propiedades" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value: any) => (
              <Chip key={value} label={findLabel(value)} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map((item: any) => (
          <MenuItem
            key={item.uid}
            value={item.uid}
            style={{ fontWeight: theme.typography.fontWeightMedium }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
