import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Stack} from "@mui/material";
import { deleteRecord } from "../../actions/deleteRecord";
import {useState} from "react";
import {DeleteForever} from "@mui/icons-material";
import StyledButton from "../../styled/StyledButton";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        md: 700,
        xs: 350
    },
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};


export const useFormModal = ( resource:string ) => {
    const [ openModal, setOpenModal ] = React.useState(false);
    const [ modalTitle, setModalTitle ] = useState<string>(' ');
    const [ uid, setUid ] = useState<string | null>(null);
    const handleModal = () => setOpenModal(!openModal);

    const handleDelete = async () => {
        if(uid) {
            await deleteRecord( resource, uid )
            setOpenModal(false); //Close modal if is open
        }
    }

    return {
        openModal,
        handleModal,
        handleDelete,
        modalTitle,
        setModalTitle,
        setUid
    }
}

type FormModalType = {
    handleModal: any;
    openModal: any;
    handleDelete: any;
    modalTitle: string;
    getRecords: any;
    component: React.ReactElement;
    customDismiss?: any;
}

export const FormModal = ({
    handleDelete,
    handleModal,
    openModal,
    modalTitle,
    getRecords,
    component,
    customDismiss
}:FormModalType) => {
    return(
        <Modal
            open={openModal}
            onClose={handleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography
                    sx={{ mb: 4 }}
                    align="center"
                    variant="h5"
                >
                    { modalTitle }
                </Typography>
                { component  }
            </Box>
        </Modal>
    )
}