import {
  Button,
  Container,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { fetchRecords } from "../../actions/fetchRecords";
import { Add, Apartment, ChevronLeft, Delete, Edit } from "@mui/icons-material";
import {
  DeleteModal,
  useModalDelete,
} from "../../components/common/DeleteModal";
import StyledButton from "../../styled/StyledButton";
import { BreadcrumbsBar } from "../../components/common/BreadcrumbsBar";
import { updateRecord } from "../../actions/updateRecord";
import CategoryType from "../../types/CategoryType";
import { dashboardUrlBase } from "../../constants/site";
import { categoriesBaseUrl } from "../../routes/useCategoriesRoutes";

const componentDefaultProps = {
  title: "Categorías",
  resource: "categories",
  addButtonText: "Nueva categoría",
  formPath: "categories",
};

const CategoriesList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [results, setResults] = useState<CategoryType[]>([]);

  const {
    handleModal,
    openModal,
    handleDelete,
    modalTitle,
    setModalTitle,
    setUid,
  } = useModalDelete(componentDefaultProps.resource);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nombre",
      flex: 1,
      editable: true,
    },
    {
      field: "isVisible",
      width: 150,
      headerName: "Visible",
      renderCell: (cellValues: any) => {
        const currentRecords = [...results];
        const index = currentRecords.findIndex(
          (item) => item.id === cellValues.id
        );

        const handleChange = async () => {
          await updateRecord(
            "categories",
            {
              isVisible:
                cellValues.value === "undefined" ? true : !cellValues.value,
            },
            cellValues.id
          );

          currentRecords[index].isVisible = !cellValues.value;

          setResults(currentRecords);
        };

        return (
          <Box
            sx={{
              width: "100%",
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            <Switch
              checked={results[index]?.isVisible}
              disabled={cellValues.value === "undefined"}
              onChange={handleChange}
            />
          </Box>
        );
      },
    },
    {
      field: "uid",
      headerName: "Acciones",
      width: 320,
      renderCell: (cellValues) => {
        return (
          <Box
            sx={{
              width: "100%",
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            <StyledButton
              size="small"
              color="primary"
              variant="outlined"
              startIcon={<Apartment />}
              onClick={(event) => {
                event.stopPropagation();
                navigate(`/dashboard/categorias/${cellValues.id}/propiedades`);
              }}
            >
              Propiedades
            </StyledButton>
            <StyledButton
              size="small"
              color="primary"
              startIcon={<Edit />}
              onClick={(event) => {
                event.stopPropagation();
                navigate(
                  `/dashboard/${componentDefaultProps.resource}/edit/${cellValues.id}`
                );
              }}
            >
              Editar
            </StyledButton>
            <StyledButton
              size="small"
              color="error"
              startIcon={<Delete />}
              onClick={(event) => {
                event.stopPropagation();

                setModalTitle(cellValues.row.name); //Set modal title

                setUid(cellValues.id.toString()); //Set-up the uid to delete

                handleModal(); //Open modal
              }}
            >
              Eliminar
            </StyledButton>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    getRecords();
  }, []);

  async function getRecords() {
    const { categories } = await fetchRecords("categories");

    setResults(categories);

    if (categories) {
      setLoading(false);
    }
  }

  return (
    <>
      <DeleteModal
        handleDelete={handleDelete}
        handleModal={handleModal}
        openModal={openModal}
        modalTitle={modalTitle}
        getRecords={getRecords}
      />
      <Paper sx={{ pt: 12 }} elevation={0}>
        <Container>
          <Grid
            alignContent="center"
            justifyContent="space-between"
            sx={{ mb: 4 }}
            container
          >
            <Grid
              xs={12}
              md={8}
              sx={{
                alignItems: "center",
                display: "flex",
                pb: {
                  xs: 4,
                  md: 0,
                },
              }}
              item
            >
              <BreadcrumbsBar
                currentPath={[{ label: `${componentDefaultProps.title}` }]}
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <StyledButton
                fullWidth
                variant="contained"
                startIcon={<Add />}
                onClick={() =>
                  navigate(`/${dashboardUrlBase}/${categoriesBaseUrl}/nueva`)
                }
              >
                {componentDefaultProps.addButtonText}
              </StyledButton>
            </Grid>
          </Grid>
          <Box sx={{ width: "100%", height: "100%" }}>
            <DataGrid
              loading={loading}
              autoHeight
              rows={results}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
          </Box>
          <Grid mt={2} justifyContent="center" container>
            <Grid item>
              <Button
                sx={{ textTransform: "none" }}
                startIcon={<ChevronLeft />}
                onClick={() => navigate("/dashboard")}
              >
                Volver
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </>
  );
};

export default CategoriesList;
