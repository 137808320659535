import { AnimatePresence } from "framer-motion";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Web from "../pages/Web";
import PropertiesList from "../pages/properties/PropertiesList";
import PropertiesForm from "../pages/properties/PropertiesForm";
import DashboardLayout from "../components/common/DashboardLayout";
import CategoriesList from "../pages/categories/CategoriesList";
import CategoriesFormPage from "../pages/categories/CategoriesFormPage";
import FeaturesList from "../pages/features/FeaturesList";
import FeaturesForm from "../pages/features/FeaturesForm";
import LocationsList from "../pages/locations/LocationsList";
import LocationsForm from "../pages/locations/LocationsForm";
import TestimonialsList from "../pages/testimonials/TestimonialsList";
import TestimonialsForm from "../pages/testimonials/TestimonialsForm";
import System from "../pages/System";
import ClientsList from "../pages/clients/ClientsList";
import ClientsForm from "../pages/clients/ClientsForm";
import ZonesList from "../pages/zones/ZonesList";
import ZonesForm from "../pages/zones/ZonesForm";
import OffersList from "../pages/offers/OffersList";
import OffersForm from "../pages/offers/OffersForm";
import GalleryList from "../pages/gallery/GalleryList";
import GalleryForm from "../pages/gallery/GalleryForm";
import BannersList from "../pages/banners/BannersList";
import BannersForm from "../pages/banners/BannersForm";
import { useSellerRoutes } from "./sellers";
import { useContractsRoutes } from "./Contracts";
import { useReceiptsRoutes } from "./receipts";
import FinancingPlanList from "../pages/financing/FinancingPlanList";
import FinancingPlansForm from "../pages/financing/FinancingPlansForm";
import { useCategoriesRoutes } from "./useCategoriesRoutes";

const AppRoutes = () => {
  const sellerRoutes = useSellerRoutes();
  const contractRoutes = useContractsRoutes();
  const receiptsRoutes = useReceiptsRoutes();
  const categoriesRoutes = useCategoriesRoutes();

  return (
    <AnimatePresence initial={true} exitBeforeEnter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<DashboardLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/dashboard" element={<Web />} />
          <Route path="/dashboard/properties" element={<PropertiesList />} />
          <Route
            path="/dashboard/properties/new"
            element={<PropertiesForm />}
          />
          <Route
            path="/dashboard/properties/edit/:uid"
            element={<PropertiesForm />}
          />

          <Route path="/dashboard/banners" element={<BannersList />} />
          <Route path="/dashboard/banners/new" element={<BannersForm />} />
          <Route
            path="/dashboard/banners/edit/:uid"
            element={<BannersForm />}
          />

          <Route path="/dashboard/categories" element={<CategoriesList />} />
          <Route
            path="/dashboard/categories/new"
            element={<CategoriesFormPage />}
          />
          <Route
            path="/dashboard/categories/edit/:uid"
            element={<CategoriesFormPage />}
          />

          <Route path="/dashboard/banners" element={<BannersList />} />
          <Route path="/dashboard/banners/new" element={<BannersForm />} />
          <Route
            path="/dashboard/banners/edit/:uid"
            element={<BannersForm />}
          />

          <Route
            path="/dashboard/categories/new"
            element={<CategoriesFormPage />}
          />
          <Route
            path="/dashboard/categories/edit/:uid"
            element={<CategoriesFormPage />}
          />

          <Route path="/dashboard/features" element={<FeaturesList />} />
          <Route path="/dashboard/features/new" element={<FeaturesForm />} />
          <Route
            path="/dashboard/features/edit/:uid"
            element={<FeaturesForm />}
          />

          <Route path="/dashboard/offers" element={<OffersList />} />
          <Route path="/dashboard/offers/new" element={<OffersForm />} />
          <Route path="/dashboard/offers/edit/:uid" element={<OffersForm />} />

          <Route path="/dashboard/locations" element={<LocationsList />} />
          <Route path="/dashboard/locations/new" element={<LocationsForm />} />
          <Route
            path="/dashboard/locations/edit/:uid"
            element={<LocationsForm />}
          />

          <Route
            path="/dashboard/testimonials"
            element={<TestimonialsList />}
          />
          <Route
            path="/dashboard/testimonials/new"
            element={<TestimonialsForm />}
          />
          <Route
            path="/dashboard/testimonials/edit/:uid"
            element={<TestimonialsForm />}
          />

          <Route path="/system" element={<System />} />
          <Route path="/contabilidad/clientes" element={<ClientsList />} />
          <Route path="/contabilidad/clientes/new" element={<ClientsForm />} />
          <Route
            path="/contabilidad/clientes/edit/:uid"
            element={<ClientsForm />}
          />

          <Route
            path="/dashboard/properties/:uid/zones"
            element={<ZonesList />}
          />
          <Route
            path="/dashboard/properties/:uid/zones/new"
            element={<ZonesForm />}
          />
          <Route
            path="/dashboard/properties/:uid/zones/edit/:zone"
            element={<ZonesForm />}
          />

          <Route
            path="/dashboard/properties/:uid/gallery"
            element={<GalleryList />}
          />
          <Route
            path="/dashboard/properties/:uid/gallery/new"
            element={<GalleryForm />}
          />
          <Route
            path="/dashboard/properties/:uid/gallery/edit/:zone"
            element={<GalleryForm />}
          />

          <Route
            path="/dashboard/properties/:uid/financiamiento"
            element={<FinancingPlanList />}
          />
          <Route
            path="/dashboard/properties/:uid/financiamiento/crear"
            element={<FinancingPlansForm />}
          />

          {sellerRoutes}
          {contractRoutes}
          {receiptsRoutes}
          {categoriesRoutes}
        </Route>
      </Routes>
    </AnimatePresence>
  );
};

export default AppRoutes;
