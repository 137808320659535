import {Autocomplete, CircularProgress, TextField} from "@mui/material";
import {Add} from "@mui/icons-material";
import StyledButton from "../../styled/StyledButton";
import {ModalForm, useModalForm} from "./ModalForm";
import * as React from "react";
import {useEffect, useState} from "react";
import {fetchRecords} from "../../actions/fetchRecords";

interface ItemsSelectProps {
    defaultValue: any,
    onChange: any,
    disabled: boolean,
    resource: string,
    items?: any,
    ChildModalForm?: React.ReactElement,
    placeholder: string,
    creatableButtonTitle?: string,
    creatableElement?: boolean,
    autocompleteId: string,
    field?: string,
    width?: number,
    setCompleteObject?: boolean,
    multiple?: boolean,
    handleIncomingItems?: boolean,
}

const ItemsSelect = ({
                         defaultValue,
                         onChange,
                         disabled,
                         resource,
                         items,
                         ChildModalForm,
                         placeholder,
                         creatableButtonTitle,
                         autocompleteId,
                         creatableElement,
                         width = 0,
                         field,
                         setCompleteObject,
                         handleIncomingItems = true,
                         multiple = false,
                     }: ItemsSelectProps) => {

    const [iterableOptions, setIterableOptions] = useState([]);
    const [defValue, setDefValue] = useState<any>();

    const modalFormProps = useModalForm(resource);

    useEffect(() => {
        if (items) {
            handleOptionItems(items);
        }
    }, [items]);

    useEffect(() => {
        if (resource) {
            getRecords().then();
        }
    }, [resource]);

    useEffect(() => {
        handleDefaultValue()
    },[defaultValue]);

    const handleDefaultValue = () => {
        if(typeof defaultValue === 'object' && field) {
            if(defaultValue?._id || defaultValue?.uid) {
                setDefValue({
                    uid: defaultValue._id || defaultValue.uid,
                    label: defaultValue?.[field]
                })
            }
        }else {
            setDefValue(defaultValue);
        }
    }
    
    const getRecords = async () => {
        const result = await fetchRecords(resource);

        if (result[resource]) {
            handleOptionItems(result[resource]);
        }
    };

    const handleOptionItems = (items: any) => {
        let optionsArray: any = [];

        if(handleIncomingItems && field) {
            items.map((item: any) => (
                optionsArray.push({
                    ...item,
                    label: item[field],
                    uid: item.uid
                })
            ));
        } else {
            optionsArray = items;
        }

        setIterableOptions(optionsArray);
    };

    return (
        <>
            {
                ChildModalForm && (
                    <ModalForm
                        {...modalFormProps}
                        getRecords={getRecords}
                        ChildModalForm={ChildModalForm}
                    />
                )
            }
            {iterableOptions.length === 0 && creatableElement ? (
                    <TextField
                        disabled
                        placeholder={placeholder}
                        fullWidth
                        InputProps={{
                            endAdornment: disabled ? <CircularProgress size={12}/> :
                                <StyledButton
                                    fullWidth
                                    onClick={modalFormProps.handleModalForm}
                                    variant="outlined"
                                    startIcon={<Add/>}
                                >
                                    {creatableButtonTitle}
                                </StyledButton>
                        }}
                    />
                ) :
                <Autocomplete
                    disabled={disabled}
                    disablePortal
                    sx={{
                        width: width ? width : '100%'
                    }}
                    loading={disabled}
                    loadingText="Cargando..."
                    multiple={multiple}
                    defaultValue={defValue ? defValue : undefined}
                    onChange={(event: any, newValue: any) => {
                        if (newValue) {
                            if (setCompleteObject) {
                                onChange(newValue);
                            } else {
                                onChange(newValue.uid);
                            }
                        }
                    }}
                    renderOption={(props: any, option) => {

                        //If is the last option
                        if ((iterableOptions.length - 1) === props["data-option-index"]) {
                            return (
                                <>
                                    <li  {...props}>
                                        {option.label}
                                    </li>
                                    <li>
                                        <StyledButton
                                            onClick={modalFormProps.handleModalForm}
                                            fullWidth
                                            startIcon={<Add/>}
                                        >
                                            {creatableButtonTitle}
                                        </StyledButton>
                                    </li>
                                </>

                            );
                        }
                        return (<li {...props}>
                            {option.label}
                        </li>);
                    }}
                    id={autocompleteId}
                    options={iterableOptions}
                    renderInput={(params) => <TextField {...params} label={placeholder}/>}
                />
            }
        </>
    );
};

export default ItemsSelect;