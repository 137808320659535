import { Apartment } from "@mui/icons-material";
import { Card, CardActionArea, CardContent, Stack, Typography, CardActions, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import StyledButton from "../../styled/StyledButton";
import StyledCircleBox from "../../styled/StyledCircleBox";

type CardSectionType = {
    redirectTo: string;
    title: string;
    actionButtonText: string,
    icon?: any
}

const CardSection = ({ redirectTo, title, actionButtonText, icon }:CardSectionType) => {
    const navigate = useNavigate();
    const theme = useTheme();

    return(
        <Card elevation={ 3 } sx={{
            borderRadius: 4,
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        }}>
            <CardActionArea
                sx={{ p: 1 }}
                onClick={ () => navigate( redirectTo ) }
            >
                <CardContent>
                    <Stack spacing={ 2 } alignItems="center">
                        {
                            icon ? icon : (
                                <StyledCircleBox>
                                    { icon }
                                </StyledCircleBox>
                            )
                        }
                        <Typography align="center" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            { title }
                        </Typography>
                    </Stack>
                </CardContent>
                <CardActions>
                    <StyledButton
                        variant="outlined"
                        size="small"
                        fullWidth
                    >
                        { actionButtonText }
                    </StyledButton>
                </CardActions>
            </CardActionArea>
        </Card>
    )
}

export default CardSection;