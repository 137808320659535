import {DropzoneComponent, useDropzoneComponent} from "../common/DropzoneComponent";
import {forwardRef, RefObject, useEffect, useImperativeHandle} from "react";

type FormatType = '.jpeg' | '.jpg' | '.png' | '.pdf';

type FilesUploaderType = {
    allowedFormats: FormatType[];
    defaultName?: string;
    maxFilesSupport?: number;
    loading?: boolean;
    description?: string;
    icon?: any;
    defaultFile?: any;
}

export const FilesUploader = forwardRef(
    ({
        defaultName,
         allowedFormats,
         maxFilesSupport,
         loading,
         description,
         icon,
         defaultFile,
         ...props
     }:FilesUploaderType, ref:any) => {
        const { files,
            getInputProps,
            getRootProps,
            startUploading,
            progress,
            setCover,
            setFiles,
            setDeletedFiles,
            deletedFiles,
            setCroppedData
        } = useDropzoneComponent( maxFilesSupport ? maxFilesSupport : 1, 4500000, allowedFormats );

        useImperativeHandle(ref,() => ({
                handleUpload: async (folder:string) => {
                    return await startUploading(folder)
                }
            })
        );

        return(<DropzoneComponent
            fileName={ defaultName ? defaultName : files[0]?.name }
            fileUid={ defaultFile?._id }
            isPdf={ true }
            icon={ icon }
            loading={ loading }
            title={ description }
            progress={ progress }
            getInputProps={ getInputProps }
            getRootProps={ getRootProps }
            files={ files }
            setCover={ setCover }
            setFiles={ setFiles }
            withCropper={ false }
            setDeletedFiles={ setDeletedFiles }
            deletedFiles={ deletedFiles }
            allowedFormats={ allowedFormats }
            setCroppedData={ setCroppedData }
        />)
    })
