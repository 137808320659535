import { Route } from 'react-router-dom';
import * as React from 'react';
import ContractsList from "../pages/contracts/ContractsList";
import ContractsForm from "../pages/contracts/ContractsForm";
import Templates from "../pages/contracts/Templates";

export const useContractsRoutes = () => (
    <React.Fragment>
        <Route path="/contabilidad/contratos" element={<ContractsList/>}/>
        <Route path="/contabilidad/contratos/nuevo" element={<ContractsForm/>}/>
        <Route path="/contabilidad/contratos/editar/:contractId" element={<ContractsForm/>}/>
        <Route path="/contabilidad/contratos/plantillas" element={<Templates/>}/>
    </React.Fragment>
)