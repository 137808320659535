import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Save } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { fetchRecord } from "../../actions/fetchRecord";
import { updateRecord } from "../../actions/updateRecord";
import StyledButton from "../../styled/StyledButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropertyType from "../../types/PropertyType";

const componentDefaultProps = {
  resource: "properties",
};

const __TITLE__ = "medidas";
const __RESOURCE__ = "properties";

type CardinalPointType = {
  name: string,
  label: string
}
const cardinalPoints:Array<CardinalPointType> = [
  {
    name: "north",
    label: "Norte",
  },
  {
    name: "south",
    label: "Sur",
  },
  {
    name: "east",
    label: "Este",
  },
  {
    name: "west",
    label: "Oeste",
  },
];

const PropertiesMeasurements = ({
   onCancel,
    propertyId,
    embedded = false,
    setPropertyData,
    setMeasurements,
}: {
  onCancel?: () => void;
  propertyId: string | undefined;
  embedded?: boolean;
  setPropertyData?: any;
  setMeasurements?: any;
}) => {
  const {
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [cardinalPointsData, setCardinalPointsData] = useState<any>();

  const [property, setProperty] = useState<PropertyType>({
    slug: "",
    name: "",
    description: "",
    measures_unit: "",
    videos: [],
    images: [],
    category: "",
    keywords: "",
    status: true,
  });

  useEffect(() => {
    getRecord().then();
  }, []);

  const getRecord = async () => {
    if (propertyId) {
      const { property } = await fetchRecord(
        componentDefaultProps.resource,
          propertyId
      );

      if( setPropertyData ) {
        setPropertyData(property);
      }
      setProperty(property);
    }
  };

  const navigate = useNavigate();

  const submitForm = async () => {
    let result: any;

    const data = {
      ...property,
      measurements: cardinalPointsData
    }

    setMeasurements(cardinalPointsData);
    if (propertyId) {
      result = await updateRecord(
        componentDefaultProps.resource,
          data,
          propertyId
      );
    }
  };

  return (
      <Grid spacing={2} container>
        <Grid xs={12} item>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Punto cardinal</TableCell>
                  <TableCell>Metros lineales</TableCell>
                  <TableCell>Colinda con</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cardinalPoints.map((cardinalPoint:CardinalPointType, index: number) => (
                    <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                    >
                      <TableCell component="th" scope="row">
                        Al { cardinalPoint.label }
                      </TableCell>
                      <TableCell>
                        <TextField
                            disabled={loading}
                            fullWidth
                            onChange={(e) => {
                              setCardinalPointsData((prev:any) => ({
                                ...prev,
                                [cardinalPoint.name]: {
                                  measurement: e.target.value
                                }
                              }));
                            }}
                            //value={value}
                            label="Metros lineales"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                            disabled={loading}
                            fullWidth
                            onChange={(e) => {
                              setCardinalPointsData((prev:any) => ({
                                ...prev,
                                [cardinalPoint.name]: {
                                  measurement: cardinalPointsData[cardinalPoint.name].measurement,
                                  borderWith: e.target.value
                                }
                              }));
                            }}
                            label="Colinda con"
                        />
                      </TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
  );
};

export default PropertiesMeasurements;
