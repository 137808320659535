import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Check } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { postRecord } from "../../actions/postRecord";
import { fetchRecord } from "../../actions/fetchRecord";
import { updateRecord } from "../../actions/updateRecord";
import StyledButton from "../../styled/StyledButton";
import Box from "@mui/material/Box";
import { BreadcrumbsBar } from "../../components/common/BreadcrumbsBar";
import {
  DropzoneComponent,
  useDropzoneComponent,
} from "../../components/common/DropzoneComponent";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import OfferType from "../../types/OfferType";
import { PropertiesSelect } from "../../components/common/PropertiesSelect";
const __BACK_PATH__ = "/dashboard/offers";

const __TITLE__ = "oferta";
const __RESOURCE__ = "offers";
const __LABEL__ = "Ofertas";

const OffersForm = () => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const [isFormReady, setIsFormReady] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    files,
    getInputProps,
    getRootProps,
    startUploading,
    progress,
    setCover,
    setFiles,
    croppedData,
    setCroppedData,
    setDeletedFiles,
    deletedFiles,
  } = useDropzoneComponent(1);
  const [valueFrom, setValueDateFrom] = React.useState<Dayjs | null>(
    dayjs(Date.now())
  );
  const [valueUntil, setValueDateUntil] = React.useState<Dayjs | null>(
    dayjs(Date.now())
  );
  const navigate = useNavigate();

  const params = useParams();

  const [record, setResult] = useState<OfferType>({
    name: "",
    description: "",
    activeFrom: 0,
    activeUntil: 0,
    isActive: false,
    file: "",
  });

  useEffect(() => {
    getRecord().then();
  }, []);

  const getRecord = async () => {
    if (params.uid) {
      const { offer } = await fetchRecord(__RESOURCE__, params.uid);

      setValue("name", offer.name);

      if (offer?.file) {
        setCroppedData(offer?.file.url);
      }
      setResult(offer);
    }

    setIsFormReady(true);
  };

  const handleChangeFrom = (newValue: Dayjs | null) => {
    setValueDateFrom(newValue);
  };

  const handleChangeUntil = (newValue: Dayjs | null) => {
    setValueDateUntil(newValue);
  };

  const submitForm = async (data: any) => {
    let record: any;

    setLoading(true);

    data.activeFrom = valueFrom?.unix();
    data.activeUntil = valueUntil?.unix();

    if (data?.property && data?.property[0]) {
      data.property = data.property[0];
    }

    if (files.length > 0) {
      const resultUpload = await startUploading("offers");

      if (resultUpload) {
        data.file = resultUpload[0];
      }
    }

    if (params.uid) {
      record = await updateRecord(__RESOURCE__, data, params.uid); //If updating
    } else {
      record = await postRecord(__RESOURCE__, data); //If creating
    }

    setLoading(false);

    if (record) {
      navigate(`/dashboard/${__RESOURCE__}`);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Paper sx={{ pt: 12 }} elevation={0}>
        <Container maxWidth="md">
          <BreadcrumbsBar
            currentPath={[
              { to: "/dashboard", label: "Inicio" },
              { to: `/dashboard/${__RESOURCE__}`, label: __LABEL__ },
              {
                label: params.uid
                  ? `Editar ${__TITLE__}`
                  : `Crear ${__TITLE__}`,
              },
            ]}
          />
          {isFormReady ? (
            <form onSubmit={handleSubmit(submitForm)}>
              <Grid sx={{ mt: 2 }} spacing={2} container>
                <Grid xs={12} item>
                  <Controller
                    name="name"
                    defaultValue={record ? record.name : undefined}
                    control={control}
                    rules={{
                      required: "El nombre es requerido.",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        multiline
                        fullWidth
                        onChange={onChange}
                        value={value}
                        label="Nombre"
                      />
                    )}
                  />
                  {errors.name && (
                    <Typography variant="caption" sx={{ color: "red" }}>
                      {errors.name.message}
                    </Typography>
                  )}
                </Grid>
                <Grid xs={12} item>
                  <Controller
                    name="description"
                    defaultValue={record ? record.description : undefined}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        multiline={true}
                        fullWidth
                        onChange={onChange}
                        value={value}
                        label="Descripción"
                      />
                    )}
                  />
                  {errors.description && (
                    <Typography variant="caption" sx={{ color: "red" }}>
                      {errors.description.message}
                    </Typography>
                  )}
                </Grid>
                <Grid xs={12} item>
                  <Controller
                    name="property"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <PropertiesSelect
                        customLabel="Propiedad relacionada"
                        disabled={loading}
                        defaultValue={record ? record?.property : undefined}
                        onChange={onChange}
                        multiple={false}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={6} item>
                  <DateTimePicker
                    label="Disponible desde"
                    value={valueFrom}
                    onChange={handleChangeFrom}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </Grid>
                <Grid xs={6} item>
                  <DateTimePicker
                    label="Disponible hasta"
                    value={valueUntil}
                    onChange={handleChangeUntil}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </Grid>
                <Grid xs={12} sx={{ height: "100% " }} item>
                  <DropzoneComponent
                    maxWidthImg={40}
                    defaultPreview={record?.file?.url}
                    loading={loading}
                    title={
                      "Arrastra y suelta o selecciona una imagen para la oferta."
                    }
                    progress={progress}
                    getInputProps={getInputProps}
                    getRootProps={getRootProps}
                    files={files}
                    setCover={setCover}
                    setFiles={setFiles}
                    withCropper={false}
                    croppedData={croppedData}
                    setCroppedData={setCroppedData}
                    setDeletedFiles={setDeletedFiles}
                    deletedFiles={deletedFiles}
                    aspectRatio={1}
                  />
                </Grid>
              </Grid>
              <Grid
                spacing={2}
                sx={{ my: 2, justifyContent: "center" }}
                container
              >
                <Grid
                  sx={{
                    order: {
                      xs: 2,
                      md: 1,
                    },
                  }}
                  xs={12}
                  sm={4}
                  item
                >
                  <StyledButton
                    fullWidth
                    variant="outlined"
                    onClick={() => navigate(__BACK_PATH__)}
                  >
                    Cancelar
                  </StyledButton>
                </Grid>
                <Grid
                  sx={{
                    order: {
                      xs: 1,
                      md: 2,
                    },
                  }}
                  xs={12}
                  sm={4}
                  item
                >
                  <StyledButton
                    fullWidth
                    variant="contained"
                    disabled={loading}
                    startIcon={
                      loading ? (
                        <CircularProgress color="inherit" size={12} />
                      ) : (
                        <Check />
                      )
                    }
                    type="submit"
                  >
                    {loading
                      ? params.uid
                        ? `Actualizando ${__TITLE__}...`
                        : `Creando ${__TITLE__}`
                      : params.uid
                      ? `Actualizar ${__TITLE__}...`
                      : `Crear ${__TITLE__}`}
                  </StyledButton>
                </Grid>
              </Grid>
            </form>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: 400,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Container>
      </Paper>
    </LocalizationProvider>
  );
};

export default OffersForm;
