import {Button, Container, Grid, Paper, Switch} from "@mui/material";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {useEffect, useState} from "react";
import {fetchRecords} from "../../actions/fetchRecords";
import {Add, ChevronLeft, Delete, Edit} from "@mui/icons-material";
import {DeleteModal, useModalDelete} from "../../components/common/DeleteModal";
import StyledButton from "../../styled/StyledButton";
import {BreadcrumbsBar} from "../../components/common/BreadcrumbsBar";
import {updateRecord} from "../../actions/updateRecord";
import BannerType from "../../types/BannerType";

const __TITLE__ = 'Banners';
const __RESOURCE__ = 'banners';
const __LABEL__ = 'Añadir banner';

const BannersList = () => {
    const navigate = useNavigate();
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ results, setResults ] = useState<BannerType[]>([]);

    const {handleModal, openModal, handleDelete, modalTitle, setModalTitle,setUid } = useModalDelete( __RESOURCE__ );

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Nombre',
            flex: 1,
            editable: true,
        },
        {
            field: 'isActive',
            width: 150,
            headerName: '¿Está activo?',
            renderCell: ((cellValues:any) => {
                const currentRecords = [...results];
                const index = currentRecords.findIndex(item => item.id === cellValues.id);

                const handleChange = async () => {

                    await updateRecord(__RESOURCE__, {
                        isActive: cellValues.value === 'undefined' ? true : !cellValues.value
                    }, cellValues.id);

                    currentRecords[index].isActive = !cellValues.value;

                    setResults( currentRecords );
                }

                return(
                    <Box sx={{width: '100%', justifyContent: 'space-around', display: 'flex' }}>
                        <Switch checked={ results[ index ]?.isActive } disabled={ cellValues.value === 'undefined' } onChange={ handleChange }/>
                    </Box>
                )
            })
        },
        {
            field: 'uid',
            headerName: 'Acciones',
            width: 240,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{width: '100%', justifyContent: 'space-around', display: 'flex' }}>
                        <Button
                            size="small"
                            color="primary"
                            startIcon={ <Edit/> }
                            onClick={(event) => {
                                event.stopPropagation();
                                navigate(`/dashboard/${__RESOURCE__}/edit/${cellValues.id}`)
                            }}
                        >
                            Editar
                        </Button>
                        <Button
                            size="small"
                            color="error"
                            startIcon={ <Delete/> }
                            onClick={(event) => {
                                event.stopPropagation();

                                setModalTitle( cellValues.row.name ); //Set modal title

                                setUid(cellValues.id.toString()); //Set-up the uid to delete

                                handleModal(); //Open modal
                            }}
                        >
                            Eliminar
                        </Button>
                    </Box>
                );
            }
        }
    ];

    useEffect( () => {
        getRecords();
    },[]);

    async function getRecords() {
        const { banners } = await fetchRecords( __RESOURCE__ );

        setResults(banners);

        if(banners) {
            setLoading(false);
        }
    }

    return(
        <>
            <DeleteModal handleDelete={handleDelete} handleModal={handleModal} openModal={openModal} modalTitle={modalTitle} getRecords={getRecords}/>
            <Paper sx={{ pt: 12 }} elevation={ 0 }>
                <Container>
                    <Grid alignContent="center" justifyContent="space-between" sx={{ mb: 4 }} container>
                        <Grid
                            xs={ 12 }
                            md={ 8 }
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                pb: {
                                    xs: 4,
                                    md: 0
                                }
                            }}
                            item
                        >
                            <BreadcrumbsBar currentPath={[
                                
                                { label: `${__TITLE__}` },
                            ]}/>
                        </Grid>
                        <Grid xs={ 12 } md={ 4 } item>
                            <StyledButton
                                fullWidth
                                variant="contained"
                                startIcon={ <Add/> }
                                onClick={ () => navigate(  `/dashboard/${ __RESOURCE__ }/new` ) }
                            >
                                { __LABEL__ }
                            </StyledButton>
                        </Grid>
                    </Grid>
                    <Box sx={{ width: '100%', height: '100%' }}>
                        <DataGrid
                            loading={loading}
                            autoHeight
                            rows={results}
                            columns={columns}
                            pageSize={ 10 }
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                        />
                    </Box>
                    <Grid mt={2} justifyContent="center" container>
                        <Grid item>
                            <Button
                                sx={{ textTransform: 'none' }}
                                startIcon={ <ChevronLeft/> }
                                onClick={ () => navigate( `/dashboard/${ __RESOURCE__ }` ) }
                            >
                                Volver
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </>
    )
}

export default BannersList;