import React, {useEffect, useRef, useState} from "react";
import PropertyType from "../../types/PropertyType";
import {fetchRecord} from "../../actions/fetchRecord";
import Box from "@mui/material/Box";
import {Card, CardContent, CardMedia, Paper, Stack, TextField, Typography} from "@mui/material";
import {CoverDropzone} from "../CoverDropzone";
import {useDropzoneComponent} from "./DropzoneComponent";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

const PropertyBanner = ({ propertyId, showProps = [], editable = false }:{ propertyId:string, showProps?:Array<string> | undefined, editable?: boolean }) => {
    const [data, setData] = useState<PropertyType | null>(null);
    const refFacilityLogo = useRef(null);
    const [loading, setLoading] = useState(true);
    const [selectableMonths, setSelectableMonths] = useState([])
    const dropZoneProps = useDropzoneComponent( 40 );

    useEffect(() => {
        getRecord().then()
    },[propertyId])

    const getRecord = async () => {
        if(propertyId) {
            const { property } = await fetchRecord('properties', propertyId );
            if(property) {
                setData(property);
            }

            console.log('property ', property);
            if(property?.selectable_financing_months) {
                setSelectableMonths(property.selectable_financing_months)
            }
        }
        setLoading(false);
    }

    return(
        data ? (
            <Card sx={{display: 'flex', backgroundColor: 'white'}}>
                <Box sx={{display: 'flex',  flex: 1, flexDirection: 'column'}}>
                    <CardContent sx={{flex: '1 0 auto'}}>
                        <Typography component="div" variant="h5">
                            { data.name }
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary"
                                    component="div">
                            Lotificación
                        </Typography>
                    </CardContent>
                    <Stack sx={{ pl: 1, pb: 1}}>
                        {
                            showProps && showProps.includes('selectable_financing_months') && (
                                <>
                                    <Typography>
                                        Total de meses de financiamiento: { data.total_financing_months }
                                    </Typography>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 400 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Plazo</TableCell>
                                                    <TableCell>Precio por m²</TableCell>
                                                    <TableCell>Enganche</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {selectableMonths.map((selectableMonth:any, index: number) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{
                                                            "&:last-child td, &:last-child th": { border: 0 },
                                                        }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {selectableMonth.selectable_months}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                editable ? (
                                                                    <TextField
                                                                        disabled={loading}
                                                                        fullWidth
                                                                        onChange={(e) => {

                                                                        }}
                                                                        //value={value}
                                                                        label="Metros lineales"
                                                                    />
                                                                ) : (
                                                                     selectableMonth.price_per_square_meter
                                                                )
                                                            }

                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                editable ? (
                                                                    <TextField
                                                                        disabled={loading}
                                                                        fullWidth
                                                                        onChange={(e) => {

                                                                        }}
                                                                        label="Enganche"
                                                                    />
                                                                ) : (
                                                                    selectableMonth?.downpayment ? selectableMonth.downpayment : 'N/A'
                                                                )
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>

                            )
                        }
                    </Stack>
                </Box>
                {
                    data?.facilityLogo ? (
                        <CardMedia
                            component="img"
                            sx={{width: 151}}
                            image={ data?.facilityLogo?.url }
                            alt="Portada del fraccionamiento"
                        />
                    ) : (
                        <Box sx={{ p: 1 }}>
                            <CoverDropzone
                                title="Arrastra y suelta o selecciona un logotipo para la lotificación"
                                defaultPreview={ data?.facilityLogo?.url }
                                loading={loading}
                                defaultCoverPreview={ data?.facilityLogo?.url }
                                ref={ refFacilityLogo }
                                {...dropZoneProps}
                            />
                        </Box>
                    )
                }
            </Card>
        ) : <></>
    )
}

export default PropertyBanner;