import Header from "../Header";
import {Outlet} from "react-router-dom";
import {ThemeProvider} from "@mui/styles";
import {createTheme} from "@mui/material";

const DashboardLayout = () => {
    return(
        <ThemeProvider theme={createTheme({
            palette: {
                mode: 'dark',
                primary: {
                    main: '#023859'
                },
                secondary: {
                    main: '#00A2A6'
                }
            }
        })}>
            <Header/>
            <Outlet />
        </ThemeProvider>
    )
}

export default DashboardLayout;