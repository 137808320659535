import { Card, CardActionArea, CardActions, CardContent, Container, Grid, Paper, Stack} from "@mui/material";
import { Category, Chat, Map, People, Receipt, StarRounded } from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import StyledButton from "../styled/StyledButton";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import StyledCircleBox from "../styled/StyledCircleBox";

const System = () => {
    const navigate = useNavigate();
    const theme = useTheme();

    return(
        <>
            <Paper sx={{ pt: 12 }} elevation={ 0 }>
                <Container>
                    <Grid justifyContent="space-between" sx={{ mt: 2 }} spacing={ 2 } container>
                        <Grid xs={ 12 } md={ 3 } item>
                            <StyledButton
                                color="primary"
                                variant="outlined"
                                startIcon={ <Category/> }
                                onClick={ () => navigate( '/contabilidad/clients' ) }
                                fullWidth
                            >
                                Administrar clientes
                            </StyledButton>
                        </Grid>
                        <Grid xs={ 12 } md={ 3 } item>
                            <StyledButton
                                color="primary"
                                variant="outlined"
                                startIcon={ <StarRounded/> }
                                onClick={ () => navigate( '/dashboard/contabilidad/features' ) }
                                fullWidth
                            >
                                Administrar características
                            </StyledButton>
                        </Grid>
                    </Grid>
                    <Grid sx={{ mt: 2 }} spacing={ 2 } container>
                        <Grid xs={ 12 } md={ 3 } item>
                            <Card elevation={ 3 }>
                                <CardActionArea
                                    onClick={ () => navigate( '/contabilidad/clients' ) }
                                >
                                    <CardContent>
                                        <Stack spacing={ 2 } alignItems="center">
                                            <StyledCircleBox sx={{
                                                background: theme.palette.primary.main,
                                            }}>
                                                <People sx={{ color: 'white', fontSize: 30 }}/>
                                            </StyledCircleBox>
                                            <Typography align="center" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                Clientes
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                    <CardActions>
                                        <StyledButton
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        >
                                            Administrar clientes
                                        </StyledButton>
                                    </CardActions>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={ 12 } md={ 3 } item>
                            <Card elevation={ 3 }>
                                <CardActionArea
                                    onClick={ () => navigate( '/contabilidad/receipts' ) }
                                >
                                    <CardContent>
                                        <Stack spacing={ 2 } alignItems="center">
                                            <StyledCircleBox sx={{
                                                background: theme.palette.primary.main,
                                            }}>
                                                <Receipt sx={{ color: 'white', fontSize: 30 }}/>
                                            </StyledCircleBox>
                                            <Typography align="center" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                Recibos
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                    <CardActions>
                                        <StyledButton
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        >
                                            Administrar recibos
                                        </StyledButton>
                                    </CardActions>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={ 12 } md={ 3 } item>
                            <Card elevation={ 3 }>
                                <CardActionArea
                                    onClick={ () => navigate( '/dashboard/contabilidad/locations' ) }
                                >
                                    <CardContent>
                                        <Stack spacing={ 2 } alignItems="center">
                                            <StyledCircleBox sx={{
                                                background: theme.palette.primary.main,
                                            }}>
                                                <Map sx={{ color: 'white', fontSize: 30 }}/>
                                            </StyledCircleBox>
                                            <Typography align="center" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                Ubicaciones
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                    <CardActions>
                                        <StyledButton
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        >
                                            Administrar ubicaciones
                                        </StyledButton>
                                    </CardActions>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid xs={ 12 } md={ 3 } item>
                            <Card elevation={ 3 }>
                                <CardActionArea
                                    onClick={ () => navigate( '/dashboard/contabilidad/testimonials' ) }
                                >
                                    <CardContent>
                                        <Stack spacing={ 2 } alignItems="center">
                                            <StyledCircleBox sx={{
                                                background: theme.palette.primary.main,
                                            }}>
                                                <Chat sx={{ color: 'white', fontSize: 30 }}/>
                                            </StyledCircleBox>
                                            <Typography align="center" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                Testimonios
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                    <CardActions>
                                        <StyledButton
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        >
                                            Administrar testimonios
                                        </StyledButton>
                                    </CardActions>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </>
    )
}

export default System;