import {
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Check, Edit } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { BreadcrumbsBar } from "../../components/common/BreadcrumbsBar";
import StyledButton from "../../styled/StyledButton";
import { useEffect, useState } from "react";
import { postRecord } from "../../actions/postRecord";
import { fetchRecord } from "../../actions/fetchRecord";
import PropertyType from "../../types/PropertyType";
import Box from "@mui/material/Box";
import { updateRecord } from "../../actions/updateRecord";
import { ZoneType } from "../../types/ZoneType";
import {
  LoadingDialog,
  useLoadingDialog,
} from "../../components/common/LoadingDialog";
import WithTheme from "../../components/common/WithTheme";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  CreatableProperties,
  useCreatableProperties,
} from "../../components/common/CreatableProperties";
import PropertyBanner from "../../components/common/PropertyBanner";
const __TITLE__ = "zona/etapa";
const __RESOURCE__ = "zones";
const __LABEL__ = "Zonas/Etapas";

const ZonesForm = () => {
  const [defultPropertyName, setDefaultPropertyName] = useState<
    string | undefined
  >(undefined);
  const [zoneName, setZoneName] = useState<string | undefined>(undefined);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
  } = useForm();
  const params = useParams();
  const totalPercentage = 0;

  const creatablePropertiesProps = useCreatableProperties();

  const {
    openModal,
    handleModal,
    modalTitle,
    setModalTitle,
    modalDescription,
    setModalDescription,
  } = useLoadingDialog();

  const [loading, setLoading] = useState<boolean>(false);
  const [isFormReady, setIsFormReady] = useState<boolean>(false);

  const navigate = useNavigate();

  const [property, setProperty] = useState<PropertyType>({
    slug: "",
    name: "",
    description: "",
    measures_unit: "",
    videos: [],
    images: [],
    category: "",
    keywords: "",
    status: true,
  });

  const [zone, setZone] = useState<ZoneType>({
    name: "",
    price_per_square_meter: 0,
    price_annual_interest: 0,
    uid: "",
    property: "",
  });

  useEffect(() => {
    getRecord().then();
  }, []);

  const getRecord = async () => {
    if (params.uid) {
      const { property }: { property: any } = await fetchRecord(
        "properties",
        params.uid
      );

      if (property) {
        setProperty(property);
        setDefaultPropertyName(property.name);
        creatablePropertiesProps.setParentPropertyData(property);
      }
    }

    if (params?.zone) {
      const { zone }: { zone: any } = await fetchRecord("zones", params.zone);
      setValue("name", zone.name);
      setZoneName(zone.name);

      if (zone) {
        setZone(zone);
        if (zone?.child_properties) {
          creatablePropertiesProps.setChildPropertiesArray(
            zone.child_properties
          );
        }
      }
    }

    setIsFormReady(true);
  };

  const submitForm = async (data: any) => {
    handleModal();

    setLoading(true);
    setModalTitle(`Cargando...`);
    setModalDescription("No cierres ni recargues esta página.");

    data.property = params.uid;

    let zoneResult = await postRecord("zones", data);

    const propertyData: PropertyType = {
      ...data,
      parent_property: params.uid,
      zone: zoneResult?.zone.uid,
      financing_plans: property?.financing_plans
    };

    let resChildProperties:any = [];

    if (creatablePropertiesProps?.childPropertiesArray.length > 0) {
      resChildProperties = await creatablePropertiesProps.postProperties(
        propertyData
      );
      if (resChildProperties.length > 0) {
        data.child_properties = resChildProperties;
      }
    }

    await updateRecord("zones", data, zoneResult?.zone.uid);

    const zonesProperty: any = property?.zones ? property.zones : [];

    zonesProperty.push(zoneResult?.zone.uid);

    property.zones = zonesProperty;

    await updateRecord("properties", property, params.uid);
    setLoading(false);

    setModalTitle("Finalizando proceso.");

    setModalTitle("Las zonas/etapas se actualizaron correctamente.");
    setModalDescription("Puedes salir de la página o seguir editando.");
    setLoading(false);
  };

  return (
    <Paper sx={{ pt: 12 }} elevation={0}>
      <Container maxWidth="md">
        <BreadcrumbsBar
          currentPath={[
            { to: "/dashboard", label: "Inicio" },
            {
              to: `/dashboard/properties/${property.uid}/zones`,
              label: __LABEL__,
            },
            {
              label: params?.zone
                ? `Editar ${__TITLE__}`
                : `Crear ${__TITLE__}`,
            },
          ]}
        />
        {isFormReady ? (
          <form style={{ marginTop: 16 }} onSubmit={handleSubmit(submitForm)}>
            <Grid spacing={2} container sx={{ mb: 2 }}>
              {property?.uid && (
                <Grid item xs={12}>
                  <PropertyBanner
                    propertyId={property.uid}
                    showProps={["selectable_financing_months"]}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={zone ? zone.name : undefined}
                  rules={{
                    required: "El nombre de la zona/etapa es requerido",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled={loading}
                      fullWidth
                      onChange={(e) => {
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        setZoneName(e.target.value);
                      }}
                      value={value}
                      label="Nombre de la Zona/Etapa"
                    />
                  )}
                />
                {errors.name && (
                  <Typography variant="caption" sx={{ color: "red" }}>
                    {errors.name.message}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {defultPropertyName && zoneName && (
              <>
                <Typography variant="caption">
                  Propiedades pertenecientes a esta zona/etapa
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container>
                  <Grid item xs={12}>
                    <CreatableProperties
                      {...creatablePropertiesProps}
                      defaultPropertyName={`${property.name} - ${zoneName}`}
                      disabled={loading}
                      selectableMonthsArray={
                        property?.selectable_financing_months
                      }
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {openModal && (
              <Grid sx={{ my: 2 }} container>
                <Grid item xs={12}>
                  <LoadingDialog
                    handleModal={handleModal}
                    openModal={openModal}
                    modalTitle={modalTitle}
                    modalDescription={modalDescription}
                    percentage={totalPercentage}
                    success={!loading}
                    successButtonText={`Seguir editando...`}
                    successButtonAction={handleModal}
                    successButtonIcon={<Edit />}
                  />
                </Grid>
              </Grid>
            )}
            {!openModal && !loading && (
              <Grid
                spacing={2}
                sx={{ my: 2, justifyContent: "center" }}
                container
              >
                <Grid
                  sx={{
                    order: {
                      xs: 2,
                      md: 1,
                    },
                  }}
                  xs={12}
                  sm={4}
                  item
                >
                  <StyledButton
                    fullWidth
                    variant="outlined"
                    onClick={() => navigate(`/dashboard/${__RESOURCE__}`)}
                  >
                    Cancelar
                  </StyledButton>
                </Grid>
                <Grid
                  sx={{
                    order: {
                      xs: 1,
                      md: 2,
                    },
                  }}
                  xs={12}
                  sm={4}
                  item
                >
                  <StyledButton
                    fullWidth
                    variant="contained"
                    disabled={loading || !zoneName}
                    startIcon={
                      loading ? (
                        <CircularProgress color="inherit" size={12} />
                      ) : (
                        <Check />
                      )
                    }
                    type="submit"
                  >
                    {loading
                      ? params.zone
                        ? `Actualizando ${__TITLE__}...`
                        : `Creando ${__TITLE__}`
                      : params.zone
                      ? `Actualizar ${__TITLE__}...`
                      : `Crear ${__TITLE__}`}
                  </StyledButton>
                </Grid>
              </Grid>
            )}
          </form>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: 400,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
    </Paper>
  );
};

export default WithTheme(ZonesForm);
