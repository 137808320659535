import { useEffect, useState} from "react";
import {CircularProgress, Container, Grid, Paper, TextField, Typography} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {Check} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import {postRecord} from "../../actions/postRecord";
import LocationType from "../../types/LocationType";
import {fetchRecord} from "../../actions/fetchRecord";
import {updateRecord} from "../../actions/updateRecord";
import StyledButton from "../../styled/StyledButton";
import { GoogleMap, useJsApiLoader, Marker, Autocomplete } from '@react-google-maps/api';
import googleApiKey from "../../constants/googleApiKey";
import Box from "@mui/material/Box";

const containerStyle = {
    width: '100%',
    height: '500px'
};

const componentDefaultProps = {
    title: 'ubicación',
    resource: 'locations',
    addButtonText: 'Nueva ubicación',
    formPath: 'locations',
}

const __TITLE__ = 'ubicación';
const __BACK_PATH__ = '/dashboard/locations';

const LocationsForm = ({ customDismiss, getRecords }:{ customDismiss?:any, getRecords?:any }) => {
    const { handleSubmit, setValue, control, formState: {errors}, } = useForm();
    const [ formattedAddress, setFormattedAddress ] = useState<string>("");
    const [ isFormReady, setIsFormReady ] = useState<boolean>( false );
    const [ loading, setLoading ] = useState<boolean>( false );
    const [ center, setCenter ] = useState({
        lat: 15.871112589980743,
        lng: -97.07639694213863
    });
    const [ latLng, setLatLng ] = useState({
        lat: 15.871112589980743,
        lng: -97.07639694213863
    });

    const [ zoom, setZoom ] = useState<number>(10);

    const [ autocomplete, setAutocomplete ] = useState<any>(null);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: googleApiKey,
        libraries: ["places"]
    })

    const params = useParams();

    const [result, setResult] = useState<LocationType>({
        name: '',
        location: ''
    });

    useEffect(() => {
        getRecord().then();
    },[]);

    const getRecord = async () => {
        if(params.uid) {
            const { location } = await fetchRecord(componentDefaultProps.resource, params.uid);

            if( location.lat && location.lng ) {
                const latLng = {
                    lat: parseFloat( location.lat ),
                    lng: parseFloat( location.lng )
                }
                setLatLng( latLng );
                setCenter( latLng );
                setZoom( 18 );
            }
            setValue('name', location.name )
            setResult(location);
        }

        setIsFormReady( true );
    }

    const navigate = useNavigate();

    const submitForm = async (data:any) => {

        let record:any;

        if(latLng) {
            data.lat = latLng.lat;
            data.lng = latLng.lng;
        }

        if(formattedAddress) {
            data.formattedAddress = formattedAddress;
        }

        setLoading( true );

        if(params.uid){
            record = await updateRecord(componentDefaultProps.resource, data, params.uid); //If updating

        }else{
            record = await postRecord(componentDefaultProps.resource, data); //If creating
        }

        setLoading( false );

        if(record) {
            getRecords && getRecords();
            setLoading( false );
            customDismiss ?
                customDismiss() :
                navigate(`/dashboard/${componentDefaultProps.resource}`);
        }
    }

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const { geometry, formatted_address } = autocomplete.getPlace();

            setFormattedAddress( formatted_address );
            const lat = geometry?.location?.lat();
            const lng = geometry?.location?.lng();
            setCenter({ lat, lng });

            setLatLng({ lat, lng,})

            setZoom( 18 );

        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }

    const onLoad = (autocomplete:any) => {
        setAutocomplete(autocomplete);
    }

    return(
        <Paper sx={{ pt: 12 }} elevation={ 0 }>
            <Container maxWidth="md">
                <Typography align="center" variant="h6">
                    { params.uid ? `Editar ${componentDefaultProps.title}` : `Crear ${componentDefaultProps.title}` }
                </Typography>
                {
                    isFormReady ? (
                        <form onSubmit={handleSubmit(submitForm)}>
                            <Grid spacing={ 2 } container>
                                <Grid xs={12} item>

                                </Grid>
                                <Grid xs={ 12 } item>
                                    <Controller
                                        name="name"
                                        defaultValue={ result ? result.name : undefined }
                                        control={ control }
                                        rules={{
                                            required: 'El titulo es requerido.'
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                fullWidth
                                                onChange={ onChange }
                                                value={ value }
                                                label="Nombre"
                                            />
                                        )}
                                    />
                                    { errors.name && <Typography variant="caption" sx={{color:'red'} }>{errors.name.message}</Typography>}
                                </Grid>
                                <Grid xs={ 12 } item>
                                    {
                                        isLoaded ? (
                                            <GoogleMap
                                                mapContainerStyle={containerStyle}
                                                center={ center }
                                                zoom={ zoom }
                                            >
                                                <Autocomplete
                                                    onLoad={ onLoad }
                                                    onPlaceChanged={ onPlaceChanged }
                                                    restrictions={{
                                                        country: "mx"
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        onChange={ (e) => setFormattedAddress(e.target.value) }
                                                        placeholder="Buscar por dirección"
                                                        style={{
                                                            boxSizing: `border-box`,
                                                            border: `1px solid transparent`,
                                                            width: `340px`,
                                                            height: `40px`,
                                                            padding: `0 12px`,
                                                            borderRadius: `3px`,
                                                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                            fontSize: `14px`,
                                                            outline: `none`,
                                                            textOverflow: `ellipses`,
                                                            position: "absolute",
                                                            left: "50%",
                                                            marginLeft: "-120px"
                                                        }}
                                                    />
                                                </Autocomplete>
                                                <Marker
                                                    position={ latLng }
                                                    onDragEnd={(e:any) => {
                                                        setLatLng({
                                                            lat: e.latLng.lat(),
                                                            lng: e.latLng.lng()
                                                        })}}
                                                    draggable={true}
                                                />
                                            </GoogleMap>
                                        ) : <></>
                                    }
                                </Grid>
                            </Grid>
                            <Grid spacing={ 2 } sx={{ my: 2, justifyContent: 'center' }} container>
                                <Grid
                                    sx={{
                                        order: {
                                            xs: 2,
                                            md: 1
                                        }
                                    }}
                                    xs={ 12 }
                                    sm={ 4 } item
                                >
                                    <StyledButton
                                        fullWidth
                                        variant="outlined"
                                        onClick={ () => navigate(__BACK_PATH__ ) }
                                    >
                                        Cancelar
                                    </StyledButton>
                                </Grid>
                                <Grid
                                    sx={{
                                        order: {
                                            xs: 1,
                                            md: 2
                                        }
                                    }}
                                    xs={ 12 }
                                    sm={ 4 }
                                    item
                                >
                                    <StyledButton
                                        fullWidth
                                        variant="contained"
                                        disabled={ loading }
                                        startIcon={ loading ? <CircularProgress color="inherit" size={ 12 }/> : <Check/> }
                                        type="submit"
                                    >
                                        { loading ? (params.uid ? `Actualizando ${__TITLE__}...` : `Creando ${__TITLE__}`) :
                                            (params.uid ? `Actualizar ${__TITLE__}...` : `Crear ${__TITLE__}`) }
                                    </StyledButton>
                                </Grid>
                            </Grid>
                        </form>

                    ) : (
                        <Box
                            sx={{
                                width: '100%',
                                height: 400,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex'
                            }}>
                            <CircularProgress/>
                        </Box>
                    )
                }
            </Container>
        </Paper>
    )
}

export default LocationsForm;