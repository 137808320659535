import React, {useEffect, useState} from 'react';
import {Snackbar} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

type AlertMessagesHandlerPropsType = {
    type?: 'error' | 'success' | 'neutral',
    message: string | null,
    setMessage: any,
    open: boolean,
    handleClose: () => void;
}

export const useAlertMessagesHandler = () => {
    const [message, setMessage] = useState<string | null>();
    const [type, setType] = useState<'error' | 'success' | 'neutral'>('neutral');
    const [open, setOpen] = useState<boolean>(false);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleOpen = (message:string, type:'error' | 'success' | 'neutral') => {
        setMessage(message);
        setType(type)
        setOpen(true);
    }

    return {
        message,
        setMessage,
        open,
        type,
        handleOpen,
        handleClose
    }
}

export const AlertMessagesHandler = ({message, handleClose, open, type = 'neutral' }:AlertMessagesHandlerPropsType) => {

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return(
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={6000}
            onClose={handleClose}
            message={message}
            action={action}
        />
    )
};
