import { Button, Container, Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { fetchRecords } from "../../actions/fetchRecords";
import PropertyType from "../../types/PropertyType";
import {
  Add,
  ChevronLeft,
  Delete,
  DocumentScannerTwoTone,
  Download,
  Edit,
} from "@mui/icons-material";
import {
  DeleteModal,
  useModalDelete,
} from "../../components/common/DeleteModal";
import StyledButton from "../../styled/StyledButton";
import { BreadcrumbsBar } from "../../components/common/BreadcrumbsBar";
import {
  PdfPreviewModal,
  usePdfPrevierwModal,
} from "../../components/common/PdfPreviewModal";
import { postRecord } from "../../actions/postRecord";
import { fetchRecord } from "../../actions/fetchRecord";

const __RESOURCE__ = "contracts";
const __BUTTON_TEXT__ = "Nuevo contrato";
const __LABEL__ = "Contratos";

const ContractsList = () => {
  const navigate = useNavigate();
  const pdfModalProps = usePdfPrevierwModal();
  const [loading, setLoading] = useState<boolean>(true);
  const [results, setResults] = useState<PropertyType[]>([]);

  const {
    handleModal,
    openModal,
    handleDelete,
    modalTitle,
    setModalTitle,
    setUid,
  } = useModalDelete(__RESOURCE__);

  const columns: GridColDef[] = [
    {
      field: "contract_number",
      headerName: "No. de contrato",
      flex: 1,
      editable: true,
    },
    {
      field: "property",
      headerName: "Propiedad",
      flex: 1,
      editable: true,
      renderCell: (cellValues) => {
        return (
          <Box>
            <b>
              {cellValues?.formattedValue && cellValues.formattedValue.name}
            </b>
          </Box>
        );
      },
    },
    {
      field: "client",
      headerName: "Cliente",
      flex: 1,
      editable: true,
      renderCell: (cellValues) => {
        return (
          <Box>
            <b>{cellValues.formattedValue && cellValues.formattedValue.name}</b>
          </Box>
        );
      },
    },
    {
      field: "uid",
      headerName: "Acciones",
      width: 480,
      renderCell: (cellValues) => {
        return (
          <Box
            sx={{
              width: "100%",
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            <Button
              size="small"
              color="primary"
              startIcon={<Download />}
              onClick={async (event) => {
                event.stopPropagation();

                await pdfModalProps.getContractData(cellValues.id);
                pdfModalProps.handleModalForm();
              }}
            >
              Descargar PDF
            </Button>
            <Button
              size="small"
              color="primary"
              startIcon={<Edit />}
              disabled
              onClick={(event) => {
                event.stopPropagation();
                navigate(`/contabilidad/contratos/edit/${cellValues.id}`);
              }}
            >
              Editar
            </Button>
            <Button
              size="small"
              color="error"
              startIcon={<Delete />}
              onClick={(event) => {
                event.stopPropagation();

                setModalTitle(cellValues.row.name); //Set modal title

                setUid(cellValues.id.toString()); //Set-up the uid to delete

                handleModal(); //Open modal
              }}
            >
              Eliminar
            </Button>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    getRecords();
  }, []);

  async function getRecords() {
    const { contracts } = await fetchRecords(__RESOURCE__);

    setResults(contracts);

    if (contracts) {
      setLoading(false);
    }
  }

  const handleCreateContract = async () => {
    const latestContract = await fetchRecord("contracts/getLatest");
    let contract_number: number = latestContract?.contract?.contract_number
      ? latestContract.contract.contract_number
      : 0;

    if (contract_number !== undefined || latestContract.result === false) {
      contract_number++;
      const { contract } = await postRecord("contracts", {
        contract_number,
      });

      if (contract) {
        navigate(`/contabilidad/contratos/editar/${contract?.uid}`);
      }
    }
  };

  return (
    <>
      <DeleteModal
        handleDelete={handleDelete}
        handleModal={handleModal}
        openModal={openModal}
        modalTitle={modalTitle}
        getRecords={getRecords}
      />
      <PdfPreviewModal {...pdfModalProps} />
      <Paper sx={{ pt: 12 }} elevation={0}>
        <Container>
          <Grid
            alignContent="center"
            justifyContent="space-between"
            sx={{ mb: 4 }}
            container
          >
            <Grid
              xs={12}
              md={8}
              sx={{
                alignItems: "center",
                display: "flex",
                pb: {
                  xs: 4,
                  md: 0,
                },
              }}
              item
            >
              <BreadcrumbsBar
                currentPath={[
                  { to: "/system", label: "Sistema" },
                  { label: `${__LABEL__}` },
                ]}
              />
            </Grid>
            <Grid xs={12} md={4} display={"flex"} item>
              <StyledButton
                sx={{ mr: 1 }}
                variant="outlined"
                fullWidth
                startIcon={<DocumentScannerTwoTone />}
                onClick={() => navigate("/contabilidad/contratos/plantillas")}
              >
                Plantillas
              </StyledButton>
              <StyledButton
                fullWidth
                variant="contained"
                startIcon={<Add />}
                onClick={() => handleCreateContract()}
              >
                {__BUTTON_TEXT__}
              </StyledButton>
            </Grid>
          </Grid>
          <Box sx={{ width: "100%", height: "100%" }}>
            <DataGrid
              loading={loading}
              autoHeight
              rows={results}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
          </Box>
          <Grid mt={2} justifyContent="center" container>
            <Grid item>
              <Button
                sx={{ textTransform: "none" }}
                startIcon={<ChevronLeft />}
                onClick={() => navigate("/contabilidad")}
              >
                Volver
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </>
  );
};

export default ContractsList;
