import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {LinearProgress, Paper, Stack} from "@mui/material";
import {useState} from "react";
import {Add, Check, ChevronLeft} from "@mui/icons-material";
import StyledButton from "../../styled/StyledButton";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import {useNavigate} from "react-router-dom";
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};


export const useLoadingDialog = (  ) => {
    const [ openModal, setOpenModal ] = React.useState( false );
    const [ modalTitle, setModalTitle ] = useState<string>(' ');
    const [ modalDescription, setModalDescription ] =  useState<string>(' ');
    const [ uid, setUid ] = useState<string | null>(null);
    const [ percentage, setPercentage ] = useState<number | undefined>( undefined );
    const handleModal = () => setOpenModal(!openModal);

    const handleDelete = async () => {
        if(uid) {
            setOpenModal(false); //Close modal if is open
        }
    }

    return {
        openModal,
        handleModal,
        handleDelete,
        modalTitle,
        setModalTitle,
        setUid,
        percentage,
        setPercentage,
        modalDescription,
        setModalDescription
    }
}

type LoadingModalType = {
    handleModal: any;
    openModal: any;
    modalTitle: string;
    modalDescription: string;
    percentage: number | undefined;
    success: boolean;
    asModal?: boolean;
    successButtonText?: string;
    successButtonAction?: any;
    successButtonIcon?: any;
    errors?: any;
}

export const LoadingDialog = ({ 
    handleModal, 
    openModal,
    modalTitle,
    modalDescription,
    percentage,
    asModal,
    success,
    successButtonText,
    successButtonAction,
    successButtonIcon
}:LoadingModalType) => {

    const navigate = useNavigate();

    const content = () => (
        <>
            <Stack direction="row" spacing={ 2 }>
                {
                    success ? (
                        <Check/>
                    ) : (
                        <HourglassBottomIcon/>
                    )
                }
                <Typography color="text.primary" id="modal-modal-title" variant="h6">
                    { modalTitle } { percentage !== 0 && `(${ percentage }%)` }
                </Typography>
            </Stack>
            <Typography color="text.primary" id="modal-modal-description" sx={{ my: 2 }}>
                { modalDescription }
            </Typography>
            <LinearProgress
                color={ success ? 'success' : 'primary' }
                variant={ percentage ? 'determinate' : 'indeterminate' }
                value={ percentage }
            />
            {
                ( asModal || success ) && (
                    <Stack sx={{ justifyContent: 'space-between', mt: 2 }} direction="row">
                        <StyledButton
                            variant="outlined"
                            onClick={ () => navigate(-1) }
                            startIcon={ <ChevronLeft/> }
                        >
                            Volver al listado
                        </StyledButton>
                        <StyledButton
                            variant="outlined"
                            color="success"
                            startIcon={ successButtonIcon ? successButtonIcon : <Add/> }
                            onClick={ successButtonAction ? () => successButtonAction() : () => window.location.reload() }
                        >
                            { successButtonText }
                        </StyledButton>
                    </Stack>
                )
            }

        </>
    )
    return(

            asModal ?
                (
                    <Modal
                        open={openModal}
                        onClose={handleModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"

                    >
                        <Box sx={style}>
                            { content() }
                        </Box>
                    </Modal>
                ) : (
                    <Paper elevation={ 2 } sx={{
                        p: 2
                    }}>
                        { content() }
                    </Paper>
                )


    )
}