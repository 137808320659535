import { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Paper,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import { fetchRecord } from "../../actions/fetchRecord";
import TemplateType from "../../types/TemplateType";
import {
  Add,
  Delete,
  Save,
  StarBorder,
  StarBorderOutlined,
} from "@mui/icons-material";
import { postRecord } from "../../actions/postRecord";
import { useAlertMessagesHandler } from "../../components/common/AlertMessagesHandler";
import { updateRecord } from "../../actions/updateRecord";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import "../../styles/decoupledEditor.css";
import { deleteRecord } from "../../actions/deleteRecord";
import {
  EditorWrapper,
  useCkeditorWrapper,
} from "../../components/common/EditorWrapper";
import axios from "axios";

const { REACT_APP_API_HOST } = process.env;

const cardBasicStyles = {
  height: "100%",
  cursor: "pointer",
  position: "relative",
};

const style = {
  margin: 0,
  top: "auto",
  right: 20,
  bottom: 20,
  left: "auto",
  position: "fixed",
};

const Templates = (props) => {
  const alertMessagesHandlerProps = useAlertMessagesHandler();
  const [templatesArray, setTemplatesArray] = useState<Array<TemplateType>>([]);
  const editorWrapperProps = useCkeditorWrapper();
  const [editorDefaultData, setEditorDefaultData] =
    useState<any>("<h1>Editor</h1>");
  const [templateId, setTemplateId] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string>();

  useEffect(() => {
    getTemplates().then();
  }, []);

  const getTemplates = async () => {
    try {
      const { templates } = await fetchRecord("templates");

      if (templates) {
        setTemplatesArray(templates);
      }
    } catch (e) {
      alertMessagesHandlerProps.handleOpen(
        `Ocurrió un error al obtener los templates: ${e}`,
        "error"
      );
    }
  };

  const submitTemplate = async () => {
    const data = {
      content: editorWrapperProps.editorRef.current.getContent(),
      category: "Recibos",
      name,
    };

    editorWrapperProps.setProcessedData(
      editorWrapperProps.editorRef.current.getContent()
    );
    try {
      if (templateId) {
        const { template } = await updateRecord("templates", data, templateId);

        if (template) {
          alertMessagesHandlerProps.handleOpen(
            `La plantilla ${template.name} se actualizó correctamente`,
            "success"
          );
        }
      } else {
        const { template } = await postRecord("templates", data);

        if (template) {
          setTemplateId(template?.uid);
          alertMessagesHandlerProps.handleOpen(
            `La plantilla ${template.name} se guardó correctamente`,
            "success"
          );
        }
      }
    } catch (e) {
      console.log("e ", e);
      alertMessagesHandlerProps.handleOpen(
        `Ocurrió un error al guardar el template: ${e}`,
        "error"
      );
    }
  };

  const handleCardClick = (templateData: TemplateType) => {
    console.log(templateData);
    setName(templateData.name);
    if (templateData?.content) {
      setEditorDefaultData(templateData.content);
    } else {
      setEditorDefaultData("");
    }
    setTemplateId(templateData.uid);
  };

  const handleTemplateChange = async (currentStatus, templateId) => {
    const template = await updateRecord(
      "templates",
      { isActive: !currentStatus },
      templateId
    );
  };

  const handlePrintTemplate = async () => {
    axios({
      method: "get",
      url: `${REACT_APP_API_HOST}templates/getPdf/${templateId}`,
      responseType: "blob",
    })
      .then(function (response) {
        // create file link in browser's memory
        console.log("response.data ", response);
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `plantilla-${templateId}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const handleTemplateDelete = async (templateId) => {
    if (!templateId) {
      return;
    }
    await deleteRecord("templates", templateId);
    getTemplates().then();
  };

  return (
    <>
      <Paper sx={{ pt: 12 }} elevation={0}>
        <Container>
          <Grid container>
            <Box sx={{ "& > :not(style)": { m: 1 } }}>
              <Fab
                sx={style}
                onClick={submitTemplate}
                color="primary"
                aria-label="add"
              >
                <Save />
              </Fab>
            </Box>
            <Grid xs={12}>
              <Grid sx={{ height: "auto" }} container spacing={4}>
                {templatesArray.map((template: TemplateType) => (
                  <Grid key={template.uid} item xs={3}>
                    <Card
                      onClick={() => handleCardClick(template)}
                      sx={{ ...cardBasicStyles }}
                    >
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            position: "absolute",
                            right: 0,
                            top: 0,
                          }}
                        >
                          <ToggleButton
                            value="check"
                            selected={template?.isActive}
                            onChange={() =>
                              handleTemplateChange(
                                template.isActive,
                                template.uid
                              )
                            }
                          >
                            {!template?.isActive ? (
                              <StarBorderOutlined />
                            ) : (
                              <StarBorder />
                            )}
                             
                          </ToggleButton>
                          <IconButton
                            onClick={() => handleTemplateDelete(template.uid)}
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                        <Typography>{template.name}</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
                <Grid item xs={3}>
                  <Card
                    onClick={() => {}}
                    sx={{
                      ...cardBasicStyles,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CardContent sx={{}}>
                      <Add />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <TextField
                sx={{ mt: 4, mb: 2 }}
                value={name}
                placeholder="Nombre de la plantilla"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <EditorWrapper
                editorDefaultData={editorDefaultData}
                onSaveDoc={submitTemplate}
                onPrintDoc={handlePrintTemplate}
                {...editorWrapperProps}
              />
            </Grid>
            <Grid
              xs={12}
              sx={{ justifyContent: "right", display: "flex" }}
              item
            ></Grid>
          </Grid>
        </Container>
      </Paper>
    </>
  );
};

export default Templates;
