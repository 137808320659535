import {
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import LocationsSelect from "../../components/common/LocationsSelect";
import { Check, Edit, PictureAsPdf } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { BreadcrumbsBar } from "../../components/common/BreadcrumbsBar";
import {
  DropzoneComponent,
  useDropzoneComponent,
} from "../../components/common/DropzoneComponent";
import StyledButton from "../../styled/StyledButton";
import { useEffect, useRef, useState } from "react";
import CategoriesSelect from "../../components/common/CategoriesSelect";
import FeaturesSelect from "../../components/common/FeaturesSelect";
import { postRecord } from "../../actions/postRecord";
import { fetchRecord } from "../../actions/fetchRecord";
import PropertyType from "../../types/PropertyType";
import Box from "@mui/material/Box";
import { updateRecord } from "../../actions/updateRecord";
import { CoverDropzone } from "../../components/CoverDropzone";
import { PricesPlan, usePricesPlan } from "../../components/PricesPlan";
import {
  LoadingDialog,
  useLoadingDialog,
} from "../../components/common/LoadingDialog";
import {
  SlugCreator,
  useSlugCreator,
} from "../../components/common/SlugCreator";
import WithTheme from "../../components/common/WithTheme";
import { FilesUploader } from "../../components/common/FilesUploader";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios, { AxiosRequestHeaders } from "axios";
import ItemsSelect from "../../components/common/ItemsSelect";
import { fetchRecords } from "../../actions/fetchRecords";
import PropertyBanner from "../../components/common/PropertyBanner";
import PropertiesMeasurements from "./PropertiesMeasurements";

const __TITLE__ = "propiedad";
const __RESOURCE__ = "properties";
const __LABEL__ = "Propiedades";

const getInitialState = (defaultValue: any) => {
  if (defaultValue) {
    const blocksFromHtml = htmlToDraft(defaultValue);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    return EditorState.createWithContent(contentState);
  } else {
    return EditorState.createEmpty();
  }
};

const PropertiesForm = ({
  recordId,
  embedded = false,
}: {
  recordId?: string | null;
  embedded?: boolean;
}) => {
  const brochureUploaderRef: any = useRef(null);
  const blueprintUploaderRef: any = useRef(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
  } = useForm();
  const params = useParams();
  const [propertyId, setPropertyId] = useState<any>(params.uid);

  const {
    files,
    getInputProps,
    getRootProps,
    startUploading,
    progress,
    setCover,
    setFiles,
    totalPercentage,
    setTotalPercentage,
    setDeletedFiles,
    deletedFiles,
    previewItemsArray,
    setPreviewItemsArray,
  } = useDropzoneComponent(40);

  const pricesPlanProps = usePricesPlan();
  const [fullName, setFullName] = useState<string | undefined>(undefined);
  const {
    openModal,
    handleModal,
    modalTitle,
    setModalTitle,
    modalDescription,
    setModalDescription,
  } = useLoadingDialog();

  const [extraAttributes, setExtraAttributes] = useState<any>({
    isFeatured: false,
    isStarProject: false,
    definePriceZones: false,
  });

  const refCoverDropzone: any = useRef(null);
  const refFacilityLogo: any = useRef(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isFormReady, setIsFormReady] = useState<boolean>(false);
  const [clients, setClients] = useState<any>([]);
  const [parentProperty, setParentProperty] = useState<PropertyType | null>(
    null
  );
  const [monthlyPayments, setMonthlyPayments] = useState([]);
  const [measurements, setMeasurements] = useState<any>();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const { createdSlug, setCreatedSlug } = useSlugCreator();
  const navigate = useNavigate();

  const [record, setRecord] = useState<PropertyType>({
    slug: "",
    name: "",
    description: "",
    measures_unit: "",
    videos: [],
    images: [],
    category: "",
    keywords: "",
    status: true,
  });

  useEffect(() => {
    setPropertyId(recordId ? recordId : params.uid);
  }, [recordId, params.uid]);

  useEffect(() => {
    getRecord().then();
  }, [propertyId]);

  useEffect(() => {
    if (record?.description) {
      const initialState = getInitialState(record.description);
      setEditorState(initialState);
    }
  }, [record]);

  async function getClients() {
    const { clients } = await fetchRecords("clients");
    setClients(handleOptionItems(clients, "name"));
  }

  const handleOptionItems = (items: any, field: string) => {
    const optionsArray: any = [];

    items.map((item: any) =>
      optionsArray.push({
        label: item[field],
        uid: item.uid,
      })
    );

    return optionsArray;
  };

  const getRecord = async () => {
    setIsFormReady(false);
    if (propertyId) {
      const { property }: { property: any } = await fetchRecord(
        __RESOURCE__,
        propertyId
      );
      setValue("name", property.name);
      setValue("description", property.description);
      setValue("width", property.width);
      setValue("length", property.length);
      setValue("area", property.area);
      setValue("measures_unit", property.measures_unit);
      setValue("price", property.price);
      setValue("slug", property.slug);
      setValue("block", property.block);
      setValue("plot", property.plot);

      if (property.images) {
        setPreviewItemsArray(property.images);
      }

      pricesPlanProps.setTotalMonths(property?.total_financing_months);
      setRecord({
        ...property,
        category: property?.category?.name,
        location: property?.location?.name,
      });

      if (
        property?.selectable_financing_months &&
        property?.selectable_financing_months.length > 0
      ) {
        pricesPlanProps.setMonthsArray(property.selectable_financing_months);
      }

      setExtraAttributes({
        isFeatured: property.isFeatured,
        isProject: property.isProject,
      });

      if (property?.parent_property) {
        setParentProperty(property.parent_property);
        if (property?.monthly_payments) {
          setMonthlyPayments(property.monthly_payments);
        }
      }
    }

    setIsFormReady(true);
  };

  const validateIfProjectExists = async (slug: string) => {
    let result: boolean = false;
    //Validate that name doesn't exists
    const { property } = await fetchRecord("properties/bySlug", slug);
    if (property) {
      setError("slug", {
        type: "manual",
        message: "El ID único del proyecto ya existe.",
      });
      result = true;
    }

    return result;
  };

  const submitForm = async (data: any) => {
    handleModal();

    setLoading(true);
    setModalTitle(`Cargando...`);
    setModalDescription("No cierres ni recargues esta página.");

    setModalTitle(`Subiendo archivos...`);

    //If the user changes the username
    if (record.slug !== data.slug) {
      //Validate that name doesn't exists
      const existingProject = await validateIfProjectExists(data.slug);

      if (existingProject) {
        setLoading(false);
        handleModal();

        return;
      }
    }

    if (pricesPlanProps.monthsArray && pricesPlanProps.totalMonths) {
      data.total_financing_months = pricesPlanProps.totalMonths;
      data.selectable_financing_months = pricesPlanProps.monthsArray;
    }

    let result: any;

    data.isFeatured = extraAttributes.isFeatured;
    data.description = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    if (propertyId) {
      setModalTitle(`Actualizando ${__TITLE__}...`);

      result = await updateRecord(__RESOURCE__, data, propertyId); //If is updating
      //setModalTitle(`El ${ __TITLE__ } se actualizó correctamente.`);
      //setModalDescription( "Puedes salir de la página o seguir editando.");
      //setTotalPercentage( 100 );
      //setLoading( false );
    } else {
      setModalTitle(`Creando ${__TITLE__}...`);
      result = await postRecord(__RESOURCE__, data);
    }

    const zonesRequest: any = [];
    let zonesArray: any = [];

    if (zonesRequest.length > 0) {
      try {
        const authToken = localStorage.getItem("token");

        const headers: AxiosRequestHeaders = {
          "Content-Type": "application/json",
          "x-token": "",
        };

        if (authToken) {
          headers["x-token"] = authToken;
        }

        const zonesRequestResult = await axios.all(
          zonesRequest.map((req: any) => {
            if (req.isUpdating) {
              return axios.put(req.endpoint, req.data, {
                headers,
              });
            } else {
              return axios.post(req.endpoint, req.data, {
                headers,
              });
            }
          })
        );

        zonesRequestResult.map((response: any) => {
          if (response.status === 200) {
            const { uid } = response.data.zone;
            zonesArray.push(uid);
          }
        });

        await updateRecord(
          __RESOURCE__,
          {
            zones: zonesArray,
          },
          result.property.uid
        );
      } catch (e) {
        console.log("Error while uploading zones", e);
      }
    }

    setModalTitle(`Subiendo imagen de portada...`);

    let cover: any = null;

    if (refCoverDropzone?.current) {
      cover = await refCoverDropzone.current.uploadFile(`${data.slug}/cover`);
    } else {
      console.log("ocurrió un error al subir la portada");
    }

    if (cover.length > 0) {
      //Set a cover image to main data
      data.coverImage = cover;
    }

    setModalTitle(`Subiendo archivos...`);
    const brochure = await brochureUploaderRef.current.handleUpload(
      `${data.slug}/brochure`
    );

    if (brochure.length > 0) {
      //Set a cover image to main data
      data.brochureFile = brochure;
    }

    const blueprint = await blueprintUploaderRef.current.handleUpload(
      `${data.slug}/bluePrint`
    );

    if (blueprint.length > 0) {
      //Set a cover image to main data
      data.blueprintFile = blueprint;
    }

    setTotalPercentage(0);
    setModalTitle(`Subiendo imágenes del proyecto`);

    const imagesArray = await startUploading(`${data.slug}/gallery`);

    //if imagesArray > 0 assing to main data
    if (imagesArray.length > 0) {
      data.images = imagesArray;
    }

    setLoading(false);

    setModalTitle("Finalizando proceso.");
    await updateRecord("properties", data, result.property.uid);

    setModalTitle("El proyecto se creó correctamente.");
    setModalDescription("Puedes salir de la página o seguir editando.");
    setLoading(false);
  };

  return (
    <Paper sx={{ pt: embedded ? 0 : 12, pb: 2 }} elevation={embedded ? 1 : 0}>
      <Container maxWidth="md">
        {!embedded && (
          <BreadcrumbsBar
            currentPath={[
              { to: "/dashboard", label: "Inicio" },
              { to: `/dashboard/${__RESOURCE__}`, label: __LABEL__ },
              {
                label: propertyId
                  ? `Editar ${__TITLE__}`
                  : `Crear ${__TITLE__}`,
              },
            ]}
          />
        )}
        {isFormReady ? (
          <form style={{ marginTop: 16 }} onSubmit={handleSubmit(submitForm)}>
            <Grid spacing={2} container>
              {parentProperty?._id && (
                <Grid item xs={12}>
                  <PropertyBanner propertyId={parentProperty._id} />
                </Grid>
              )}
              <Grid item xs={12} md={8}>
                <Grid spacing={2} container>
                  <Grid xs={12} item>
                    <Controller
                      name="name"
                      control={control}
                      defaultValue={record ? record.name : undefined}
                      rules={{
                        required: "El nombre de la propiedad es requerido",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          disabled={loading}
                          fullWidth
                          onChange={(e) => {
                            onChange(e);
                          }}
                          onBlur={(e) => setFullName(e.target.value)}
                          value={value}
                          label="Nombre"
                        />
                      )}
                    />
                    {errors.name && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {errors.name.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid xs={12} item>
                    <SlugCreator
                      disabled={loading}
                      fullName={fullName}
                      createdSlug={createdSlug}
                      setCreatedSlug={setCreatedSlug}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      placeholder={`nombre-${__TITLE__}`}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue={record ? record.description : undefined}
                      render={() => (
                        <Editor
                          editorState={editorState}
                          onEditorStateChange={setEditorState}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} item>
                <Controller
                  name="timeFromDowntown"
                  control={control}
                  defaultValue={record ? record.timeFromDowntown : undefined}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled={loading}
                      fullWidth
                      onChange={(e) => {
                        onChange(e);
                      }}
                      value={value}
                      label="Tiempo desde Puerto Escondido"
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} item>
                <Controller
                  name="nearToBeaches"
                  control={control}
                  defaultValue={record ? record.nearToBeaches : undefined}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled={loading}
                      fullWidth
                      onChange={(e) => {
                        onChange(e);
                      }}
                      value={value}
                      label="Playas cercanas"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CoverDropzone
                  defaultPreview={record?.coverImage}
                  loading={loading}
                  defaultCoverPreview={record?.coverImage?.url}
                  ref={refCoverDropzone}
                  setDeletedFiles={setDeletedFiles}
                  deletedFiles={deletedFiles}
                />
              </Grid>
              <Grid xs={6} item>
                <Controller
                  name="category"
                  control={control}
                  rules={{}}
                  render={({ field: { onChange } }) => (
                    <CategoriesSelect
                      disabled={loading}
                      defaultValue={record ? record?.category : undefined}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} item>
                <Controller
                  name="location"
                  control={control}
                  rules={{}}
                  render={({ field: { onChange } }) => (
                    <LocationsSelect
                      disabled={loading}
                      defaultValue={record ? record?.location : undefined}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              <Grid xs={6} item>
                <Controller
                  name="plot"
                  control={control}
                  defaultValue={record ? record.plot : undefined}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled={loading}
                      fullWidth
                      onChange={(e) => {
                        onChange(e);
                      }}
                      value={value}
                      label="Lote"
                    />
                  )}
                />
              </Grid>
              <Grid xs={6} item>
                <Controller
                  name="block"
                  control={control}
                  defaultValue={record ? record.block : undefined}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled={loading}
                      fullWidth
                      onChange={(e) => {
                        onChange(e);
                      }}
                      value={value}
                      label="Manzana"
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} item>
                <Controller
                  name="fullAddress"
                  control={control}
                  defaultValue={record ? record.fullAddress : undefined}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled={loading}
                      fullWidth
                      onChange={(e) => {
                        onChange(e);
                      }}
                      value={value}
                      label="Domicilio (Calle, número exterior, colonia, municipio)"
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} item>
                <DropzoneComponent
                  previewItemsArray={previewItemsArray}
                  setPreviewItemsArray={setPreviewItemsArray}
                  loading={loading}
                  progress={progress}
                  getInputProps={getInputProps}
                  getRootProps={getRootProps}
                  files={files}
                  setCover={setCover}
                  setFiles={setFiles}
                  setDeletedFiles={setDeletedFiles}
                  deletedFiles={deletedFiles}
                />
              </Grid>
              <Grid xs={6} item>
                <FilesUploader
                  defaultFile={record?.brochureFile}
                  defaultName={record?.brochureFile?.url}
                  allowedFormats={[".pdf"]}
                  ref={brochureUploaderRef}
                  description="Arrastra y suelta o selecciona el PDF del folleto."
                  icon={<PictureAsPdf color="primary" fontSize="large" />}
                />
              </Grid>
              <Grid xs={6} item>
                <FilesUploader
                  defaultFile={record?.bluePrintImage}
                  allowedFormats={[".pdf"]}
                  ref={blueprintUploaderRef}
                  description="Arrastra y suelta o selecciona el PDF del plano."
                  icon={<PictureAsPdf color="primary" fontSize="large" />}
                />
              </Grid>
              <Grid xs={12} item>
                <Controller
                  name="features"
                  control={control}
                  rules={{}}
                  render={({ field: { onChange } }) => (
                    <FeaturesSelect
                      disabled={loading}
                      defaultValue={record ? record?.features : undefined}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>
              {parentProperty && (
                <>
                  <Grid xs={12} md={6} item>
                    <Controller
                      name="zone"
                      control={control}
                      rules={{}}
                      render={({ field: { onChange } }) => (
                        <ItemsSelect
                          disabled={loading}
                          defaultValue={
                            record?.zone ? record?.zone?.name : undefined
                          }
                          onChange={onChange}
                          items={parentProperty?.zones}
                          field="name"
                          autocompleteId="zones-auto-complete"
                          placeholder="Selecciona una Zona"
                          resource="zones"
                        />
                      )}
                    />
                  </Grid>
                  {/*
                                           <Grid item xs={12}>
                          {record?.monthly_payments && (
                            <PricesPlan
                              {...pricesPlanProps}
                              monthlyPayments={record.monthly_payments}
                            />
                          )}
                        </Grid>
                           */}
                  <Grid item xs={12} md={6}></Grid>
                  <Grid xs={12} item>
                    <Controller
                      name="measurements"
                      defaultValue={record ? record.measurements : undefined}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <PropertiesMeasurements
                          propertyId={propertyId}
                          setMeasurements={setMeasurements}
                        />
                      )}
                    />
                    {errors.measurements && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {errors.measurements.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="area"
                      control={control}
                      defaultValue={record ? record.area : undefined}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          disabled={loading}
                          fullWidth
                          onChange={onChange}
                          onBlur={(e) => setFullName(e.target.value)}
                          value={value}
                          label="Superficie total en m²"
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {openModal && (
              <Grid sx={{ my: 2 }} container>
                <Grid item xs={12}>
                  <LoadingDialog
                    handleModal={handleModal}
                    openModal={openModal}
                    modalTitle={modalTitle}
                    modalDescription={modalDescription}
                    percentage={totalPercentage}
                    success={!loading}
                    successButtonText={`Seguir editando...`}
                    successButtonAction={handleModal}
                    successButtonIcon={<Edit />}
                  />
                </Grid>
              </Grid>
            )}
            {!openModal && !loading && (
              <Grid
                spacing={2}
                sx={{ my: 2, justifyContent: "center" }}
                container
              >
                <Grid
                  sx={{
                    order: {
                      xs: 2,
                      md: 1,
                    },
                  }}
                  xs={12}
                  sm={4}
                  item
                >
                  <StyledButton
                    fullWidth
                    variant="outlined"
                    onClick={() => navigate(`/dashboard/${__RESOURCE__}`)}
                  >
                    Cancelar
                  </StyledButton>
                </Grid>
                <Grid
                  sx={{
                    order: {
                      xs: 1,
                      md: 2,
                    },
                  }}
                  xs={12}
                  sm={4}
                  item
                >
                  <StyledButton
                    fullWidth
                    variant="contained"
                    disabled={loading}
                    startIcon={
                      loading ? (
                        <CircularProgress color="inherit" size={12} />
                      ) : (
                        <Check />
                      )
                    }
                    type="submit"
                  >
                    {loading
                      ? propertyId
                        ? `Actualizando ${__TITLE__}...`
                        : `Creando ${__TITLE__}`
                      : propertyId
                      ? `Actualizar ${__TITLE__}...`
                      : `Crear ${__TITLE__}`}
                  </StyledButton>
                </Grid>
              </Grid>
            )}
          </form>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: 400,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
    </Paper>
  );
};

export default WithTheme(PropertiesForm);
