import React from "react";
import { Toolbar, IconButton, Menu, MenuItem, Paper} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { motion } from "framer-motion";
import Logo from "./../assets/logo.svg";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Link, useNavigate } from "react-router-dom";
import {CSSObject, styled, Theme, useTheme} from '@mui/material/styles';
import MenuIcon from "@mui/icons-material/Menu";
import MiniDrawer, {useDrawer} from "./common/DrawerComponent";
const drawerWidth = 240;



interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Header = () => {
    const navigation = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const theme = useTheme();
    const { open, handleDrawer } = useDrawer();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        navigation('/');
        setAnchorEl(null);
    };

    return(
        <>
            <AppBar
                position="fixed"
            >
                <Toolbar sx={{
                    backgroundColor: theme.palette.primary.main,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawer}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Link to="/dashboard">
                        <motion.img
                            initial={{
                                opacity: 0,
                                x: -10,
                            }}
                            animate={{
                                x:0,
                                opacity: 1,
                                transition: {
                                    delay: 0.5
                                }
                            }}
                            style={{
                                height: 40,
                            }}
                            alt="logo"
                            src={Logo}
                        />
                    </Link>
                    <MiniDrawer open={open} handleDrawer={handleDrawer}/>
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                            sx={{
                                p:0
                            }}
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose}>Mi perfil</MenuItem>
                            <MenuItem onClick={handleClose}>Cerrar Sesión</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        </>

    );
}

export default Header;