import {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import {fetchRecords} from "../../actions/fetchRecords";
import { useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const FeaturesSelect = ({ defaultValue, onChange, disabled }:{ defaultValue:any, onChange:any, disabled:boolean }) => {
    const [ options, setOptions ] = useState<[]>([]);
    const [ feature, setFeature ] = useState<any>([]);
    const theme = useTheme();



    useEffect( () => {
        getRecords().then(() => {
            if(defaultValue) {
                const arrFeatures:any = [];

                defaultValue.map((item:any) => (
                    arrFeatures.push(item._id)
                ));

                setFeature( arrFeatures );
            }
        });

    },[]);


    const handleChange = (event: SelectChangeEvent<typeof feature>) => {

        const {
            target: { value },
        } = event;

        setFeature( typeof value === 'string' ? value.split(',') : value );
        onChange( typeof value === 'string' ? value.split(',') : value );
    };

    /*
    function getStyles(name: string, personName: readonly string[], theme: Theme) {
        return {
            fontWeight:
                options.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }*/

    async function getRecords() {
        const { features } = await fetchRecords( 'features' );

        const optionsArray:any = [];

        features.map((item:any) => (
            optionsArray.push({
                label: item.name,
                uid: item.uid
            })
        ))

        setOptions(optionsArray);
    }

    const findLabel = ( selectedUId:any ) => {
        let index = options.findIndex(function( option:any) {
            return option.uid === selectedUId;
        });

        const { label } = options[index];

        return label;
    }

    return(
        <FormControl sx={{ width: '100%' }}>
            <InputLabel id="property-chip-label">Características y servicios</InputLabel>
            <Select
                disabled={ disabled }
                fullWidth
                labelId="property-chip-label"
                id="property-multiple-chip"
                multiple
                value={ feature }
                onChange={ handleChange }
                input={<OutlinedInput id="select-multiple-chip" label="Características y servicios" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value:any) => (
                            <Chip key={value} label={ findLabel( value ) } />
                        ))}
                    </Box>
                )}
                MenuProps={ MenuProps }
            >
                {options.map((item:any) => (
                    <MenuItem
                        key={item.uid}
                        value={ item.uid }
                        style={ { fontWeight: theme.typography.fontWeightMedium }}
                    >
                        { item.label }
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default FeaturesSelect;