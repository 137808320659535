import { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  Container,
  Grid,
  Paper,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { RemoveRedEye } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { fetchRecord } from "../../actions/fetchRecord";
import StyledButton from "../../styled/StyledButton";
import Box from "@mui/material/Box";
import {
  Beneficiaries,
  useBeneficiaries,
} from "../../components/Beneficiaries";
import ContractsType from "../../types/ContractsType";
import InputAdornment from "@mui/material/InputAdornment";
import {
  PdfPreviewModal,
  usePdfPrevierwModal,
} from "../../components/common/PdfPreviewModal";
import { updateRecord } from "../../actions/updateRecord";
import PropertyType from "../../types/PropertyType";
import { postRecord } from "../../actions/postRecord";
import { formatContractNumber } from "../../helpers/formatContractNumber";
import SellersForm from "../accounting/sellers/SellersForm";
import ClientsForm from "../clients/ClientsForm";
import PropertiesForm from "../properties/PropertiesForm";
import ItemsSelect from "../../components/common/ItemsSelect";
import {
  CreatablePaymentsPlan,
  useCreatablePaymentsPlan,
} from "../../components/CreatablePaymentsPlan";
import {
  ContractPDFPreview,
  useContractPDFPreview,
} from "../../components/common/ContractPDFPreview";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { ContractHTML, useContractHTML } from "../../components/ContractHtml";
import Button from "@mui/material/Button";
import SellersType from "../../types/SellersType";
import ClientsType from "../../types/ClientsType";
import SaveFloatingButton from "../../components/common/SaveFloatingButton";
//import { BalloonEditor } from '@ckeditor/ckeditor5-editor-balloon';
//import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';

const __TITLE__ = "contrato";
const __RESOURCE__ = "contracts";

const ContractsForm = ({
  customDismiss,
  getRecords,
}: {
  customDismiss?: any;
  getRecords?: any;
}) => {
  const params = useParams();

  const {
    getValues,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const getInitialState = (defaultValue: any) => {
    if (defaultValue) {
      const blocksFromHtml = htmlToDraft(defaultValue);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      return EditorState.createWithContent(contentState);
    } else {
      return EditorState.createEmpty();
    }
  };
  const [isFormReady, setIsFormReady] = useState<boolean>(false);
  const [contractId, setContractId] = useState<any>(params.contractId);
  const sellers: SellersType[] = watch("sellers", false);
  const client = watch("client", false);
  const property = watch("property", false);
  const [loading, setLoading] = useState<boolean>(false);
  const [propertyData, setPropertyData] = useState<PropertyType | null>(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [currentContractNumber, setCurrentContractNumber] = useState<number>(0);
  const facilityLogoRef: any = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const beneficiaryProps = useBeneficiaries();
  const creatablePaymentsPlanProps = useCreatablePaymentsPlan();
  const contractPDFPreviewProps = useContractPDFPreview();

  const pdfPreviewParams = usePdfPrevierwModal();

  const steps = [
    "Vendedor",
    "Cliente y beneficiarios",
    "Propiedad y fianciamiento",
    "Vista previa",
  ];
  const [result, setResult] = useState<ContractsType | null>(null);
  const contractHTMLProps = useContractHTML();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  useEffect(() => {
    //getRecord().then();
    getLatestContract().then();
    //const initialState = getInitialState(ContractHTML);
    getRecord().then();
    //setEditorState(initialState);
  }, []);

  useEffect(() => {
    console.log("seller ", sellers);
  }, [sellers]);

  const getRecord = async () => {
    const { contract } = await fetchRecord(
      "contracts/getRecord",
      params.contractId
    );
    //setCurrentContractNumber()
    console.log("contract ", contract);
    //setValue("name", contract.name);
    //const initialState = getInitialState(contract.content);
    //setEditorState(initialState);
    setResult(contract);
    //setEditorData(contract?.content)
    setIsFormReady(true);
  };

  const getLatestContract = async () => {
    const { contract } = await fetchRecord(`${__RESOURCE__}/getLatest`);
    let contract_number_formatted: string = "000001";

    if (contract?.contract_number) {
      setCurrentContractNumber(contract.contract_number);
      contract_number_formatted = formatContractNumber(
        contract.contract_number
      );
    }

    setValue("contract_number_formatted", contract_number_formatted);

    setIsFormReady(true);
  };

  const navigate = useNavigate();

  const saveContract = async () => {
    const data: any = getValues();
    const dataSellersArray: [SellersType] = data.sellers;
    const dataClient: ClientsType = data.client;

    data.content = contractHTMLProps.editorData?.getData();
    data.contract_number = currentContractNumber + 1;
    data.property = data?.property?.id;
    data.financing_plan = creatablePaymentsPlanProps.financingPlan._id;

    let record: any;

    if (dataSellersArray) {
      const uidArray: Array<string> = [];
      dataSellersArray.map((seller: SellersType) => {
        if (seller?.uid) {
          uidArray.push(seller.uid);
        }
      });

      data.sellers = uidArray;
    }

    if (dataClient) {
      data.client = dataClient.uid;
    }

    if (contractId) {
      record = await updateRecord("contracts", data, contractId); //If creating
    } else {
      record = await postRecord("contracts", data); //If creating
    }

    let paymentPlanResult;
    if (creatablePaymentsPlanProps.paymentsPlan) {
      paymentPlanResult = await creatablePaymentsPlanProps.savePaymentPlan(
        record.contractResult?.id,
        record.contractResult?.payment_plan
      );
    }
    if (paymentPlanResult) {
      await updateRecord(
        "contracts",
        {
          payment_plan: paymentPlanResult?.paymentPlan?.uid,
        },
        contractId
      );
    }
  };

  const submitForm = async (data: any) => {
    let record: any;
    let facilityLogo = null;

    data.property = data.property[0];
    data.contract_number = currentContractNumber + 1;

    record = await postRecord("contracts", data); //If creating

    const resultBeneficiaries = await beneficiaryProps.uploadToServer(
      record.uid
    );

    if (resultBeneficiaries.length > 0) {
      data.beneficiaries = resultBeneficiaries;
    }

    record = await updateRecord("contracts", data, record.contract.uid);

    setLoading(false);
    //pdfPreviewParams.setUid(record.contract.uid);
    //await pdfPreviewParams.handleModalForm(record.contract.uid);
    /*
        if(record) {
            navigate(`/contabilidad/contratos`);
        }*/
  };

  const handleStep = (step: number) => () => {
    saveContract().then();
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const sellerSection = () => (
    <Grid spacing={4} mt={2} container>
      <Grid xs={12} item>
        <Controller
          name="sellers"
          control={control}
          rules={{
            required: "El vendedor es requerido.",
          }}
          render={({ field: { onChange, value } }) => (
            <ItemsSelect
              disabled={loading}
              defaultValue={value}
              onChange={onChange}
              field="name"
              autocompleteId="sellers-auto-complete"
              placeholder="Selecciona un vendedor"
              resource="sellers"
              setCompleteObject
              multiple
            />
          )}
        />
        {errors.sellers && (
          <Typography variant="caption" sx={{ color: "red" }}>
            {errors.sellers.message}
          </Typography>
        )}
      </Grid>
      {sellers && (
        <Grid item xs={12}>
          {/*
                                <SellersForm recordId={seller.uid} embedded/>
                            */}
        </Grid>
      )}
    </Grid>
  );

  const clientSection = () => (
    <Grid spacing={4} mt={2} container>
      <Grid xs={12} item>
        <Grid xs={12} item>
          <Controller
            name="client"
            control={control}
            rules={{
              required: "El cliente es requerido.",
            }}
            render={({ field: { onChange, value } }) => (
              <ItemsSelect
                disabled={loading}
                defaultValue={value}
                onChange={onChange}
                field="name"
                autocompleteId="clients-auto-complete"
                placeholder="Selecciona un cliente"
                resource="clients"
                setCompleteObject
              />
            )}
          />
          {errors.client && (
            <Typography variant="caption" sx={{ color: "red" }}>
              {errors.client.message}
            </Typography>
          )}
        </Grid>
      </Grid>
      {client && (
        <>
          <Grid item xs={12}>
            <ClientsForm recordId={client.uid} embedded />
          </Grid>
          <Grid xs={12} item>
            <Beneficiaries redirect={false} {...beneficiaryProps} />
            {errors.beneficiaries && (
              <Typography variant="caption" sx={{ color: "red" }}>
                {errors.beneficiaries.message}
              </Typography>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );

  const propertySection = () => (
    <Grid spacing={4} mt={2} container>
      <Grid xs={12} item>
        <Grid xs={12} item>
          <Controller
            name="property"
            control={control}
            rules={{
              required: "La propiedad es requerida",
            }}
            render={({ field: { onChange, value } }) => (
              <ItemsSelect
                disabled={loading}
                defaultValue={value}
                onChange={onChange}
                field="name"
                autocompleteId="properties-auto-complete"
                placeholder="Selecciona una propiedad/lote"
                resource="properties"
                setCompleteObject
              />
            )}
          />
          {errors.property && (
            <Typography variant="caption" sx={{ color: "red" }}>
              {errors.property.message}
            </Typography>
          )}
        </Grid>
      </Grid>
      {property && (
        <>
          <Grid item xs={12}>
            <PropertiesForm recordId={property.uid} embedded />
          </Grid>
          <Grid item xs={12}>
            <CreatablePaymentsPlan
              propertyId={property.uid}
              {...creatablePaymentsPlanProps}
            />
          </Grid>
        </>
      )}
    </Grid>
  );

  const contractPreviewSection = () => (
    <>
      <Container>
        <Grid>
          <Button onClick={() => saveContract()}>Guardar</Button>
        </Grid>
        <Grid>
          <ContractHTML
            contractId={contractId}
            sellers={sellers}
            clientId={client.uid}
            {...contractHTMLProps}
          />
        </Grid>
      </Container>
    </>
  );

  return (
    <Paper sx={{ pt: 12 }} elevation={0}>
      <PdfPreviewModal {...pdfPreviewParams} />
      <SaveFloatingButton mainAction={saveContract} />
      <Container maxWidth="md">
        <Grid
          sx={{ mb: 2 }}
          justifyContent="space-between"
          alignItems="center"
          container
        >
          <Grid xs={6} item>
            <Typography align="left" variant="h6">
              {params.uid ? `Editar ${__TITLE__}` : `Crear ${__TITLE__}`}
            </Typography>
          </Grid>
          <Grid xs={6} sx={{ display: "flex", justifyContent: "end" }} item>
            <Controller
              name="contract_number_formatted"
              //defaultValue={result ? result.contract_number : undefined}
              control={control}
              rules={{
                required: "El número de contrato es requerido.",
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  label="Número de contrato"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">C</InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {errors.contract_number && (
              <Typography variant="caption" sx={{ color: "red" }}>
                {errors.contract_number.message}
              </Typography>
            )}
          </Grid>
        </Grid>

        {isFormReady ? (
          <>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step
                  key={index}
                  completed={completed[index]}
                  disabled={(!sellers || !client) && totalSteps() === index + 1}
                >
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <Container>
              {(() => {
                switch (activeStep) {
                  case 0:
                    return sellerSection();
                  case 1:
                    return clientSection();
                  case 2:
                    return propertySection();
                  case 3:
                    return contractPreviewSection();
                  default:
                    return <></>;
                }
              })()}
              <Grid spacing={2} mt={2} container></Grid>
            </Container>
            <Grid
              spacing={2}
              sx={{ mb: 2, mt: 1, justifyContent: "center" }}
              container
            >
              <Grid
                sx={{
                  order: {
                    xs: 2,
                    md: 1,
                  },
                }}
                xs={12}
                sm={4}
                item
              >
                <StyledButton
                  fullWidth
                  variant="outlined"
                  onClick={() => navigate(`/contabilidad/clientes`)}
                >
                  Cancelar
                </StyledButton>
              </Grid>
              <Grid
                sx={{
                  order: {
                    xs: 1,
                    md: 2,
                  },
                }}
                xs={12}
                sm={4}
                item
              >
                <StyledButton
                  fullWidth
                  form="contract-form"
                  variant="contained"
                  disabled={true}
                  startIcon={
                    loading ? (
                      <CircularProgress color="inherit" size={12} />
                    ) : (
                      <RemoveRedEye />
                    )
                  }
                  //onClick={() => pdfPreviewParams.handleModalForm()}
                  type="submit"
                >
                  {loading
                    ? params.uid
                      ? `Actualizando ${__TITLE__}...`
                      : `Creando ${__TITLE__}`
                    : params.uid
                    ? `Actualizar ${__TITLE__}...`
                    : `Generar vista previa`}
                </StyledButton>
              </Grid>
            </Grid>
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: 400,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
    </Paper>
  );
};

export default ContractsForm;
