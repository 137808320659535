import { Route } from "react-router-dom";
import * as React from "react";
import { dashboardUrlBase } from "../constants/site";
import CategoriesPropertiesList from "../pages/categories/CategoriesPropertiesList";
import CategoriesList from "../pages/categories/CategoriesList";
import CategoriesFormPage from "../pages/categories/CategoriesFormPage";

export const categoriesBaseUrl = "categorias";

export const useCategoriesRoutes = () => (
  <React.Fragment>
    <Route
      path={`/${dashboardUrlBase}/${categoriesBaseUrl}`}
      element={<CategoriesList />}
    />
    <Route
      path={`/${dashboardUrlBase}/${categoriesBaseUrl}/:category/propiedades`}
      element={<CategoriesPropertiesList />}
    />
    <Route
      path={`/${dashboardUrlBase}/${categoriesBaseUrl}/nueva`}
      element={<CategoriesFormPage />}
    />
  </React.Fragment>
);
