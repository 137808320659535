import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Check } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { postRecord } from "../../../actions/postRecord";
import { fetchRecord } from "../../../actions/fetchRecord";
import { updateRecord } from "../../../actions/updateRecord";
import StyledButton from "../../../styled/StyledButton";
import Box from "@mui/material/Box";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { DateField } from "@mui/x-date-pickers/DateField/DateField";
import ReceiptType from "../../../types/ReceiptType";
import ContractsSelect from "../../contracts/ContractsSelect";
import PaymentMethodSelect from "../../../components/common/PaymentMethodSelect";
import ItemsSelect from "../../../components/common/ItemsSelect";
import PaymentPlansType from "../../../types/PaymentPlansType";
import PaymentsType from "../../../types/PaymentsType";
import ContractsType from "../../../types/ContractsType";

const __TITLE__ = "recibo";
const __RESOURCE__ = "receipts";

const ReceiptsForm = ({
  customDismiss,
  getRecords,
}: {
  customDismiss?: any;
  getRecords?: any;
}) => {
  const {
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const [isFormReady, setIsFormReady] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [contractId, setContractId] = useState<string>();
  const [valueFrom, setValueDateFrom] = React.useState<Dayjs | null>(
    dayjs(Date.now())
  );
  const params = useParams();
  const paymentNumber = watch("paymentNumber", false);
  const [result, setResult] = useState<ReceiptType>();
  const [paymentPlansOptions, setPaymentPlansOptions] = useState<any>([]);
  const [contract, setContract] = useState<ContractsType>();
  const [latestReceipt, setLatestReceipt] = useState<ReceiptType>();
  const [latestClientPayment, setLatestClientPayment] = useState<any>();
  const [paymentMethod, setPaymentMethod] = useState<any>();

  useEffect(() => {
    getRecord().then();
  }, []);

  useEffect(() => {
    getContract().then();
    getLatestReceipt().then();
    getLatestClientPayment().then();
  }, [contractId]);

  const getRecord = async () => {
    if (params.uid) {
      const { receipt } = await fetchRecord("receipts/getRecord", params.uid);

      setValue("name", receipt.name);
      setResult(receipt);
    }

    setIsFormReady(true);
  };

  const getLatestReceipt = async () => {
    const { receipt } = await fetchRecord("receipts/getLatest");
    if (receipt) {
      setValue("receiptNumber", receipt.receiptNumber + 1);
      setLatestReceipt(receipt);
    }
  };

  const getLatestClientPayment = async () => {
    const { clientPayment } = await fetchRecord("clientPayments/getLatest");
    if (clientPayment) {
      setLatestClientPayment(clientPayment);
    }
  };

  const getContract = async () => {
    if (!contractId) {
      return;
    }
    try {
      const { contract } = await fetchRecord("contracts/getRecord", contractId);

      setContract(contract);
      if (contract?.payment_plan?.payments) {
        const ppo: any = [];

        contract?.payment_plan?.payments.map((paymentPlan: PaymentsType) => {
          if (!paymentPlan.paid) {
            return ppo.push({
              ...paymentPlan,
              label: `Pago ${paymentPlan.payment_number}`,
              uid: paymentPlan._id,
            });
          }
        });

        setPaymentPlansOptions(ppo);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeFrom = (newValue: Dayjs | null) => {
    setValueDateFrom(newValue);
  };

  const navigate = useNavigate();

  const submitForm = async (data: any) => {
    let record: any;

    data.date = valueFrom?.valueOf();

    if (data?.paymentNumber?.payment_number) {
      data.paymentNumber = data.paymentNumber.payment_number;
    }

    setLoading(true);
    data.amount = parseInt(data.amount);

    if (params.uid) {
      record = await updateRecord(__RESOURCE__, data, params.uid); //If updating
    } else {
      record = await postRecord(__RESOURCE__, data); //If creating
    }

    data.paidAmount =
      (latestClientPayment?.paidAmount ? latestClientPayment.paidAmount : 0) +
      data.amount;

    if (contract?.payment_plan?.total_price) {
      data.remainingAmount =
        contract?.payment_plan?.total_price -
        (data.paidAmount ? data.paidAmount : 0);
    }

    if (record) {
      const { clientPayments } = await postRecord("clientPayments", {
        ...data,
        receipt: record.receipt.uid,
      });

      if (clientPayments && contract) {
        await updateRecord(
          __RESOURCE__,
          {
            client_payment: clientPayments?.uid,
            paymentId: paymentNumber._id,
            paymentPlanId: contract.payment_plan?._id,
          },
          record.receipt.uid
        );
      }
    }

    setLoading(false);

    if (record?.receipt) {
      navigate(`/contabilidad/recibos/${record?.receipt?.uid}`);
    }
  };

  return (
    <Paper sx={{ pt: 12 }} elevation={0}>
      <Container maxWidth="md">
        <Typography align="center" variant="h6">
          {params.uid ? `Editar ${__TITLE__}` : `Crear ${__TITLE__}`}
        </Typography>
        {isFormReady ? (
          <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <form onSubmit={handleSubmit(submitForm)}>
                <Grid spacing={2} container>
                  <Grid xs={6} item>
                    <Controller
                      name="contract"
                      defaultValue={result ? result.contract : undefined}
                      control={control}
                      rules={{
                        required: "El nombre es requerido.",
                      }}
                      render={({ field: { onChange, value } }) => {
                        setContractId(value);
                        return (
                          <ContractsSelect
                            defaultValue={result ? result?.contract : undefined}
                            onChange={onChange}
                            disabled={loading}
                          />
                        );
                      }}
                    />
                    {errors.name && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {errors.name.message}
                      </Typography>
                    )}
                  </Grid>
                  {latestReceipt && contract && (
                    <Grid xs={6} item>
                      <Controller
                        name="receiptNumber"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            disabled={loading}
                            value={value}
                            onChange={onChange}
                            label="Número de recibo"
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid xs={12} item>
                    <Controller
                      name="date"
                      defaultValue={result ? result.date : undefined}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DateField
                          label="Fecha de pago"
                          value={valueFrom}
                          format="DD/MM/YYYY"
                          onChange={handleChangeFrom}
                          // @ts-ignore
                          renderInput={(params: any) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <Controller
                      name="concept"
                      defaultValue={result ? result.concept : undefined}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          multiline
                          disabled={loading}
                          defaultValue={result?.concept}
                          onChange={onChange}
                          label="Concepto"
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={6} item>
                    <Controller
                      name="paymentMethod"
                      defaultValue={result ? result.paymentMethod : undefined}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <ItemsSelect
                          defaultValue={undefined}
                          onChange={(e) => {
                            setValue("paymentMethod", e.value);
                          }}
                          disabled={false}
                          resource={""}
                          items={[
                            { value: "transferencia", label: "Transferencia" },
                            { value: "efectivo", label: "Efectivo" },
                          ]}
                          placeholder="Método de pago"
                          autocompleteId="metodo-pago-select"
                          setCompleteObject
                        />
                      )}
                    />
                  </Grid>

                  {paymentPlansOptions.length > 0 && (
                    <Grid xs={6} item>
                      <Controller
                        name="paymentNumber"
                        defaultValue={undefined}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <ItemsSelect
                            defaultValue={undefined}
                            onChange={(e: any) => {
                              onChange(e);
                            }}
                            items={paymentPlansOptions}
                            disabled={false}
                            placeholder="Pago correspondiente"
                            resource=""
                            autocompleteId="payment"
                            handleIncomingItems={false}
                            setCompleteObject
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {paymentNumber?.amount && (
                    <Grid xs={6} item>
                      <Controller
                        name="amount"
                        defaultValue={paymentNumber.amount}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            disabled={loading}
                            defaultValue={value}
                            onChange={onChange}
                            label="Cantidad"
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid
                  spacing={2}
                  sx={{ my: 2, justifyContent: "center" }}
                  container
                >
                  <Grid
                    sx={{
                      order: {
                        xs: 2,
                        md: 1,
                      },
                    }}
                    xs={12}
                    sm={4}
                    item
                  >
                    <StyledButton
                      fullWidth
                      variant="outlined"
                      onClick={() => navigate(`/contabilidad/recibos`)}
                    >
                      Cancelar
                    </StyledButton>
                  </Grid>
                  <Grid
                    sx={{
                      order: {
                        xs: 1,
                        md: 2,
                      },
                    }}
                    xs={12}
                    sm={4}
                    item
                  >
                    <StyledButton
                      fullWidth
                      variant="contained"
                      disabled={loading || !contract}
                      startIcon={
                        loading ? (
                          <CircularProgress color="inherit" size={12} />
                        ) : (
                          <Check />
                        )
                      }
                      type="submit"
                    >
                      {loading
                        ? params.uid
                          ? `Actualizando ${__TITLE__}...`
                          : `Creando ${__TITLE__}`
                        : params.uid
                        ? `Actualizar ${__TITLE__}...`
                        : `Crear ${__TITLE__}`}
                    </StyledButton>
                  </Grid>
                </Grid>
              </form>
            </LocalizationProvider>
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: 400,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
    </Paper>
  );
};

export default ReceiptsForm;
