import {DropzoneComponent, useDropzoneComponent} from "./common/DropzoneComponent";
import {forwardRef, useEffect, useImperativeHandle} from "react";

export const CoverDropzone = forwardRef(( props:any,ref:any ) => {
    const { files, getInputProps, getRootProps, startUploading, progress, setCover, setFiles, croppedData, setCroppedData, setDeletedFiles, deletedFiles } = useDropzoneComponent( 1 );

    useEffect(() => {
        if( props.defaultCoverPreview ) {
            setCroppedData( props.defaultCoverPreview );
        }
    },[]);

    useImperativeHandle(ref,() => ({
        uploadFile: async (folder:string) => {
            return await startUploading(folder)
        }})
    );

    return(<DropzoneComponent
        defaultPreview={ props?.defaultPreview }
        loading={ props?.loading }
        title={ props?.title ? props.title : "Arrastra y suelta o selecciona una imagen de portada." }
        progress={ progress }
        getInputProps={ getInputProps }
        getRootProps={ getRootProps }
        files={ files }
        setCover={ setCover }
        setFiles={ setFiles }
        withCropper={ true }
        croppedData={ croppedData }
        setCroppedData={ setCroppedData }
        setDeletedFiles={ setDeletedFiles }
        deletedFiles={ deletedFiles }
    />)
})
