import {useEffect, useState} from "react";
import {Container, Grid, Paper, TextField, Typography} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {postRecord} from "../../actions/postRecord";
import CategoryType from "../../types/CategoryType";
import {fetchRecord} from "../../actions/fetchRecord";
import {updateRecord} from "../../actions/updateRecord";
import CategoriesForm from "../../components/common/CategoriesForm";
import {BreadcrumbsBar} from "../../components/common/BreadcrumbsBar";

const componentDefaultProps = {
    title: 'categoría',
    resource: 'categories',
    addButtonText: 'Nueva categoría',
    formPath: 'categories',
}

const CategoriesFormPage = () => {
    const { handleSubmit, setValue, control, formState: {errors}, } = useForm();
    const params = useParams();
    const [result, setResult] = useState<CategoryType>({
        name: '',
        description: ''
    });

    useEffect(() => {
        getRecord().then();
    },[]);

    const getRecord = async () => {
        if(params.uid) {
            const { category } = await fetchRecord(componentDefaultProps.resource, params.uid);
            setValue('name', category.name )
            setValue('description', category.description )
            setResult(category);
        }
    }

    const navigate = useNavigate();

    const submitForm = async (data:any) => {

        let result:any;

        if(params.uid){
            result = await updateRecord(componentDefaultProps.resource, data, params.uid); //If updating

        }else{
            result = await postRecord(componentDefaultProps.resource, data); //If creating
        }

        if(result) {
            navigate('/dashboard/categories');
        }
    }

    return(
        <Paper sx={{ pt: 12 }} elevation={ 0 }>
            <Container maxWidth="md">
                <BreadcrumbsBar currentPath={[
                    { to: '/dashboard', label: 'Inicio' },
                    { to: '/dashboard/categories', label: 'Categorías' },
                    { label: params.uid ? 'Editar categoría' : 'Crear categoría' },
                ]}/>
                <CategoriesForm/>
            </Container>
        </Paper>
    )
}

export default CategoriesFormPage;