import {useEffect, useState} from "react";
import {Autocomplete, CircularProgress, TextField} from "@mui/material";
import {fetchRecords} from "../../actions/fetchRecords";
import {Add} from "@mui/icons-material";
import StyledButton from "../../styled/StyledButton";
import {FormModal, useFormModal} from "./FormModal";
import CategoriesForm from "./CategoriesForm";

const __RESOURCE__ = 'categories';
const __PLACEHOLDER__ = 'Categoría';
const __TITLE_ESP__ = 'Crear categoría';
const __AUTOCOMPLETE_ID__ = 'categories-combo-box';

const CategoriesSelect = ({ defaultValue, onChange, disabled, width = 0 }:{ defaultValue:any, onChange:any, disabled: boolean, width?:number }) => {
    const [ options, setOptions ] = useState<[]>([]);
    const [ loading, setLoading ] = useState<boolean>( true );
    const { handleModal, openModal, handleDelete } = useFormModal( __RESOURCE__ );

    useEffect( () => {
        getRecords().then(() => setLoading( false ) );
    },[]);

    async function getRecords() {
        const { categories } = await fetchRecords( __RESOURCE__ );

        const optionsArray:any = [];

        categories.map((item:any) => (
            optionsArray.push({
                label: item.name,
                uid: item.uid
            })
        ));

        setOptions(optionsArray);
    }

    return(
        <>
            <FormModal
                handleModal={ handleModal }
                openModal={ openModal }
                handleDelete={ handleDelete }
                modalTitle={ __TITLE_ESP__ }
                getRecords={ getRecords }
                component={ <CategoriesForm
                    customDismiss={ handleModal }
                    getRecords={ getRecords }
                /> }
            />

            { options.length === 0 ? (
                    <TextField
                        disabled
                        placeholder={ __PLACEHOLDER__ }
                        fullWidth
                        InputProps={{
                            endAdornment: loading ? <CircularProgress size={ 12 }/> :
                                <StyledButton
                                    fullWidth
                                    onClick={ handleModal }
                                    variant="outlined"
                                    startIcon={ <Add/> }
                                >
                                    { __TITLE_ESP__ }
                                </StyledButton>
                        }}
                    />
                ):
                <Autocomplete
                    disabled={ disabled }
                    disablePortal
                    sx={{
                        width: width ? width : '100%'
                    }}
                    loading={ loading }
                    loadingText="Cargando..."
                    defaultValue={ defaultValue ? defaultValue : undefined }
                    onChange={(event: any, newValue:any) => {
                        if(newValue) {
                            onChange( newValue.uid );
                        }
                    }}
                    renderOption={( props:any, option ) => {

                        //If is the last option
                        if( ( options.length - 1 ) === props["data-option-index"]) {
                            return(
                                <>
                                    <li  {...props}>
                                        { option.label }
                                    </li>
                                    <li>
                                        <StyledButton
                                            onClick={ handleModal }
                                            fullWidth
                                            startIcon={ <Add/> }
                                        >
                                            { __TITLE_ESP__ }
                                        </StyledButton>
                                    </li>
                                </>

                            )
                        }
                        return(   <li {...props}>
                            {option.label}
                        </li> )
                    }}
                    id={ __AUTOCOMPLETE_ID__ }
                    options={ options }
                    renderInput={(params) => <TextField {...params} label={ __PLACEHOLDER__ } />}
                />
            }
        </>
    )
}

export default CategoriesSelect;