import { Save } from '@mui/icons-material';
import { Box, Fab } from '@mui/material';
const style = {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
};

const SaveFloatingButton = ({mainAction}:{mainAction:() => void}) => {
  return (
    <Box sx={{ '& > :not(style)': { m: 1 } }}>
        <Fab
            sx={style}
            onClick={mainAction}
            color="primary"
            aria-label="add"
        >
            <Save/>
        </Fab>
    </Box>
  )
}

export default SaveFloatingButton;
