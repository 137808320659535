import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { deleteRecord } from "../../actions/deleteRecord";
import { useState } from "react";
import ReactDOM from "react-dom";
import { PDFViewer } from "@react-pdf/renderer";
import { Contract } from "../../documents/Contract";
import {fetchRecord} from "../../actions/fetchRecord";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    md: 700,
    xs: 350,
  },
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export const usePdfPrevierwModal = () => {
  const [openModalForm, setOpenModalForm] = React.useState(false);
  const [contractData, setContractData] = React.useState();
  const [modalTitle, setModalTitle] = useState<string>(" ");
  const [uid, setUid] = useState<string | null>(null);
  const handleModalForm = () => setOpenModalForm(!openModalForm);

  const getContractData = async (contractId:any) => {
    if(!contractId)
      return;

    try {
      const contractData = await fetchRecord('contracts/getRecord' , contractId);
      if(contractData) {
        setContractData(contractData)
      }
    } catch (e) {
      console.log('error ', e);
    }

  }

  const handleDelete = async () => {
    if (uid) {
      //await deleteRecord( resource, uid )
      setOpenModalForm(false); //Close modal if is open
    }
  };

  return {
    openModalForm,
    handleModalForm,
    handleDelete,
    modalTitle,
    setModalTitle,
    setUid,
    getContractData,
    contractData
  };
};

type ModalFormType = {
  handleModalForm: any;
  openModalForm: any;
  modalTitle: string;
  contractData: any;
  customDismiss?: any;

};

export const PdfPreviewModal = ({
    handleModalForm,
    openModalForm,
    modalTitle,
    contractData
}: ModalFormType) => {
  return (
    <Modal
      open={openModalForm}
      onClose={handleModalForm}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography sx={{ mb: 4 }} align="center" variant="h5">
          {modalTitle}
        </Typography>
        <PDFViewer width="100%" height={600}>
          <Contract
            data={contractData}
          />
        </PDFViewer>
      </Box>
    </Modal>
  );
};
