import React, {useEffect, useState} from "react";
import {CircularProgress, Container, Grid, Paper, TextField, Typography} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {Check} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import {postRecord} from "../../actions/postRecord";
import CategoryType from "../../types/CategoryType";
import {fetchRecord} from "../../actions/fetchRecord";
import {updateRecord} from "../../actions/updateRecord";
import StyledButton from "../../styled/StyledButton";

const componentDefaultProps = {
    title: 'característica',
    resource: 'features',
    addButtonText: 'Nueva característica',
    formPath: 'features',
}

const __TITLE__ = 'característica';
const __RESOURCE__ = 'features';

const FeaturesForm = () => {
    const { handleSubmit, setValue, control, formState: {errors}, } = useForm();
    const [ loading, setLoading ] = useState( false );
    const params = useParams();
    const [result, setResult] = useState<CategoryType>({
        name: '',
        description: ''
    });

    useEffect(() => {
        getRecord().then();
    },[]);

    const getRecord = async () => {
        if(params.uid) {
            const { feature } = await fetchRecord(componentDefaultProps.resource, params.uid);
            setValue('name', feature.name );
            setValue( 'icon', feature?.icon );
            setResult(feature);
        }
    }

    const navigate = useNavigate();

    const submitForm = async (data:any) => {

        let result:any;

        if(params.uid){
            result = await updateRecord(componentDefaultProps.resource, data, params.uid); //If updating

        }else{
            result = await postRecord(componentDefaultProps.resource, data); //If creating
        }

        if(result) {
            navigate(`/dashboard/${componentDefaultProps.resource}`);
        }
    }

    return(
        <Paper sx={{ pt: 12 }} elevation={ 0 }>
            <Container>
                <Typography sx={{ mb: 2 }} align="center" variant="h6">
                    { params.uid ? `Editar ${componentDefaultProps.title}` : `Crear ${componentDefaultProps.title}` }
                </Typography>
                <form onSubmit={handleSubmit(submitForm)}>
                    <Grid spacing={ 2 } container>
                        <Grid xs={ 12 } item>
                            <Controller
                                name="name"
                                defaultValue={ result ? result.name : undefined }
                                control={ control }
                                rules={{
                                    required: 'El titulo es requerido.'
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth
                                        onChange={ onChange }
                                        value={ value }
                                        label="Nombre"
                                    />
                                )}
                            />
                            {errors.name && <Typography variant="caption" sx={{color:'red'}}>{errors.name.message}</Typography>}
                        </Grid>
                        <Grid xs={ 12 } item>
                            <Controller
                                name="icon"
                                defaultValue={ result ? result.name : undefined }
                                control={ control }
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth
                                        multiline
                                        onChange={ onChange }
                                        value={ value }
                                        label="Icono en SVG"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={2} sx={{my: 2, justifyContent: 'center'}} container>
                        <Grid
                            sx={{
                                order: {
                                    xs: 2,
                                    md: 1
                                }
                            }}
                            xs={12}
                            sm={4} item
                        >
                            <StyledButton
                                fullWidth
                                variant="outlined"
                                onClick={() => navigate(`/dashboard/${ __RESOURCE__ }`)}
                            >
                                Cancelar
                            </StyledButton>
                        </Grid>
                        <Grid
                            sx={{
                                order: {
                                    xs: 1,
                                    md: 2
                                }
                            }}
                            xs={12}
                            sm={4}
                            item
                        >
                            <StyledButton
                                fullWidth
                                variant="contained"
                                disabled={loading}
                                startIcon={loading ? <CircularProgress color="inherit" size={12}/> :
                                    <Check/>}
                                type="submit"
                            >
                                {loading ? (params.uid ? `Actualizando ${__TITLE__}...` : `Creando ${__TITLE__}`) :
                                    (params.uid ? `Actualizar ${__TITLE__}...` : `Crear ${__TITLE__}`)}
                            </StyledButton>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </Paper>
    )
}

export default FeaturesForm;