import { Add, Check, Remove } from "@mui/icons-material";
import {
  Divider,
  IconButton,
  Paper,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import monthsArrayType from "../types/MonthsArrayType";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import formatNumberToCurrency from "../helpers/formatNumberToCurrency";
import calculateTotalPrice from "../helpers/calculateTotalPrice";
import { fetchRecord } from "../actions/fetchRecord";
import PropertyType from "../types/PropertyType";
import ItemsSelect from "./common/ItemsSelect";
import dayjs, { Dayjs } from "dayjs";

export const usePricesPlan = () => {
  const [totalMonths, setTotalMonths] = useState<number | null>(null);
  const [monthsArray, setMonthsArray] = useState<monthsArrayType[]>([]);

  return {
    totalMonths,
    setTotalMonths,
    monthsArray,
    setMonthsArray,
  };
};

export const PricesPlan = ({
  monthsArray,
  setMonthsArray,
  totalMonths,
  setTotalMonths,
  disabled,
  default_ppsm,
  monthlyPayments,
  data,
  propertyId,
}: {
  monthsArray: monthsArrayType[];
  setMonthsArray: any;
  totalMonths: number | null;
  setTotalMonths: any;
  disabled?: boolean;
  monthlyPayments?: any;
  default_ppsm?: number | null | undefined;
  displayPricesTable?: boolean;
  data?: any;
  propertyId?: string;
}) => {
  const [checkIcons, setCheckIcons] = useState<string | null>(null);
  const [property, setProperty] = useState<PropertyType | null>(null);
  const [monthsToShow, setMonthsToShow] = useState<any>(monthlyPayments);

  useEffect(() => {
    if (propertyId) {
      getRecord().then();
    }
  }, [propertyId]);

  const getRecord = async () => {
    const { property } = await fetchRecord("properties", propertyId);
    if (property) {
      setProperty(property);
    }
  };

  useEffect(() => {
    if (totalMonths) {
      handleMonthsChange(totalMonths);
    }
  }, [totalMonths]);

  const setWrittenPrice = () => {};

  const handleMonthsChange = (value: number) => {
    const years: number = Math.round(value / 12);

    let arrayMonths: monthsArrayType[] = [
      {
        selectable_months: 0,
        price_per_square_meter: 0,
      },
    ];

    setTotalMonths(value);
    for (let index = 1; index <= years; index++) {
      const months: number = (value / years) * index;
      arrayMonths.push({
        selectable_months: months,
        price_per_square_meter: default_ppsm,
      });
    }

    setMonthsArray(arrayMonths);
  };

  const triggerMonthlyPayments = () => {};

  // @ts-ignore
  const pricesTable = () => (
    <>
      <Typography>Esquema de pagos {data?.total_financing_months}</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 400 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Plazo</TableCell>
              <TableCell>Mensualidad</TableCell>
              <TableCell>Precio mensualidad con letra</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Total con letra</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {monthlyPayments.map((selectableMonth: any, index: number) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  {selectableMonth.months === 0
                    ? "De contado"
                    : `${selectableMonth.months} meses`}
                </TableCell>
                <TableCell>
                  {selectableMonth.months === 0
                    ? "N/A"
                    : formatNumberToCurrency?.format(selectableMonth.amount)}
                </TableCell>
                <TableCell>
                  {selectableMonth?.written_price_monthly ? (
                    selectableMonth.written_price_monthly
                  ) : (
                    <TextField
                      fullWidth
                      onClick={() => {
                        setCheckIcons(`written_price_monthly-${index}`);
                      }}
                      onChange={(e) => {
                        setCheckIcons(null);
                        if (e.target.value !== "") {
                          setCheckIcons(`written_price_monthly-${index}`);
                        }
                      }}
                      onBlur={(e) => {
                        setCheckIcons(null);
                      }}
                      label="Precio mensualidad con letra"
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setWrittenPrice()}
                            edge="end"
                          >
                            {checkIcons ===
                              `written_price_monthly-${index}` && <Check />}
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <p>
                    {formatNumberToCurrency?.format(
                      calculateTotalPrice(
                        selectableMonth.amount,
                        selectableMonth.months
                      )
                    )}
                  </p>
                </TableCell>
                <TableCell>
                  {selectableMonth?.written_price_total ? (
                    selectableMonth.written_price_total
                  ) : (
                    <TextField
                      fullWidth
                      onClick={() => {
                        setCheckIcons(`written_price_total-${index}`);
                      }}
                      onChange={(e) => {
                        setCheckIcons(null);
                        if (e.target.value !== "") {
                          setCheckIcons(`written_price_total-${index}`);
                        }
                      }}
                      onBlur={(e) => {
                        setCheckIcons(null);
                      }}
                      label="Precio total con letra"
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setWrittenPrice()}
                            edge="end"
                          >
                            {checkIcons === `written_price_total-${index}` && (
                              <Check />
                            )}
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  const element = (index: number, item: any) => (
    <Stack key={index} spacing={2} direction="row">
      <IconButton
        disabled={index === 0 || disabled}
        onClick={() => {
          const el = [...monthsArray];
          el.splice(index, 1);
          setMonthsArray(el);
        }}
      >
        <Remove />
      </IconButton>
      <TextField
        disabled={disabled || item?.selectable_months === 0}
        label="Meses elegibles por el cliente"
        defaultValue={Math.round(item?.selectable_months)}
        InputProps={{
          type: "number",
        }}
        onChange={(e: any) => {
          const arrElements = [...monthsArray];
          arrElements[index] = {
            selectable_months: parseInt(e.target.value),
            price_per_square_meter: arrElements[index].price_per_square_meter,
          };
          setMonthsArray(arrElements);
        }}
      />
      <TextField
        disabled={disabled}
        label={
          item?.selectable_months === 0
            ? "Precio por m² de contado"
            : `Precio por m² a ${item?.selectable_months} meses`
        }
        value={monthsArray[index]?.price_per_square_meter}
        InputProps={{
          type: "number",
        }}
        onChange={(e: any) => {
          const arrElements = [...monthsArray];
          arrElements[index] = {
            selectable_months: arrElements[index].selectable_months,
            price_per_square_meter: e.target.value,
          };
          setMonthsArray(arrElements);
        }}
      />
      <FormControlLabel
        control={
          <Switch
            checked={item?.downpayment > 0}
            onChange={(e) => {
              const arrElements = [...monthsArray];
              const element = arrElements[index];
              arrElements[index] = {
                ...element,
                downpayment: e.target.checked ? 10 : 0,
              };
              setMonthsArray(arrElements);
            }}
          />
        }
        label="Enganche"
      />

      {item?.downpayment > 0 && (
        <TextField
          disabled={disabled}
          label="% Enganche"
          value={monthsArray[index]?.downpayment}
          InputProps={{
            type: "number",
          }}
          onChange={(e: any) => {
            const arrElements = [...monthsArray];
            const element = arrElements[index];

            arrElements[index] = {
              ...element,
              downpayment: e.target.value,
            };

            setMonthsArray(arrElements);
          }}
        />
      )}
      {index === monthsArray.length - 1 && (
        <IconButton
          disabled={disabled}
          onClick={() => {
            setMonthsArray((el: any) => [...el, null]);
          }}
        >
          <Add />
        </IconButton>
      )}
    </Stack>
  );

  return (
    <>
      {property && (
        <ItemsSelect
          defaultValue={undefined}
          onChange={(e: any) => {
            setTotalMonths(e?.total_financing_months);
          }}
          disabled={false}
          resource={""}
          items={property?.financing_plans}
          placeholder="Plan de pagos a elegir"
          autocompleteId="meses"
          field="name"
          setCompleteObject
        />
      )}
      {monthsToShow ? (
        pricesTable()
      ) : (
        <Stack spacing={2}>
          <Typography variant="caption">Mensualidades</Typography>
          <Divider />
          <TextField
            disabled={disabled}
            defaultValue={totalMonths}
            InputProps={{
              type: "number",
            }}
            onBlur={(e: any) => handleMonthsChange(e.target.value)}
            label="Total de mensualidades de financiamiento"
          />
          {monthsArray.map((item: any, index: number) => element(index, item))}
        </Stack>
      )}
    </>
  );
};
