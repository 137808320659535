import { Box, Container, Fab, LinearProgress, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchRecord } from "../../../actions/fetchRecord";
import ReceiptType from "../../../types/ReceiptType";
import { Print, Save } from "@mui/icons-material";
import { updateRecord } from "../../../actions/updateRecord";
import { postRecord } from "../../../actions/postRecord";
import { useAlertMessagesHandler } from "../../../components/common/AlertMessagesHandler";
import moment from "moment";
import "moment/locale/es";
import ClientsType from "../../../types/ClientsType";
import PropertyType from "../../../types/PropertyType";
import formatNumberToCurrency from "../../../helpers/formatNumberToCurrency";
import FloatingButton from "../../../components/common/FloatingButton";
import axios from "axios";
import FinancingPlansType from "../../../types/FinancingPlanType";
import PaymentPlansType from "../../../types/PaymentPlansType";
import "../../../styles/decoupledEditor.css";
import {
  EditorWrapper,
  useCkeditorWrapper,
} from "../../../components/common/EditorWrapper";
import { formatContractNumber } from "../../../helpers/formatContractNumber";
import ClientPaymentType from "../../../types/ClientPaymentType";

const { REACT_APP_API_HOST } = process.env;

type ParamsArrayType = {
  [key: string]: string | undefined;
};

const Receipt = () => {
  moment.locale("es");
  const { uid } = useParams();
  const [receiptData, setReceiptData] = useState<ReceiptType>();
  const [progress, setProgress] = useState(0);
  const [editorDefaultContent, setEditorDefaultContent] = useState<string>("");
  const alertMessagesHandlerProps = useAlertMessagesHandler();
  const [showLoadingScreen, setShowLoadingScreen] = useState<boolean>(true);
  const [content, setContent] = useState<any>();

  const editorWrapperProps = useCkeditorWrapper();

  useEffect(() => {
    if (uid) {
      getReceipt(uid).then();
    }
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    const int = setTimeout(() => {
      clearInterval(timer);

      setShowLoadingScreen(false);
    }, 8000);

    return () => {
      clearTimeout(int);
    };
  }, []);

  useEffect(() => {
    if (content) {
      const updatedContent: string = handleContentCreation(content);
      setEditorDefaultContent(updatedContent);
    }
  }, [receiptData]);

  const getReceipt = async (receiptId: string) => {
    if (!receiptId) {
      return;
    }

    const { receipt } = await fetchRecord("receipts/getRecord", receiptId);
    let updatedContent: string = "";

    if (receipt?.content) {
      setContent(receipt.content);
      //updatedContent = handleContentCreation(receipt.content);
    } else {
      const { template } = await fetchRecord(`templates/Recibos`);
      if (template?.content && template.content !== undefined) {
        setContent(template.content);
        //updatedContent = handleContentCreation(template.content);
      }
    }

    if (receipt) {
      setReceiptData({
        ...receipt,
      });
    }
  };

  const handlePrintReceipt = async () => {
    axios({
      method: "get",
      url: `${REACT_APP_API_HOST}receipts/getPdf/${receiptData?.uid}`,
      responseType: "blob",
    })
      .then(function (response) {
        // create file link in browser's memory
        console.log("response.data ", response);
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `recibo-${receiptData?.uid}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const handleContentCreation = (originalContent: string) => {
    const clientData: ClientsType | undefined = receiptData?.contract.client;
    const propertyData: PropertyType | undefined =
      receiptData?.contract.property;
    const financingPlanData: FinancingPlansType | undefined =
      receiptData?.contract?.financing_plan;
    const paymentsPlanData: PaymentPlansType | undefined =
      receiptData?.contract?.payment_plan;
    const ClientPaymentData: ClientPaymentType | undefined =
      receiptData?.client_payment ? receiptData.client_payment : undefined;

    const paramsArray: Array<ParamsArrayType> = [
      { "{fecha_completa}": moment().format("LL") },
      {
        "{numero_recibo}": receiptData?.receiptNumber
          ? formatContractNumber(parseInt(receiptData?.receiptNumber))
          : "",
      },
      { "{numero_pago}": receiptData?.paymentNumber },
      {
        "{nombre_cliente}":
          clientData?.name &&
          `${clientData.name} ${clientData?.parentalLastName} ${clientData.maternalLastName}`,
      },
      { "{proyecto}": propertyData?.name && propertyData.name },
      { "{ubicacion}": "ubicación de prueba" },
      {
        "{cantidad}": receiptData?.amount
          ? formatNumberToCurrency?.format(receiptData.amount)
          : "",
      },
      {
        "{importe}": receiptData?.amount
          ? formatNumberToCurrency?.format(receiptData.amount)
          : "",
      },
      { "{metodo_pago}": receiptData?.paymentMethod },
      { "{lote_numero}": propertyData?.plot ? propertyData.plot : "" },
      {
        "{superficie}": propertyData?.area
          ? `${propertyData.area.toString()} m²`
          : "",
      },
      {
        "{costo_total}": paymentsPlanData?.total_price
          ? formatNumberToCurrency?.format(paymentsPlanData.total_price)
          : "",
      },
      { "{apartado_fecha}": "2 de abril de 2003" },
      { "{plazo}": financingPlanData?.name },
      {
        "{mensualidad}":
          receiptData?.paymentNumber && paymentsPlanData?.total_financing_months
            ? `${receiptData?.paymentNumber} de ${paymentsPlanData?.total_financing_months}`
            : "",
      },
      { "{manzana}": propertyData?.block },
      {
        "{saldo}": ClientPaymentData?.remainingAmount
          ? formatNumberToCurrency?.format(ClientPaymentData.remainingAmount)
          : "",
      },
      {
        "{abonado}": ClientPaymentData?.paidAmount
          ? formatNumberToCurrency?.format(ClientPaymentData.paidAmount)
          : "",
      },
      { "{concepto_correspondiente}": receiptData?.concept },
      { "{fecha_inicio}": "____________________________" },
      { "{fecha_fin}": "_____________________________" },
      { "{recibido_por}": "____________________________" },
    ];

    paramsArray.map((paramArray) => {
      Object.entries(paramArray).forEach(([key, value]) => {
        if (originalContent.includes(key) && value) {
          originalContent = originalContent.replace(key, value);
        }
      });
    });

    return originalContent;
  };

  const submitReceipt = async () => {
    handleContentCreation(editorWrapperProps.editorRef.current.getContent());

    const data = {
      content: editorWrapperProps.editorRef.current.getContent(),
    };

    editorWrapperProps.setProcessedData(data.content);

    try {
      if (receiptData?.uid) {
        const { receiptResult } = await updateRecord(
          "receipts",
          data,
          receiptData.uid
        );

        if (receiptResult) {
          alertMessagesHandlerProps.handleOpen(
            `La plantilla se actualizó correctamente`,
            "success"
          );
        }
      } else {
        const { template } = await postRecord("templates", data);

        if (template) {
          alertMessagesHandlerProps.handleOpen(
            `La plantilla ${template.name} se guardó correctamente`,
            "success"
          );
        }
      }
    } catch (e) {
      console.log("e ", e);
      alertMessagesHandlerProps.handleOpen(
        `Ocurrió un error al guardar el template: ${e}`,
        "error"
      );
    }
  };

  const LoadingScreen = () => (
    <Box
      sx={{
        p: 28,
        height: "50vh",
        width: "100%",
        backgroundColor: "white",
        position: "absolute",
        zIndex: 1000,
      }}
    >
      <Typography>Creando recibo</Typography>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );

  const containerSection = () => (
    <>
      <EditorWrapper
        editorDefaultData={editorDefaultContent}
        onSaveDoc={submitReceipt}
        onPrintDoc={handlePrintReceipt}
        {...editorWrapperProps}
      />
    </>
  );

  return (
    <>
      {showLoadingScreen && <LoadingScreen />}
      {editorDefaultContent !== "" && (
        <Container sx={{ mt: 20 }}>{containerSection()}</Container>
      )}
    </>
  );
};

export default Receipt;
