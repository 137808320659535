import { useState, useEffect } from "react";
import {
  Box,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import PropertyType from "../../types/PropertyType";
import propertyType from "../../types/PropertyType";
import { createSlug } from "../../helpers/createSlug";
import CategoriesSelect from "./CategoriesSelect";
import axios from "axios";
import { PricesModal, usePriceModal } from "./PricesModal";
import calculateMonthlyPayments from "../../helpers/calculateMonthlyPayments";
import { MonthlyPaymentsType } from "../../types/MonthlyPaymentsType";

type CreatablePropertiesType = {
  disabled: boolean;
  childPropertiesArray: PropertyType[];
  setChildPropertiesArray: any;
  defaultPropertyName: string | undefined;
  ppsm?: number;
  selectableMonthsArray?: [string] | [] | undefined;
  priceModalProps: any;
  selectedPropertyPayments: Array<MonthlyPaymentsType>;
  setSelectedPropertyPayments: any;
  updating?: boolean;
  setParentPropertyData?: any;
  parentPropertyData?: any;
  checkboxControl?: any;
  setCheckboxControl?: any;
};

const { REACT_APP_API_HOST } = process.env;

export const useCreatableProperties = () => {
  const [childPropertiesArray, setChildPropertiesArray] = useState<any>([]);
  const [checkboxControl, setCheckboxControl] = useState<any>([]);

  const [selectedPropertyPayments, setSelectedPropertyPayments] = useState<
    Array<MonthlyPaymentsType>
  >([]);
  const [parentPropertyData, setParentPropertyData] =
    useState<PropertyType | null>(null);

  const priceModalProps = usePriceModal();

  const postProperties = async (parentPropertyData?: PropertyType) => {
    let results: any = [];
    const cppa = [...childPropertiesArray];
    const childPropertiesRequests: any = [];

    if (cppa.length === 0) {
      return [];
    }
    cppa.map((property: PropertyType, index: number) => {
      property.slug = createSlug(property.name);

      if (parentPropertyData) {
        property.isProject = false;
        property.isVisible = false;
        property.images = parentPropertyData.images;
        property.features = parentPropertyData.features;
        property.description = parentPropertyData.description;
        property.location = parentPropertyData.location;
        property.coverImage = parentPropertyData.coverImage;
        property.parent_property = parentPropertyData.parent_property;
        property.zone = parentPropertyData.zone;
      }

      const financingPlansUid: any = [];

      if (
        parentPropertyData?.financing_plans &&
        parentPropertyData.financing_plans.length > 0
      ) {
        parentPropertyData.financing_plans.map((financingPlan) => {
          financingPlansUid.push(financingPlan._id);
        });

        if (checkboxControl[index]) {
          property.financing_plans = financingPlansUid;
        }
      }

      childPropertiesRequests.push({
        endpoint: property?.uid
          ? `${REACT_APP_API_HOST}properties/${property.uid}`
          : `${REACT_APP_API_HOST}properties`,
        data: { ...property },
        isUpdating: !!property?.uid,
        headers: {
          "Content-Type": "application/json",
          "x-token": localStorage.getItem("token"),
        },
      });
    });

    results = await axios.all(
      childPropertiesRequests.map(async (req: any) => {
        if (req?.isUpdating) {
          return await axios.put(req.endpoint, req.data, {
            headers: req.headers,
          });
        } else {
          return await axios.post(req.endpoint, req.data, {
            headers: req.headers,
          });
        }
      })
    );
    const createdProperties: string[] = [];
    if (results) {
      results.map((result: any) => {
        createdProperties.push(result?.data?.property?.uid);
      });
    }
    return createdProperties;
  };

  return {
    postProperties,
    childPropertiesArray,
    setChildPropertiesArray,
    checkboxControl,
    setCheckboxControl,
    setParentPropertyData,
    parentPropertyData,
    selectedPropertyPayments,
    setSelectedPropertyPayments,
    priceModalProps,
  };
};

export const CreatableProperties = ({
  childPropertiesArray,
  setChildPropertiesArray,
  disabled,
  defaultPropertyName,
  selectableMonthsArray,
  priceModalProps,
  selectedPropertyPayments,
  checkboxControl,
  setCheckboxControl,
  parentPropertyData,
}: CreatablePropertiesType) => {
  const [loadingControl, setLoadingControl] = useState<number | null>(null);

  useEffect(() => {
    if (childPropertiesArray.length === 0) {
      pushElement(0);
    }
  }, []);

  const pushElement = (index: number) => {
    setCheckboxControl((el: any) => [true]);
    setChildPropertiesArray((el: any) => [
      ...el,
      {
        name: `L-${index < 9 ? "0" : ""}${index + 1} - ${
          defaultPropertyName ? defaultPropertyName : "Proyecto"
        }`,
      },
    ]);
  };

  const element = (index: number, item: any) => (
    <Box key={index}>
      <Stack spacing={2} direction="row">
        <IconButton
          disabled={disabled}
          onClick={() => {
            const el = [...childPropertiesArray];
            el.splice(index, 1);
            setChildPropertiesArray(el);
          }}
        >
          <Remove />
        </IconButton>
        <TextField
          disabled={disabled}
          label="Nombre propiedad relacionada"
          defaultValue={`L-${index < 9 ? "0" : ""}${index + 1} - ${
            defaultPropertyName ? defaultPropertyName : "Proyecto"
          }`}
          onChange={(e: any) => {
            const arrElements = [...childPropertiesArray];
            arrElements[index] = {
              ...arrElements[index],
              name: e.target.value,
            };
            setChildPropertiesArray(arrElements);
          }}
        />
        <TextField
          disabled={disabled}
          label="Área m²"
          defaultValue={item?.area}
          onBlur={(e: any) => {
            const arrElements = [...childPropertiesArray];
            const property: propertyType = {
              ...arrElements[index],
              area: e.target.value,
            };

            arrElements[index] = property;
            setChildPropertiesArray(arrElements);
          }}
        />
        <TextField
          disabled={disabled}
          label="Manzana"
          defaultValue={item?.block}
          onBlur={(e: any) => {
            const arrElements = [...childPropertiesArray];
            const property: propertyType = {
              ...arrElements[index],
              block: e.target.value,
            };

            arrElements[index] = property;
            setChildPropertiesArray(arrElements);
          }}
        />
        <TextField
          disabled={disabled}
          label="Lote"
          defaultValue={`L-${index < 9 ? "0" : ""}${index + 1}`}
          onBlur={(e: any) => {
            const arrElements = [...childPropertiesArray];

            const mp: Array<MonthlyPaymentsType> = calculateMonthlyPayments(
              parseFloat(e.target.value),
              selectableMonthsArray
            );
            const property: propertyType = {
              ...arrElements[index],
              plot: e.target.value,
              monthly_payments: mp,
            };

            arrElements[index] = property;
            setChildPropertiesArray(arrElements);
          }}
        />
        <CategoriesSelect
          width={200}
          defaultValue={item?.category?.name}
          onChange={(uid: string) => {
            const arrElements = [...childPropertiesArray];
            arrElements[index] = {
              ...arrElements[index],
              category: uid,
            };
            setChildPropertiesArray(arrElements);
          }}
          disabled={disabled}
        />
        {/**
         <StyledButton
        variant="outlined"
        size="small"
        disabled={disabled}
        startIcon={loadingControl === index ? <CircularProgress /> : <PriceChangeRounded /> }
        onClick={() => handleClickFinancing(index)}
      >
        Planes de financiamiento
      </StyledButton>
        */}

        {index === childPropertiesArray.length - 1 && (
          <IconButton
            disabled={disabled}
            onClick={() => pushElement(index + 1)}
          >
            <Add />
          </IconButton>
        )}
      </Stack>
      {parentPropertyData?.financing_plans &&
        parentPropertyData.financing_plans.length > 0 && (
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={checkboxControl[index]}
                  onChange={(e) => {
                    const checkboxArray = [...checkboxControl];
                    if (e.target.checked) {
                      checkboxArray[index] = true;
                      setCheckboxControl(checkboxArray);
                    } else {
                      checkboxArray[index] = false;
                      setCheckboxControl(checkboxArray);
                    }
                  }}
                />
              }
              label={`Usar planes de financiamiento de ${parentPropertyData?.name}`}
            />
          </Box>
        )}
    </Box>
  );

  return (
    <>
      <PricesModal
        {...priceModalProps}
        //selectableMonthsArray={ selectableMonthsArray ? selectableMonthsArray : [] }
        defaultPropertyPayments={selectedPropertyPayments}
        childPropertiesArray={childPropertiesArray}
      />
      <Stack spacing={2}>
        {childPropertiesArray.map((item: any, index: number) =>
          element(index, item)
        )}
      </Stack>
    </>
  );
};
