import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Apartment, Badge, DocumentScanner, Menu, Receipt, SupervisedUserCircle, Web} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
const drawerWidth = 240;

type DrawerType = {
    open:boolean,
    handleDrawer: any
}
export const useDrawer = () => {
    const [open, setOpen] = React.useState(false);

    const handleDrawer = () => {
        setOpen(!open );
    };

    return {
        open,
        handleDrawer
    }
}

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MiniDrawer({
                                        open,
                                       handleDrawer,
                                   }:DrawerType) {
    const theme = useTheme();
    const navigate = useNavigate();
    const listItemIconStyles = {
        minWidth: 0,
        mr: open ? 3 : 'auto',
        justifyContent: 'center',
    }

    const listItemButtonStyles = {
        minHeight: 48,
        justifyContent: open ? 'initial' : 'center',
        px: 2.5,
    }
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawer}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <Menu />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem onClick={() => navigate('/contabilidad/clientes')} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton sx={listItemButtonStyles} >
                            <ListItemIcon sx={listItemIconStyles} >
                                <SupervisedUserCircle/>
                            </ListItemIcon>
                            <ListItemText primary="Clientes" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => navigate('/dashboard/properties')} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton sx={listItemButtonStyles} >
                            <ListItemIcon sx={listItemIconStyles} >
                                <Apartment/>
                            </ListItemIcon>
                            <ListItemText primary="Propiedades" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => navigate('/contabilidad/vendedores')} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton sx={listItemButtonStyles} >
                            <ListItemIcon sx={listItemIconStyles} >
                                <Badge/>
                            </ListItemIcon>
                            <ListItemText primary="Vendedores" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => navigate('/contabilidad/contratos')} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton sx={listItemButtonStyles} >
                            <ListItemIcon sx={listItemIconStyles} >
                                <DocumentScanner/>
                            </ListItemIcon>
                            <ListItemText primary="Contratos" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => navigate('/contabilidad/recibos')} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton sx={listItemButtonStyles} >
                            <ListItemIcon sx={listItemIconStyles} >
                                <Receipt/>
                            </ListItemIcon>
                            <ListItemText primary="Recibos" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem onClick={() => navigate('/dashboard')} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton sx={listItemButtonStyles} >
                            <ListItemIcon sx={listItemIconStyles} >
                                <Web/>
                            </ListItemIcon>
                            <ListItemText primary="Página Web" sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>

                </List>
            </Drawer>
        </Box>
    );
}
