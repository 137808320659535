import {Autocomplete, TextField} from "@mui/material";

const __PLACEHOLDER__ = 'Estado';
const __AUTOCOMPLETE_ID__ = 'state-combo-box';
const options = [
    {
        label: 'Aguascalientes',
        value: '1',
    },{
        label: 'Baja California',
        value: '2'
    },{
        label:     'Baja California Sur',

        value: '3'
    },{
        label:     'Campeche',

        value: '4'
    },{
        label:     'Chiapas',

        value: '5'
    },{
        label:     'Chihuahua',

        value: '6'
    },{
        label:     'Coahuila de Zaragoza',

        value: '7'
    },{
        label:    'Colima',

        value: '8'
    },{
        label:     'Ciudad de México',

        value: '9'
    },{
        label:     'Durango',

        value: '10'
    },{
        label:     'Guanajuato',

        value: '11'
    },{
        label: 'Guerrero',
        value: '12'
    },{
        label:     'Hidalgo',

        value: '13'
    },{
        label:     'Jalisco',

        value: '14'
    },{
        label:     'Estado de Mexico',

        value: '15'
    },{
        label:     'Michoacan de Ocampo',

        value: '16'
    },{
        label:     'Morelos',

        value: '17'
    },{
        label:   'Nayarit',
        value: '18'
    },{
        label:    'Nuevo Leon',

        value: '19'
    },{
        label:     'Oaxaca',

        value: '20'
    },{
        label:     'Puebla',

        value: '21'
    },{
        label:     'Queretaro de Arteaga',

        value: '22'
    },{
        label:     'Quintana Roo',

        value: '23'
    },{
        label:     'San Luis Potosi',

        value: '24'
    },{
        label:     'Sinaloa',

        value: '25'
    },{
        label:     'Sonora',

        value: '26'
    },{
        label:     'Tabasco',

        value: '27'
    },{
        label:     'Tamaulipas',

        value: '28'
    },{
        label:    'Tlaxcala',

        value: '29'
    },{
        label:     'Veracruz de Ignacio de la Llave',

        value: '30'
    },{
        label:     'Yucatan',

        value: '31'
    },{
        label:     'Zacatecas',

        value: '32'
    }
];

const StatesSelect = ({ defaultValue, onChange, disabled, width = 0 }:{ defaultValue:any, onChange:any, disabled: boolean, width?:number }) => {
    const loading = false;

    return(
        <>
            <Autocomplete
                disabled={ disabled }
                disablePortal
                sx={{
                    width: width ? width : '100%'
                }}
                loading={ loading }
                loadingText="Cargando..."
                defaultValue={ defaultValue ? defaultValue : undefined }
                onChange={(event: any, newValue:any) => {
                    if(newValue) {
                        onChange( newValue.value );
                    }
                }}
                renderOption={( props:any, option ) => {
                    return(   <li {...props}>
                        {option.label}
                    </li> )
                }}
                id={ __AUTOCOMPLETE_ID__ }
                options={ options }
                renderInput={(params) => <TextField {...params} label={ __PLACEHOLDER__ } />}
            />
        </>
    )
}

export default StatesSelect;