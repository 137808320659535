import {
    CircularProgress,
    Container,
    Grid,
    Paper,
} from "@mui/material";
import {useForm} from "react-hook-form";
import { Check, Edit } from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import {BreadcrumbsBar} from "../../components/common/BreadcrumbsBar";
import StyledButton from "../../styled/StyledButton";
import React, {useEffect, useState} from "react";
import {fetchRecord} from "../../actions/fetchRecord";
import PropertyType from "../../types/PropertyType";
import Box from "@mui/material/Box";
import {updateRecord} from "../../actions/updateRecord";
import {LoadingDialog, useLoadingDialog} from "../../components/common/LoadingDialog";
import WithTheme from "../../components/common/WithTheme";
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {DropzoneComponent, useDropzoneComponent} from "../../components/common/DropzoneComponent";
const __TITLE__ = 'imágenes';
const __RESOURCE__ = 'files';
const __LABEL__ = 'Imágenes';

const GalleryForm = () => {
    const dropzoneProperties = useDropzoneComponent( 100 );
    const { handleSubmit } = useForm();
    const loadingDialogProps = useLoadingDialog();
    const params = useParams();


    const [ loading, setLoading ] = useState<boolean>(false );
    const [ isFormReady, setIsFormReady ] = useState<boolean>(false );

    const navigate = useNavigate();

    const [ property, setProperty ] = useState<PropertyType>({
        slug: '',
        name: '',
        description: '',
        measures_unit: '',
        videos: [],
        images: [],
        category: '',
        keywords: '',
        status: true,
    });

    useEffect(() => {
        getRecord().then();
    },[]);

    const getRecord = async () => {
        if(params.uid) {
            const { property }:{ property:any } = await fetchRecord('properties', params.uid);

            if( property ) {
                setProperty( property );
            }
        }

        setIsFormReady( true );
    }

    const submitForm = async (data:any) => {

        const propertyData = {...property};
        loadingDialogProps.handleModal();

        setLoading(true);
        loadingDialogProps.setModalTitle(`Cargando...`);
        loadingDialogProps.setModalDescription( "No cierres ni recargues esta página.");

        const imagesArray = await dropzoneProperties.startUploading( `${ data.slug }/gallery`, propertyData.uid );

        //if imagesArray > 0 assing to main data
        if( imagesArray.length > 0 ) {
            propertyData.images = imagesArray;
        }

        setLoading(false)

        await updateRecord('properties', propertyData, params.uid );

        loadingDialogProps.setModalTitle( "Finalizando proceso." );

        loadingDialogProps.setModalTitle( "Las zonas/etapas se actualizaron correctamente." );
        loadingDialogProps.setModalDescription( "Puedes salir de la página o seguir editando.");
        setLoading(false);
    }

    return(
        <Paper sx={{ pt: 12 }} elevation={ 0 }>
            <Container maxWidth="md">
                <BreadcrumbsBar currentPath={[
                    { to: '/dashboard', label: 'Inicio' },
                    { to: `/dashboard/properties/`, label: 'Propiedades' },
                    { to: `/dashboard/properties/${ property.uid }/gallery`, label: __LABEL__ },
                    { label: params?.zone ? `Editar ${ __TITLE__ }` : `Subir ${ __TITLE__ }`},
                ]}/>
                {
                    isFormReady ?
                        (
                            <form style={{ marginTop: 16 }} onSubmit={handleSubmit(submitForm)}>
                                <Grid spacing={ 2 } container sx={{ mb: 2 }}>
                                    <DropzoneComponent
                                        { ...dropzoneProperties }
                                    />
                                </Grid>
                                {
                                    loadingDialogProps.openModal && (
                                        <Grid sx={{ my: 2 }}container>
                                            <Grid item xs={ 12 }>
                                                <LoadingDialog
                                                    { ...loadingDialogProps }
                                                    successButtonIcon={ <Edit/> }
                                                    success={ !loading }
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                {
                                    (!loadingDialogProps.openModal && !loading) && (
                                        <Grid spacing={2} sx={{my: 2, justifyContent: 'center'}} container>
                                            <Grid
                                                sx={{
                                                    order: {
                                                        xs: 2,
                                                        md: 1
                                                    }
                                                }}
                                                xs={12}
                                                sm={4} item
                                            >
                                                <StyledButton
                                                    fullWidth
                                                    variant="outlined"
                                                    onClick={() => navigate(`/dashboard/${ __RESOURCE__ }`)}
                                                >
                                                    Cancelar
                                                </StyledButton>
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    order: {
                                                        xs: 1,
                                                        md: 2
                                                    }
                                                }}
                                                xs={12}
                                                sm={4}
                                                item
                                            >
                                                <StyledButton
                                                    fullWidth
                                                    variant="contained"
                                                    disabled={ loading }
                                                    startIcon={loading ? <CircularProgress color="inherit" size={12}/> :
                                                        <Check/>}
                                                    type="submit"
                                                >
                                                    {loading ? (params.zone ? `Actualizando ${__TITLE__}...` : `Subiendo ${__TITLE__}`) :
                                                        (params.zone ? `Actualizar ${__TITLE__}...` : `Subir ${__TITLE__}`)}
                                                </StyledButton>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </form>
                        )
                        :
                        (
                            <Box
                                sx={{
                                    width: '100%',
                                    height: 400,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex'
                                }}>
                                <CircularProgress/>
                            </Box>
                        )
                }
            </Container>
        </Paper>
    )
}

export default WithTheme( GalleryForm );