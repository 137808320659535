import {useEffect, useState} from "react";
import {Autocomplete, TextField} from "@mui/material";
import {fetchRecords} from "../../actions/fetchRecords";
import StyledButton from "../../styled/StyledButton";
import {Add} from "@mui/icons-material";
import {FormModal, useFormModal} from "./FormModal";
import LocationsForm from "../../pages/locations/LocationsForm";

const __TITLE_ESP__ = 'Crear ubicación';
const __RESOURCE__ = 'locations';
const LocationsSelect = ({ defaultValue, onChange, disabled, width = 0 }:{ defaultValue:any, onChange:any, disabled: boolean, width?:number }) => {
    const [ options, setOptions ] = useState<[]>([]);
    const { handleModal, openModal, handleDelete } = useFormModal( __RESOURCE__ );
    useEffect( () => {
        getRecords()
    },[]);

    async function getRecords() {
        const { locations } = await fetchRecords( __RESOURCE__ );

        const optionsArray:any = [];

        locations.map((item:any) => (
            optionsArray.push({
                label: item.name,
                uid: item.uid
            })
        ))

        setOptions(optionsArray);
    }

    return(
        <>
            <FormModal
                handleModal={ handleModal }
                openModal={ openModal }
                handleDelete={ handleDelete }
                modalTitle={ __TITLE_ESP__ }
                getRecords={ getRecords }
                component={ <LocationsForm
                    customDismiss={ handleModal }
                    getRecords={ getRecords }
                /> }
            />
            <Autocomplete
                disabled={ disabled }
                sx={{
                    width: width ? width : '100%'
                }}
                defaultValue={ defaultValue ? defaultValue : undefined }
                onChange={(event: any, newValue:any) => {
                    if(newValue) {
                        onChange( newValue.uid );
                    }
                }}
                id="location-combo-box"
                renderOption={( props:any, option:any ) => {

                    //If is the last option
                    if( ( options.length - 1 ) === props["data-option-index"]) {
                        return(
                            <>
                                <li  {...props}>
                                    { option.label }
                                </li>
                                <li>
                                    <StyledButton
                                        onClick={ handleModal }
                                        fullWidth
                                        startIcon={ <Add/> }
                                    >
                                        { __TITLE_ESP__ }
                                    </StyledButton>
                                </li>
                            </>
                        )
                    }
                    return(   <li {...props}>
                        {option.label}
                    </li> )
                }}
                options={options}
                renderInput={(params) => <TextField {...params} label="Ubicación" />}
            />
        </>
    )
}

export default LocationsSelect;