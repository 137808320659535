import {Button, Container, Grid, Paper, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {useEffect, useState} from "react";
import {fetchRecords} from "../../actions/fetchRecords";
import PropertyType from "../../types/PropertyType";
import {Add, ChevronLeft, Delete, Edit} from "@mui/icons-material";
import {DeleteModal, useModalDelete} from "../../components/common/DeleteModal";
import StyledButton from "../../styled/StyledButton";
import {BreadcrumbsBar} from "../../components/common/BreadcrumbsBar";

const componentDefaultProps = {
    title: 'Caracteristícas',
    resource: 'features',
    addButtonText: 'Nueva caracteristíca',
    formPath: 'features'
}

const FeaturesList = () => {
    const navigate = useNavigate();
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ results, setResults ] = useState<PropertyType[]>([]);

    const {handleModal, openModal, handleDelete, modalTitle, setModalTitle,setUid } = useModalDelete( componentDefaultProps.resource );

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Nombre',
            width: 150,
            editable: true,
        },
        {
            field: 'uid',
            headerName: 'Acciones',
            width: 240,
            renderCell: (cellValues) => {
                return (
                    <Box sx={{width: '100%', justifyContent: 'space-around', display: 'flex' }}>
                        <Button
                            size="small"
                            color="primary"
                            startIcon={ <Edit/> }
                            onClick={(event) => {
                                event.stopPropagation();
                                navigate(`/dashboard/${componentDefaultProps.resource}/edit/${cellValues.id}`)
                            }}
                        >
                            Editar
                        </Button>
                        <Button
                            size="small"
                            color="error"
                            startIcon={ <Delete/> }
                            onClick={(event) => {
                                event.stopPropagation();

                                setModalTitle( cellValues.row.name ); //Set modal title

                                setUid(cellValues.id.toString()); //Set-up the uid to delete

                                handleModal(); //Open modal
                            }}
                        >
                            Eliminar
                        </Button>
                    </Box>
                );
            }
        }
    ];

    useEffect( () => {
        getRecords();
    },[]);

    async function getRecords() {
        const { features } = await fetchRecords( componentDefaultProps.resource );

        setResults(features);

        if(features) {
            setLoading(false);
        }
    }

    return(
        <>
            <DeleteModal handleDelete={handleDelete} handleModal={handleModal} openModal={openModal} modalTitle={modalTitle} getRecords={getRecords}/>
            <Paper sx={{ pt: 12 }} elevation={ 0 }>
                <Container>
                    <Grid alignContent="center" justifyContent="space-between" sx={{ mb: 4 }} container>
                        <Grid
                            xs={ 12 }
                            md={ 8 }
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                pb: {
                                    xs: 4,
                                    md: 0
                                }
                            }}
                            item
                        >
                            <BreadcrumbsBar currentPath={[
                                { to: '/dashboard', label: 'Inicio' },
                                { label: `${componentDefaultProps.title}` },
                            ]}/>
                        </Grid>
                        <Grid xs={ 12 } md={ 4 } item>
                            <StyledButton
                                fullWidth
                                variant="contained"
                                startIcon={ <Add/> }
                                onClick={ () => navigate(  `/dashboard/${ componentDefaultProps.resource }/new` ) }
                            >
                                { componentDefaultProps.addButtonText }
                            </StyledButton>
                        </Grid>
                    </Grid>
                    <Box sx={{ width: '100%', height: '100%' }}>
                        <DataGrid
                            loading={ loading }
                            autoHeight
                            rows={ results }
                            columns={ columns }
                            pageSize={ 10 }
                            disableSelectionOnClick
                        />
                    </Box>
                    <Grid mt={2} justifyContent="center" container>
                        <Grid item>
                            <Button
                                sx={{ textTransform: 'none' }}
                                startIcon={ <ChevronLeft/> }
                                onClick={ () => navigate( '/dashboard' ) }
                            >
                                Volver
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </>
    )
}

export default FeaturesList;