import {
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Check, Edit } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { BreadcrumbsBar } from "../../components/common/BreadcrumbsBar";
import StyledButton from "../../styled/StyledButton";
import { useEffect, useState } from "react";
import { fetchRecord } from "../../actions/fetchRecord";
import PropertyType from "../../types/PropertyType";
import Box from "@mui/material/Box";
import { updateRecord } from "../../actions/updateRecord";
import { ZoneType } from "../../types/ZoneType";
import {
  LoadingDialog,
  useLoadingDialog,
} from "../../components/common/LoadingDialog";
import WithTheme from "../../components/common/WithTheme";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  CreatableProperties,
  useCreatableProperties,
} from "../../components/common/CreatableProperties";
import { PricesPlan, usePricesPlan } from "../../components/PricesPlan";
import axios, { AxiosRequestHeaders } from "axios";
const __TITLE__ = "plan de financiamiento";
const __RESOURCE__ = "financingPlans";
const __LABEL__ = "Plan de financiamiento";
const { REACT_APP_API_HOST } = process.env;
const FinancingPlansForm = () => {
  const [defultPropertyName, setDefaultPropertyName] = useState<
    string | undefined
  >(undefined);
  const [zoneName, setZoneName] = useState<string | undefined>(undefined);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
  } = useForm();
  const params = useParams();
  const totalPercentage = 0;
  const pricesPlanProps = usePricesPlan();
  const creatablePropertiesProps = useCreatableProperties();

  const {
    openModal,
    handleModal,
    modalTitle,
    setModalTitle,
    modalDescription,
    setModalDescription,
  } = useLoadingDialog();

  const [loading, setLoading] = useState<boolean>(false);
  const [isFormReady, setIsFormReady] = useState<boolean>(false);

  const navigate = useNavigate();

  const [property, setProperty] = useState<PropertyType>({
    slug: "",
    name: "",
    description: "",
    measures_unit: "",
    videos: [],
    images: [],
    category: "",
    keywords: "",
    status: true,
  });

  const [zone, setZone] = useState<ZoneType>({
    name: "",
    price_per_square_meter: 0,
    price_annual_interest: 0,
    uid: "",
    property: "",
  });

  useEffect(() => {
    getRecord().then();
  }, []);

  const getRecord = async () => {
    if (params.uid) {
      const { property }: { property: any } = await fetchRecord(
        "properties",
        params.uid
      );

      if (property) {
        setProperty(property);
        setDefaultPropertyName(property.name);
      }
    }

    if (params?.zone) {
      const { zone }: { zone: any } = await fetchRecord("zones", params.zone);
      setValue("name", zone.name);
      setZoneName(zone.name);

      if (zone) {
        setZone(zone);
        if (zone?.child_properties) {
          creatablePropertiesProps.setChildPropertiesArray(
            zone.child_properties
          );
        }
      }
    }

    setIsFormReady(true);
  };

  const createFinancingPlans = async (propertyId:string | undefined, monthsArray:any) => {
    if(!propertyId || !monthsArray) {
        return;
    }
    const authToken = localStorage.getItem("token");
    const financingPlanUIDArray: any[] = [];

    const headers: AxiosRequestHeaders = {
        "Content-Type": "application/json",
        "x-token": "",
    };

    if (authToken) {
        headers["x-token"] = authToken;
    }
    const requestsFinancingPlan:Array<any> = [];
    
    monthsArray.map((month:any, index:number) => {
        const financingPlanData = {
            name: month.selectable_months === 0 ? `De contado` : `${month.selectable_months} meses`,
            property: propertyId,
            total_financing_months: month.selectable_months,
            price_per_square_meter: month.price_per_square_meter,
            //monthly_payment: mp[index], not necessary on parent property
            //total_price: calculateTotalPrice(mp[index].amount,mp[index].months),
        };

        requestsFinancingPlan.push({
            endpoint: `${ REACT_APP_API_HOST }financingPlans`,
            data: financingPlanData,
            config: {
                headers
            },
            isUpdating: false
        })

    })

    try {
        const result = await axios.all(
            requestsFinancingPlan.map(async (req: any) => {
                if (req.isUpdating) {
                    return await axios.put(req.endpoint, req.data, {
                        headers,
                    });
                } else {
                    return await axios.post(req.endpoint, req.data, {
                        headers,
                    });
                }
            })
        );
        result.map((response:any) => {
            if(response.status === 200) {
                const { uid } = response.data.financingPlan;
                financingPlanUIDArray.push( uid );
            }
        });
    }catch (e) {
        console.log(e)
    }


    return financingPlanUIDArray

}

  const submitForm = async (data: any) => {
    handleModal();

    setLoading(true);
    setModalTitle(`Cargando...`);
    setModalDescription("No cierres ni recargues esta página.");

    const propertyId = params.uid;

    const financingPlanUIDArray:any = await createFinancingPlans(propertyId, pricesPlanProps.monthsArray)

    if(financingPlanUIDArray) {
      await updateRecord('properties', {
          financing_plans: financingPlanUIDArray
      }, propertyId)
    }

    setLoading(false);

    setModalTitle("Finalizando proceso.");

    setModalTitle("Las zonas/etapas se actualizaron correctamente.");
    setModalDescription("Puedes salir de la página o seguir editando.");
    setLoading(false);
  };

  return (
    <Paper sx={{ pt: 12 }} elevation={0}>
      <Container maxWidth="md">
        <BreadcrumbsBar
          currentPath={[
            { to: "/dashboard", label: "Inicio" },
            {
              to: `/dashboard/properties/${property.uid}/financiamiento`,
              label: __LABEL__,
            },
            {
              label: params?.zone
                ? `Editar ${__TITLE__}`
                : `Crear ${__TITLE__}`,
            },
          ]}
        />
        {isFormReady ? (
          <form style={{ marginTop: 16 }} onSubmit={handleSubmit(submitForm)}>
            <Grid spacing={2} container sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <PricesPlan {...pricesPlanProps} />
              </Grid>
            </Grid>
            {defultPropertyName && zoneName && (
              <>
                <Typography variant="caption">
                  Propiedades pertenecientes a esta zona/etapa
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container>
                  <Grid item xs={12}>
                    <CreatableProperties
                      {...creatablePropertiesProps}
                      defaultPropertyName={`${property.name} - ${zoneName}`}
                      disabled={loading}
                      selectableMonthsArray={
                        property?.selectable_financing_months
                      }
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {openModal && (
              <Grid sx={{ my: 2 }} container>
                <Grid item xs={12}>
                  <LoadingDialog
                    handleModal={handleModal}
                    openModal={openModal}
                    modalTitle={modalTitle}
                    modalDescription={modalDescription}
                    percentage={totalPercentage}
                    success={!loading}
                    successButtonText={`Seguir editando...`}
                    successButtonAction={handleModal}
                    successButtonIcon={<Edit />}
                  />
                </Grid>
              </Grid>
            )}
            {!openModal && !loading && (
              <Grid
                spacing={2}
                sx={{ my: 2, justifyContent: "center" }}
                container
              >
                <Grid
                  sx={{
                    order: {
                      xs: 2,
                      md: 1,
                    },
                  }}
                  xs={12}
                  sm={4}
                  item
                >
                  <StyledButton
                    fullWidth
                    variant="outlined"
                    onClick={() => navigate(`/dashboard/${__RESOURCE__}`)}
                  >
                    Cancelar
                  </StyledButton>
                </Grid>
                <Grid
                  sx={{
                    order: {
                      xs: 1,
                      md: 2,
                    },
                  }}
                  xs={12}
                  sm={4}
                  item
                >
                  <StyledButton
                    fullWidth
                    variant="contained"
                    disabled={loading}
                    startIcon={
                      loading ? (
                        <CircularProgress color="inherit" size={12} />
                      ) : (
                        <Check />
                      )
                    }
                    type="submit"
                  >
                    {loading
                      ? params.zone
                        ? `Actualizando ${__TITLE__}...`
                        : `Creando ${__TITLE__}`
                      : params.zone
                      ? `Actualizar ${__TITLE__}...`
                      : `Crear ${__TITLE__}`}
                  </StyledButton>
                </Grid>
              </Grid>
            )}
          </form>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: 400,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
    </Paper>
  );
};

export default WithTheme(FinancingPlansForm);
