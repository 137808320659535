import axios from 'axios';
import { actionTypes } from '../actionTypes/actionTypes';

const { REACT_APP_API_HOST } = process.env;

export const startLogin = ( email:string, password:string ) => {

    const payload = {
        email,
        password
    }

    return async ( dispatch:any ) => {

        try{
            const { data } = await axios.post(
                `${ REACT_APP_API_HOST }auth/login`,
                payload
            );

            localStorage.setItem('token', data.token);
            dispatch( login(data.user.name, data.token ));
            return data;
        }catch (e:any) {
            console.log( e )
        }

    }
}

export const login = ( name:string, token:string ) => ({
    type: actionTypes.login,
    payload: {
        name,
        token
    }
})