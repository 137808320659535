import React, { useEffect, useState} from "react";
import {CircularProgress, Container, Grid, Paper, TextField, Typography} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {Check} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import {postRecord} from "../../actions/postRecord";
import {fetchRecord} from "../../actions/fetchRecord";
import {updateRecord} from "../../actions/updateRecord";
import StyledButton from "../../styled/StyledButton";
import Box from "@mui/material/Box";
import {BreadcrumbsBar} from "../../components/common/BreadcrumbsBar";
import {DropzoneComponent, useDropzoneComponent} from "../../components/common/DropzoneComponent";
import BannerType from "../../types/BannerType";

const __BACK_PATH__ = '/dashboard/banners';

const __TITLE__ = 'banner';
const __RESOURCE__ = 'banners';
const __LABEL__ = 'Banner';

const BannersForm = () => {
    const { handleSubmit, setValue, control, formState: {errors}, } = useForm();
    const [ isFormReady, setIsFormReady ] = useState<boolean>( false );
    const [ loading, setLoading ] = useState<boolean>( false );
    const { files, getInputProps, getRootProps, startUploading, progress, setCover, setFiles, croppedData, setCroppedData, setDeletedFiles, deletedFiles } = useDropzoneComponent( 1 );

    const params = useParams();

    const [result, setResult] = useState<BannerType>({
        name: '',
        image: '',
        isActive: true
    });

    useEffect(() => {
        getRecord().then();
    },[]);

    const getRecord = async () => {
        if(params.uid) {
            const { banner } = await fetchRecord(__RESOURCE__, params.uid);

            setValue('name', banner?.name );

            if( banner?.image ) {
                setCroppedData( banner?.image.url )
            }
            setResult(banner);
        }

        setIsFormReady( true );
    }

    const navigate = useNavigate();

    const submitForm = async (data:any) => {

        let record:any;

        setLoading( true );

        if( files.length > 0 ) {
            const resultUpload = await startUploading('banners');

            if( resultUpload ) {
                data.image = resultUpload[0];
            }
        }

        if(params.uid){
            record = await updateRecord(__RESOURCE__, data, params.uid); //If updating

        }else{
            record = await postRecord(__RESOURCE__, data); //If creating
        }


        setLoading( false );

        if(record) {
            navigate(`/dashboard/${__RESOURCE__}`);
        }
    }

    return(
        <Paper sx={{ pt: 12 }} elevation={ 0 }>
            <Container maxWidth="md">
                <BreadcrumbsBar currentPath={[
                    
                    { to: `/dashboard/${ __RESOURCE__ }`, label: __LABEL__ },
                    { label: params.uid ? `Editar ${ __TITLE__ }` : `Crear ${ __TITLE__ }`},
                ]}/>
                {
                    isFormReady ? (
                        <form onSubmit={handleSubmit(submitForm)}>
                            <Grid sx={{ mt: 2 }} spacing={ 2 } container>
                                <Grid xs={ 12 } item>
                                    <Controller
                                        name="name"
                                        defaultValue={ result ? result.name : undefined }
                                        control={ control }
                                        rules={{
                                            required: 'El nombre es requerido.'
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                multiline
                                                fullWidth
                                                onChange={ onChange }
                                                value={ value }
                                                label="Nombre"
                                            />
                                        )}
                                    />
                                    { errors.name && <Typography variant="caption" sx={{color:'red'} }>{errors.name.message}</Typography>}
                                </Grid>
                                <Grid xs={ 12 } item>
                                    <DropzoneComponent
                                        defaultPreview={ result?.image?.url }
                                        loading={ loading }
                                        title={ "Arrastra y suelta o selecciona una foto para el banner." }
                                        progress={ progress }
                                        getInputProps={ getInputProps }
                                        getRootProps={ getRootProps }
                                        files={ files }
                                        setCover={ setCover }
                                        setFiles={ setFiles }
                                        withCropper={ true }
                                        croppedData={ croppedData }
                                        setCroppedData={ setCroppedData }
                                        setDeletedFiles={ setDeletedFiles }
                                        deletedFiles={ deletedFiles }
                                        aspectRatio={ 1.77 }
                                    />
                                </Grid>
                            </Grid>
                            <Grid spacing={ 2 } sx={{ my: 2, justifyContent: 'center' }} container>
                                <Grid
                                    sx={{
                                        order: {
                                            xs: 2,
                                            md: 1
                                        }
                                    }}
                                    xs={ 12 }
                                    sm={ 4 } item
                                >
                                    <StyledButton
                                        fullWidth
                                        variant="outlined"
                                        onClick={ () => navigate(__BACK_PATH__ ) }
                                    >
                                        Cancelar
                                    </StyledButton>
                                </Grid>
                                <Grid
                                    sx={{
                                        order: {
                                            xs: 1,
                                            md: 2
                                        }
                                    }}
                                    xs={ 12 }
                                    sm={ 4 }
                                    item
                                >
                                    <StyledButton
                                        fullWidth
                                        variant="contained"
                                        disabled={ loading }
                                        startIcon={ loading ? <CircularProgress color="inherit" size={ 12 }/> : <Check/> }
                                        type="submit"
                                    >
                                        { loading ? (params.uid ? `Actualizando ${__TITLE__}...` : `Creando ${__TITLE__}`) :
                                            (params.uid ? `Actualizar ${__TITLE__}...` : `Crear ${__TITLE__}`) }
                                    </StyledButton>
                                </Grid>
                            </Grid>
                        </form>

                    ) : (
                        <Box
                            sx={{
                                width: '100%',
                                height: 400,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex'
                            }}>
                            <CircularProgress/>
                        </Box>
                    )
                }
            </Container>
        </Paper>
    )
}

export default BannersForm;