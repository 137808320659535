import {styled} from "@mui/styles";
import Box from "@mui/material/Box";

const StyledCircleBox = styled(Box)(() => ({
    width: 80,
    height: 80,
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
}));

export default StyledCircleBox;