import { Button, Container, Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { fetchRecords } from "../../../actions/fetchRecords";
import PropertyType from "../../../types/PropertyType";
import { Add, ChevronLeft, Delete, Edit, Preview } from "@mui/icons-material";
import {
  DeleteModal,
  useModalDelete,
} from "../../../components/common/DeleteModal";
import StyledButton from "../../../styled/StyledButton";
import { BreadcrumbsBar } from "../../../components/common/BreadcrumbsBar";

const __RESOURCE__ = "receipts";

const labels = {
  singular: "recibo",
  plural: "recibos",
};

const ReceiptsList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [results, setResults] = useState<PropertyType[]>([]);

  const {
    handleModal,
    openModal,
    handleDelete,
    modalTitle,
    setModalTitle,
    setUid,
  } = useModalDelete(__RESOURCE__);

  const columns: GridColDef[] = [
    {
      field: "receiptNumber",
      headerName: "Recibo #",
      flex: 1,
      editable: true,
    },
    {
      field: "amount",
      headerName: "Cantidad",
      flex: 1,
      editable: true,
    },
    {
      field: "contract",
      valueGetter: ({ row }) => {
        if (row?.contract?.client?.name) {
          return row.contract.client.name;
        }
        return "";
      },
      headerName: "Cliente",
      flex: 1,
      editable: true,
    },
    {
      field: "uid",
      headerName: "Acciones",
      width: 480,
      renderCell: (cellValues) => {
        return (
          <Box
            sx={{
              width: "100%",
              justifyContent: "space-around",
              display: "flex",
            }}
          >
            <Button
              startIcon={<Preview />}
              color="primary"
              onClick={(event) => {
                event.stopPropagation();
                navigate(`/contabilidad/recibos/${cellValues.id}`);
              }}
            >
              Ver recibo
            </Button>
            <Button
              size="small"
              color="primary"
              startIcon={<Edit />}
              onClick={(event) => {
                event.stopPropagation();
                navigate(
                  `/contabilidad/${labels.plural}/editar/${cellValues.id}`
                );
              }}
            >
              Editar
            </Button>
            <Button
              size="small"
              color="error"
              startIcon={<Delete />}
              onClick={(event) => {
                event.stopPropagation();

                setModalTitle(cellValues.row.name); //Set modal title

                setUid(cellValues.id.toString()); //Set-up the uid to delete

                handleModal(); //Open modal
              }}
            >
              Eliminar
            </Button>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    getRecords();
  }, []);

  async function getRecords() {
    const { receipts } = await fetchRecords(__RESOURCE__);

    setResults(receipts);

    if (receipts) {
      setLoading(false);
    }
  }

  return (
    <>
      <DeleteModal
        handleDelete={handleDelete}
        handleModal={handleModal}
        openModal={openModal}
        modalTitle={modalTitle}
        getRecords={getRecords}
      />
      <Paper sx={{ pt: 12 }} elevation={0}>
        <Container>
          <Grid
            alignContent="center"
            justifyContent="space-between"
            sx={{ mb: 4 }}
            container
          >
            <Grid
              xs={12}
              md={8}
              sx={{
                alignItems: "center",
                display: "flex",
                pb: {
                  xs: 4,
                  md: 0,
                },
              }}
              item
            >
              <BreadcrumbsBar
                currentPath={[
                  { to: "/system", label: "Sistema" },
                  { label: `${labels.plural.toUpperCase()}` },
                ]}
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <StyledButton
                fullWidth
                variant="contained"
                startIcon={<Add />}
                onClick={() => navigate(`/contabilidad/${labels.plural}/nuevo`)}
              >
                Nuevo {labels.singular}
              </StyledButton>
            </Grid>
          </Grid>
          <Box sx={{ width: "100%", height: "100%" }}>
            <DataGrid
              loading={loading}
              autoHeight
              rows={results}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
          </Box>
          <Grid mt={2} justifyContent="center" container>
            <Grid item>
              <Button
                sx={{ textTransform: "none" }}
                startIcon={<ChevronLeft />}
                onClick={() => navigate("/contabilidad")}
              >
                Volver
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </>
  );
};

export default ReceiptsList;
