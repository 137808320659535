import { Box } from '@mui/system';
import {Button, CircularProgress, Container, Grid, Paper, TextField, Typography} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { transition, enteringFormTransition, inputTransition } from "../constants/transitions";
import Logo from "./../assets/logo.svg";
import {useTheme} from "@mui/material/styles";
import {useDispatch} from "react-redux";
import {startLogin} from "../actions/auth";
import {useState} from "react";

const Login = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { handleSubmit, control, formState: {errors}, } = useForm();
    const [ loading, setLoading ] = useState<boolean>( false );

    const dispatch = useDispatch();

    const onSubmit = async (data: any) => {
        setLoading( true );

        const result:any = await dispatch( startLogin( data.email, data.password ) );

        setLoading( false );
        if(result?.user) {
            navigate('dashboard');
        }
    };

    return(
        <>
            <Box sx={{
                height: 290,
                backgroundColor: theme.palette.primary.main,
                justifyContent:'center',
                alignItems:'center',
                display:'flex'
            }}>
                <motion.img
                    initial={{
                        opacity:0,
                        y:20
                    }}
                    animate={{
                        opacity:1,
                        y:0,
                        transition: {delay: .2, ...transition}
                    }}
                    alt="logo"
                    src={Logo} style={{
                    height: 120
                }}/>
            </Box>
            <Paper elevation={ 0 }>
                <Container maxWidth="sm">
                    <form>
                        <motion.div initial='initial' animate='animate' exit='exit'>
                            <motion.span variants={enteringFormTransition}>
                                <motion.div variants={inputTransition}>
                                    <Grid mt={5} mb={2} container>
                                        <Grid xs={ 12 } item>
                                            <Controller
                                                name={"email"}
                                                control={control}
                                                rules={{
                                                    required: 'El correo es requerido.',
                                                    pattern: {
                                                      value: /^\S+@\S+$/i,
                                                        message: 'El correo no es válido.'
                                                    }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <TextField
                                                        InputProps={{
                                                            disableUnderline: true
                                                        }}

                                                        fullWidth
                                                        variant="filled"
                                                        onChange={ onChange }
                                                        value={ value }
                                                        label="Email"
                                                    />
                                                )}
                                            />
                                            {errors.email && <Typography variant="caption" sx={{ color:'red' }}>{ errors.email.message }</Typography>}
                                        </Grid>
                                    </Grid>
                                </motion.div>
                                <motion.div variants={inputTransition}>
                                    <Grid mb={2}>
                                        <Grid item>
                                            <Controller
                                                name={"password"}
                                                control={control}
                                                rules={{
                                                    required: 'Ingresa una contraseña.'
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <TextField
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            type: "password"
                                                        }}
                                                        fullWidth
                                                        variant="filled"
                                                        onChange={ onChange }
                                                        value={ value }
                                                        label="Contraseña" />
                                                )}
                                            />
                                            {errors.password && <Typography variant="caption" sx={{ color:'red' }}>{ errors.password.message }</Typography>}
                                        </Grid>
                                    </Grid>
                                </motion.div>
                                <motion.div variants={inputTransition}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        sx={{
                                            width: '100%',
                                            padding: '14px 0',
                                            borderRadius: 3.5,
                                            textTransform: 'none',
                                        }}
                                        disabled={ loading }
                                        startIcon={ loading && <CircularProgress size={ 12 } sx={{ color:'white' }}/> }
                                        onClick={ handleSubmit(onSubmit) }
                                    >
                                        Iniciar sesión
                                    </Button>
                                </motion.div>
                            </motion.span>
                        </motion.div>
                    </form>

                    <Grid mt={2}>
                        <Grid item sx={{
                            justifyContent: 'center',
                            display: 'flex',
                        }}>
                            <Typography sx={{color: '#AAAFB6'}}>Olvidé mi contraseña</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
        </>
    )
}

export default Login;