import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/material";
import { deleteRecord } from "../../actions/deleteRecord";
import { useState } from "react";
import { DeleteForever } from "@mui/icons-material";
import StyledButton from "../../styled/StyledButton";
import axios from "axios";
import { GridSelectionModel } from "@mui/x-data-grid";

const { REACT_APP_API_HOST } = process.env;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "white",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

type DeleteModalType = {
  handleModal: any;
  openModal: any;
  handleDelete: any;
  modalTitle: string;
  getRecords: any;
  selectedItems?: any;
  multipleDeletes?: () => void;
};

export const useModalDelete = (resource: string) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [modalTitle, setModalTitle] = useState<string>(" ");
  const [uid, setUid] = useState<string | null>(null);
  const [selectedItems, setSelectedItems] = useState<GridSelectionModel>([]);
  const handleModal = () => setOpenModal(!openModal);

  const handleDelete = async () => {
    if (uid) {
      await deleteRecord(resource, uid);
      setOpenModal(false); //Close modal if is open
    }
  };

  const multipleDeletes = async () => {
    const itemsRequests: any = [];
    let results: any = [];

    selectedItems.map((id) => {
      itemsRequests.push({
        endpoint: `${REACT_APP_API_HOST}files/${id}`,
        headers: {
          "Content-Type": "application/json",
          "x-token": localStorage.getItem("token"),
        },
      });
    });

    results = await axios.all(
      itemsRequests.map(async (req: any) => {
        return await axios.delete(req.endpoint, {
          headers: req.headers,
        });
      })
    );

    handleModal();

    return results;
  };

  return {
    openModal,
    handleModal,
    handleDelete,
    modalTitle,
    setModalTitle,
    setUid,
    multipleDeletes,
    selectedItems,
    setSelectedItems,
  };
};

export const DeleteModal = ({
  handleDelete,
  handleModal,
  openModal,
  modalTitle,
  getRecords,
  selectedItems,
  multipleDeletes,
}: DeleteModalType) => {
  return (
    <Modal
      open={openModal}
      onClose={handleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography color="text.primary" id="modal-modal-title" variant="h6">
          Eliminar el registro "{modalTitle}"
        </Typography>
        <Typography
          color="text.primary"
          id="modal-modal-description"
          sx={{ my: 2 }}
        >
          Esta acción no se puede deshacer
        </Typography>
        <Stack sx={{ justifyContent: "space-between" }} direction="row">
          <StyledButton variant="outlined" onClick={handleModal}>
            Cancelar
          </StyledButton>
          <StyledButton
            variant="contained"
            color="error"
            startIcon={<DeleteForever />}
            onClick={async () => {
              if (selectedItems && selectedItems.length > 0) {
                if (selectedItems.length > 0 && multipleDeletes) {
                  await multipleDeletes();
                }
              } else {
                await handleDelete();
              }

              await getRecords();
            }}
          >
            Eliminar
          </StyledButton>
        </Stack>
      </Box>
    </Modal>
  );
};
