import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    fontSize: 14,
    lineHeight: 1.5
  },
  section: {
    margin: 10,
    padding: 10,
  },
  content: {
    margin: 10,
    padding: 10,
  },
});

const text = StyleSheet.create({
  subtitle: {
    textDecoration: "underline",
    textAlign: "center",
  },
});

// Create Document Component
export const Contract = ({
    data,

}: {
  data: any,

}) => {
  const contract = data;
  const beneficiaries = contract?.beneficiaries[0];
  const beneficiariesHonorificTitle = contract?.honorificTitle && contract.honorificTitle.toUpperCase();
  const parentProperty = contract?.property?.parent_property;
  return(
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            {
                parentProperty?.facilityLogo?.url && (
                    <Image src={ parentProperty?.facilityLogo.url } style={{
                      width: 40
                    }}/>
                )
            }
          </View>
          <View style={styles.section}>
            <Text style={{ textAlign: 'center', fontWeight: 400 }}>
              CONTRATO PRIVADO DE CESIÓN DE DERECHOS DE POSESIÓN A TITULO ONEROSO
            </Text>
          </View>
          <View style={styles.section}>
            <Text>
              QUE CELEBRAN: POR UNA PARTE, EL CC.
              <b> { contract?.seller?.name.toUpperCase() } </b>, QUIEN PARA EFECTOS DE ESTE CONTRATO SE LE DENOMINARÁ
              COMO LA PARTE VENDEDORA”, Y, POR LA OTRA PARTE, EL C. { contract?.client?.name.toUpperCase() }, QUIEN COMPARECE EN ESTE ACTO POR SU PROPIO DERECHO, Y QUIEN
              EN LO SUCESIVO SE LE DENOMINARÁ "LA PARTE COMPRADORA”; AL TENOR DE LAS
              SIGUIENTES DECLARACIONES Y CLAUSULAS:
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={text.subtitle}>D E C L A R A C I O N E S:</Text>
          </View>
          <View style={styles.content}>
            <Text>
              I.- Declara la parte “LA PARTE VENDEDORA”, Bajo protesta de decir
              verdad:
            </Text>
            <Text>
              I.I.- Declara el C. { contract?.seller?.name.toUpperCase() }, que es una persona física, con
              personalidad jurídica propia, el cual se identifica mediante su
              credencial de elector expedida por el INSTITUTO NACIONAL ELECTORAL,
              con número de folio { contract?.seller?.ineNumber }, de estado civil { contract?.seller?.maritalStatus && contract?.seller?.maritalStatus.toUpperCase() } y de
              profesión Ingeniero Civil. El cual, por sus generales tiene su
              domicilio en { contract?.seller?.homeAddress && contract?.seller?.homeAddress.toUpperCase() }.
            </Text>
            <Text>
              I.I.- Declara el C. { contract?.client?.name.toUpperCase() }, que es una persona física, con
              personalidad jurídica propia, el cual se identifica mediante su
              credencial de elector expedida por el INSTITUTO NACIONAL ELECTORAL,
              con número de folio { contract?.client?.ineNumber }, de estado civil{" "}
              { contract?.client?.maritalStatus } y de ocupación { contract?.client?.occupation }. El cual, por sus
              generales tiene su domicilio en  { contract?.client?.address && contract?.client?.address.toUpperCase() }.
            </Text>
            <Text>
              Declara la parte VENDEDORA: C. { contract?.seller?.name.toUpperCase() }{" "}
              que se ostenta para la celebración
              del presente contrato con el carácter de Legítimos propietarios y
              poseedores, del inmueble ubicado en {  }, ubicado en{" "}
              { contract?.property?.streetName && contract?.property?.streetName.toUpperCase() } { contract?.property?.externalNumber && contract?.property?.externalNumber } { contract?.property?.municipality && contract.property.municipality.toUpperCase() } fracción que se acredita del acta de posesión con
              número de folio { contract?.contract_number_formatted } y expedida por el comisariado de Bienes
              Comunales de San Pedro Mixtepec.
            </Text>
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text>
              II.- Declara { contract?.client?.genre === 'M' ? 'el' : 'la' } { contract?.client?.occupation && contract?.client?.occupation.toUpperCase() } { contract?.client?.name && contract?.client?.name.toUpperCase() } en su
              carácter del “COMPRADOR”:
            </Text>
            <Text>
              Que es una persona física, con personalidad jurídica propia, de estado
              civil { contract?.client?.maritalStatus && contract?.client?.maritalStatus.toUpperCase() }, con número de teléfono{" "}
              { contract?.client?.cellphoneNumber }, de ocupación { contract?.client?.occupation && contract?.client?.occupation.toUpperCase() }; el cual se
              identifica mediante su credencial para votar, expedida por el
              Instituto Nacional Electoral con clave de elector { contract?.client?.ineNumber && contract?.client?.ineNumber.toUpperCase() }.
            </Text>
            <Text>
              Con domicilio ubicado en C { contract?.client?.homeAddress && contract?.client?.homeAddress.toUpperCase() } El cual señala como
              beneficiario, { beneficiaries?.genre === 'M' ? 'el' : 'la' } { beneficiaries?.honorificTitle && beneficiaries?.honorificTitle.toUpperCase() }. { beneficiaries?.name && beneficiaries?.name.toUpperCase() } con número de teléfono,{" "}
              { beneficiaries?.homePhoneNumber }.
            </Text>
          </View>
          <View style={styles.section}>
            <Text>
              III. - Declaran las partes contar con la capacidad suficiente para la
              realización del acto jurídico consignado en el presente documento, y
              que se reconocen la personalidad con la se ostentan y conocer el
              inmueble sobre el cual se adquirirán los derechos parcelarios, por lo
              que manifiestan su conformidad con el otorgamiento del mismo y que
              además convienen en sujetarse a las siguientes: - -{" "}
            </Text>
            <Text>
              IV.- En la celebración de este contrato no existe, dolo, error, mala
              fe o lesión a derecho alguno, “La parte compradora”, reconoce haber
              leído y entendido los alcances legales del presente contrato, sus
              derechos y obligaciones, ha leído el presente en presencia de los
              testigos y manifiestan bajo protesta de decir verdad que no se queda
              con interrogante algún sobre el predio, fracción o lote materia del
              presente, su ubicación, medidas, colindancias, legal procedencia,
              documentación y todo aquello que resulta de trascendencia para la
              legalidad y rescisión del presente instrumento.-{" "}
            </Text>
            <Text>
              Ambas partes establecen que en caso de fallecimiento de “La parte
              compradora”, antes del cumplimiento de todas y cada una de las
              obligaciones aquí contraída, este está de acuerdo con ceder los
              derechos y{" "}
            </Text>
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text>
              obligaciones a la referencia familiar, { beneficiaries?.genre === 'M' ? 'la' : 'el' } { beneficiaries?.honorificTitle && beneficiaries?.honorificTitle.toUpperCase() }. { beneficiaries?.name && beneficiaries?.name.toUpperCase() }
              con número de teléfono, { beneficiaries?.homePhoneNumber  }, quien podrá aceptar y
              dar cumplimiento total del presente documento sin necesidad de mediar
              poder notarial o carta, y “La parte vendedora” deberá extender la
              CESIÓN DE DERECHOS POSESIONARIOS A TÍTULO ONEROSO, a su favor y
              nombre, al finiquitar el precio pactado por el inmueble objeto del
              presente instrumento jurídico.
              -------------------------------------------------
            </Text>
            <Text style={text.subtitle}>C L A U S U L A S:</Text>
            <Text>
              PRIMERA. - OBJETO: El presente contrato tiene como objeto la
              enajenación de derechos parcelarios, los cuales se trasladarán de la
              parte vendedora y la cual DECLARA Y OTORGA, QUE SE OBLIGA Y COMPROMETE
              A VENDER, ÚNICA, EXCLUSIVA E IRREVOCABLEMENTE en favor de la parte
              compradora, los derechos parcelarios que ampara el lote de terreno
              identificado como, { contract?.property?.name && contract?.property?.name.toUpperCase() }, UBICADO EN LA ZONA A {" "}
              , el cual forma parte de la lotificaciòn { contract?.property?.parent_property?.name && contract?.property?.parent_property?.name.toUpperCase() }, Ubicado
              en { contract?.property?.parent_property?.fullAddress && contract?.property?.parent_property.fullAddress.toUpperCase() } y cual se
              señala en el denominado anexo identificado como número 1 A (plano
              topográfico)
            </Text>
            <Text>
              SEGUNDA. – DEL PRECIO. el precio de la venta, lo constituye la
              cantidad total de $ { contract?.property?.price && contract?.property.price  } ({ contract?.property?.fullPrice && contract?.property.fullPrice.toUpperCase()  }) importe que "la parte compradora",
              deberá pagar precisamente en el plazo establecido en la siguiente
              clausula.
            </Text>
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text>
              TERCERA. - SEGURIDAD Y GARANTÍA DE LA OBLIGACIÓN DE PAGO. para
              seguridad y garantía de la obligación de pago que adquiere en este
              acto, "la parte compradora", se obliga y compromete a entregar a "la
              parte vendedora", en los plazos siguientes: El monto cual se señala en
              el denominado anexo identificado como número 1 B, respetando el plazo
              de 24 en caso de finiquitar antes de los 24 meses o bien el plazo de
              36 meses.{" "}
            </Text>
            <Text>
              El pago de su primera mensualidad se realizará con fecha: { contract?.first_payment_date && contract?.first_payment_date }
            </Text>
            <Text>
              El pago se hará de manera mensual en cualquiera de las siguientes
              formas: mediante efectivo entregado directamente en el domicilio de la
              “Inmobiliaria Puerto Escondido” ubicada en Calle Segunda norte #306,
              Sector Reforma B, Centro, Puerto Escondido Oaxaca 71980 o vía
              transferencia electrónica a la cuenta que se le refiera.
            </Text>
            <Text>
              CUARTA. - RESCISIÓN A PRETEXTO DE LESIÓN. La compraventa a que este
              contrato se refiere, no podrá ser rescindida en ningún tiempo a
              pretexto de lesión, en virtud de que el precio y formas de otorgarlo
              ha sido pactado y acordado por las partes de común acuerdo, previendo
              cualquier situación futura que pudiera poner en riesgo el acto
              jurídico que se consigna en el presente documento. ----------
            </Text>
            <Text>
              QUINTA. - ENTREGA. "la parte vendedora" se obliga y compromete a
              entregar a "la parte compradora", la posesión jurídica de los lotes,
              el inmueble objeto de la presente, LOTES NÙMERO 01 Y 15, ETAPA 01,
              UBICADO EN LA ZONA A de la lotificación COSTAZUL RESIDENCIAL en el
              momento que se liquide la cantidad estipulada en la cláusula segunda,
              se obliga a cubrir todos los gastos, costos, derechos, impuestos, que
              se generen con motivo de la posesión de este a partir de que obtenga
              la posesión total, real y material de los lotes. “La parte compradora”
              se compromete a delimitar los terrenos, objeto de este presente
              contrato respetando las respectivas mojoneras a fin de evitar
              invasiones u otro tipo de situaciones que pudieran llegar a ocurrir.
            </Text>
            <Text></Text>
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text>
              SEXTA. – DEL ACTA DE POSESIÓN. Las partes contratantes pactan que el
              acta de posesión se expedirá una vez que se finiquite el pago del lote
              de terreno, y una vez hecho esto, se acudirá ante las autoridades
              Comunales de San Pedro Mixtepec, y previo pago de derechos de la parte
              compradora, se firmará de conformidad de la parte vendedora ante las
              autoridades comunales de dicho núcleo comunal y se procederá a expedir
              en favor de la parte compradora el acta de posesión definitiva en
              favor del comprador.{" "}
            </Text>
            <Text>
              SÉPTIMA. - SANEAMIENTO. "la parte vendedora" responderá en términos de
              ley a "la parte compradora”, del saneamiento para el caso de evicción.
            </Text>
            <Text>
              OCTAVA. - PENA CONVENCIONAL. En caso de no hacer el respectivo pago,
              en la fecha señalada el pago generará una penalización del 5% de
              interés moratorio y deberá pagarse antes del vencimiento de la
              siguiente mensualidad. Igualmente, ambas partes de común acuerdo
              establecen para el caso de incumplimiento de las obligaciones que
              respectivamente les corresponde, acuerdan que: en caso de que "la
              parte compradora", incumpla con alguna de sus obligaciones,
              especialmente para el caso de que no hubiese realizado dos pagos
              consecutivos de los que se refiere la cláusula tercera o no quisiere
              recibir el lote o inmueble objeto de la presente, este contrato de
              compraventa quedara sin efecto legal alguno, teniendo la facultad "la
              parte vendedora" de enajenar a título oneroso el lote inmueble objeto
              de la presente a un tercero, quien será adquirente de buena fe, con la
              única obligación por parte de "la parte vendedora" de realizar la
              devolución de las cantidades entregadas por "la parte compradora",
              excepto la cantidad establecida como pena convencional siendo ésta la
              equivalente al veinticinco por ciento del valor de la operación total.
              asimismo, "la parte compradora" acepta, que por ningún motivo podrá
              reclamar daños y perjuicios o cualquier otra prestación diversa, por
              lo que, se hace constar que para el caso de que "la parte compradora"
              rehusare recibir el excedente de las cantidades entregadas, "la parte
              vendedora" únicamente tendrá la obligación de consignar en el juzgado
              respectivo a favor de "la parte compradora", la cantidad que le
              corresponda de acuerdo a la presente cláusula y notificada que sea,
              dicha consignación se tendrá por ambas partes como finiquito de
              cuentas y sin derecho a reclamación alguna por parte de los aquí
              contratantes, es decir, no tendrán cosa ni cantidad alguna que
              reclamar en materia agraria, civil, penal, mercantil, o de cualquier
              otra índole, y en razón de los términos a que se contrae esta
              cláusula.
            </Text>
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text>
              NOVENA. - Las partes que intervienen en el presente contrato, se
              someten a la jurisdicción y Tribunales de Puerto Escondido, Mixtepec,
              Juquila, Oaxaca, renunciando al que por domicilio en el futuro les
              corresponda, en todo lo relacionado a la interpretación y al
              cumplimiento del mismo. - - - - - Ambas partes estando conformes con
              el contenido y clausulado del presente contrato, el cual ratifican en
              todo su contenido, por no contener cláusula contraria a la moral y al
              derecho y para constancia lo firman ante la presencia de los testigos,
              el día treinta de agosto del año dos mil veintitrés, al margen en cada
              una de sus hojas y al calce en esta última para todos los efectos
              legales a que haya lugar.
            </Text>
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text>_______________________</Text>
          </View>
        </Page>
      </Document>
  )
}
