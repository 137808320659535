import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ButtonPropsColorOverrides, Stack } from "@mui/material";
import { deleteRecord } from "../../actions/deleteRecord";
import { useState } from "react";
import { DeleteForever } from "@mui/icons-material";
import StyledButton from "../../styled/StyledButton";
import axios from "axios";
import { GridSelectionModel } from "@mui/x-data-grid";
import { DistributiveOmit, OverridableStringUnion } from "@mui/types";
const { REACT_APP_API_HOST } = process.env;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

type ConfirmationModalType = {
  handleModal: any;
  openModal: any;
  handleAction: any;
  modalTitle: string;
  getRecords: any;
  selectedItems?: any;
  actionButtonTitle: string;
  color?: OverridableStringUnion<
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning",
    ButtonPropsColorOverrides
  >;
  multipleDeletes?: () => void;
};

export const useConfirmationModal = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [uid, setUid] = useState<string | null>(null);
  const [selectedItems, setSelectedItems] = useState<GridSelectionModel>([]);
  const [actionButtonTitle, setActionButtonTitle] = useState("");
  const handleModal = () => setOpenModal(!openModal);

  const multipleDeletes = async () => {
    const itemsRequests: any = [];
    let results: any = [];

    selectedItems.map((id) => {
      itemsRequests.push({
        endpoint: `${REACT_APP_API_HOST}files/${id}`,
        headers: {
          "Content-Type": "application/json",
          "x-token": localStorage.getItem("token"),
        },
      });
    });

    results = await axios.all(
      itemsRequests.map(async (req: any) => {
        return await axios.delete(req.endpoint, {
          headers: req.headers,
        });
      })
    );

    handleModal();

    return results;
  };

  return {
    openModal,
    handleModal,
    modalTitle,
    setModalTitle,
    setUid,
    multipleDeletes,
    selectedItems,
    setSelectedItems,
  };
};

export const ConfirmationModal = ({
  handleAction,
  handleModal,
  openModal,
  modalTitle,
  getRecords,
  selectedItems,
  multipleDeletes,
  actionButtonTitle,
  color,
}: ConfirmationModalType) => {
  return (
    <Modal
      open={openModal}
      onClose={handleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography color="text.primary" id="modal-modal-title" variant="h6">
          {modalTitle}
        </Typography>
        <Typography
          color="text.primary"
          id="modal-modal-description"
          sx={{ my: 2 }}
        >
          Esta acción no se puede deshacer
        </Typography>
        <Stack sx={{ justifyContent: "space-between" }} direction="row">
          <StyledButton variant="outlined" onClick={handleModal}>
            Cancelar
          </StyledButton>
          <StyledButton
            variant="contained"
            color={color ? color : "primary"}
            startIcon={<DeleteForever />}
            onClick={async () => {
              await handleAction();
              window.location.reload();
            }}
          >
            {actionButtonTitle}
          </StyledButton>
        </Stack>
      </Box>
    </Modal>
  );
};
