import { Box, TextField, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { Controller, Control } from 'react-hook-form';
import { red } from '@mui/material/colors';
import {createSlug} from "../../helpers/createSlug";
type SlugCreatorType = {
    fullName: string | undefined;
    createdSlug: any;
    setCreatedSlug: any;
    control: Control,
    errors: any,
    setValue: any,
    disabled: boolean,
    placeholder?: string
}

export const useSlugCreator = () => {
    const [ createdSlug, setCreatedSlug ] = useState<string | undefined>(undefined);

    return {
        createdSlug,
        setCreatedSlug,
    }
}

export const SlugCreator = ({ fullName, createdSlug, setCreatedSlug, control, errors, setValue, disabled, placeholder }:SlugCreatorType) => {

    useEffect(() => {
        if(createdSlug) {
            setValue( 'slug', createdSlug );
        }
    },[ createdSlug ]);

    useEffect(() => {
        if( fullName !== undefined ) {
            const clearedString = clearString(fullName);
            setCreatedSlug( clearedString );
            setValue( 'slug', clearedString );
        }
    },[ fullName ]);

    const clearString = ( string:string ) => {
        if(string) {
            return createSlug( string );
        }
    }

    return(
        <Box>
            <Controller
                name="slug"
                control={ control }
                rules={{
                    required: '¡Asegúrate de elegir un ID único!'
                }}
                render={ ( { field: { value, onChange } } ) => (
                    <TextField
                        disabled={ disabled }
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        onChange={ (e) => {
                            onChange(e);
                            setValue('slug', clearString(e.target.value))
                        }}
                        placeholder={ placeholder ? placeholder : 'nombre-propiedad' }
                        label="ID único"
                        value={ value }
                    />
                )}
            />
            {
                errors && (
                    <Typography variant="caption" sx={{ color: red[200] }}>{errors?.slug?.message}</Typography>
                )
            }
        </Box>

    );
};
