import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import StyledButton from "../../styled/StyledButton";
import React from "react";
import { IconButton, Stack, TextField } from "@mui/material";
import { Remove } from "@mui/icons-material";
import { MonthlyPaymentsType } from "../../types/MonthlyPaymentsType";
import PropertyType from "../../types/PropertyType";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  height: "auto",
  p: 4,
};

type PriceMonthlyPaymentsModalType = {
  openModal: boolean;
  handleModal: any;
  updatePropertyPaymentsArray: (a: number, b: number) => void;
  defaultPropertyPayments: Array<MonthlyPaymentsType>;
  propertyPaymentsArray: Array<MonthlyPaymentsType>;
  calculatePrices: (a: any) => void;
  deletePropertyPaymentsArray: (index: number) => void;
  setPropertyPaymentsArray: any;
  childPropertiesArray: Array<PropertyType>;
  selectedPropertyIndex: number | null;
};

export const usePriceModal = () => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [pricesArray, setPricesArray] = React.useState<number[]>([]);
  const [propertyPaymentsArray, setPropertyPaymentsArray] = React.useState<
    Array<MonthlyPaymentsType>
  >([]);
  const [selectedPropertyIndex, setSelectedPropertyIndex] = React.useState<
    number | null
  >(null);
  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const updatePropertyPaymentsArray = (price: number, index: number) => {
    const ppa: Array<MonthlyPaymentsType> = propertyPaymentsArray;
    ppa[index] = {
      months: ppa[index].months,
      amount: price,
    };

    setPropertyPaymentsArray(ppa);
  };

  const deletePropertyPaymentsArray = (index: number) => {
    const ppa: Array<MonthlyPaymentsType> = [...propertyPaymentsArray];
    ppa.splice(index, 1);
    setPropertyPaymentsArray(ppa);
  };

  const calculatePrices = (selectableMonthsArray: any) => {
    const pa: number[] = pricesArray;

    selectableMonthsArray.map((monthlyPayment: any, index: number) => {
      pa.push(
        monthlyPayment.price_per_square_meter * monthlyPayment.selectable_months
      );
    });

    setPricesArray(pa);
  };

  return {
    openModal,
    handleModal,
    updatePropertyPaymentsArray,
    calculatePrices,
    deletePropertyPaymentsArray,
    propertyPaymentsArray,
    setPropertyPaymentsArray,
    selectedPropertyIndex,
    setSelectedPropertyIndex,
  };
};

export const PricesModal = ({
  openModal,
  handleModal,
  updatePropertyPaymentsArray,
  defaultPropertyPayments,
  deletePropertyPaymentsArray,
  setPropertyPaymentsArray,
  propertyPaymentsArray,
  childPropertiesArray,
  selectedPropertyIndex,
}: PriceMonthlyPaymentsModalType) => {
  React.useEffect(() => {
    setPropertyPaymentsArray(defaultPropertyPayments);
  }, [defaultPropertyPayments]);

  console.log("propertyPaymentsArray ", propertyPaymentsArray);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal ? openModal : false}
      onClose={handleModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Box sx={style}>
          <Grid sx={{ mt: 2 }} spacing={2} container>
            <Stack spacing={2}>
              {propertyPaymentsArray.map(
                (monthlyPayment: MonthlyPaymentsType, index: number) => {
                  return (
                    <Stack key={index} direction="row" spacing={2}>
                      {index !== 0 && (
                        <IconButton
                          sx={{
                            borderRadius: 2,
                          }}
                          disabled={index === 0}
                          onClick={() => {
                            deletePropertyPaymentsArray(index);
                          }}
                        >
                          <Remove />
                        </IconButton>
                      )}
                      <TextField
                        name={`${monthlyPayment.months}_${index + 1}`}
                        fullWidth
                        //defaultValue={ area ? monthlyPayment.price_per_square_meter * area : undefined }
                        defaultValue={
                          monthlyPayment.amount ? monthlyPayment.amount : 0
                        }
                        onBlur={(e) =>
                          updatePropertyPaymentsArray(
                            parseFloat(e.target.value),
                            index
                          )
                        }
                        label={
                          monthlyPayment.months === 0
                            ? "Precio de contado"
                            : `Mensualidad a ${monthlyPayment.months} meses`
                        }
                      />
                      <TextField
                        name={`${monthlyPayment.months}_${index + 1}`}
                        fullWidth
                        //defaultValue={ area ? monthlyPayment.price_per_square_meter * area : undefined }
                        defaultValue={
                          monthlyPayment.amount ? monthlyPayment.amount : 0
                        }
                        onBlur={(e) =>
                          updatePropertyPaymentsArray(
                            parseFloat(e.target.value),
                            index
                          )
                        }
                        label={
                          monthlyPayment.months === 0
                            ? "Precio de contado"
                            : `Mensualidad a ${monthlyPayment.months} meses`
                        }
                      />
                    </Stack>
                  );
                }
              )}
            </Stack>
            <Grid
              order={{
                xs: 2,
                md: 1,
              }}
              xs={12}
              md={6}
              item
            >
              <StyledButton variant="outlined" fullWidth onClick={handleModal}>
                Cancelar
              </StyledButton>
            </Grid>
            <Grid
              order={{
                xs: 1,
                md: 2,
              }}
              xs={12}
              md={6}
              item
            >
              <StyledButton
                variant="contained"
                fullWidth
                onClick={() => {
                  const cpa: Array<PropertyType> = [...childPropertiesArray];
                  if (selectedPropertyIndex !== null) {
                    cpa[selectedPropertyIndex].monthly_payments =
                      propertyPaymentsArray;
                  }

                  handleModal();
                }}
              >
                Aceptar
              </StyledButton>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};
