import { useEffect, useState } from "react";
import { CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Check } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { postRecord } from "../../actions/postRecord";
import CategoryType from "../../types/CategoryType";
import { fetchRecord } from "../../actions/fetchRecord";
import { updateRecord } from "../../actions/updateRecord";
import StyledButton from "../../styled/StyledButton";
import { SlugCreator, useSlugCreator } from "./SlugCreator";

const __FORM_ID__ = "form-category";

const componentDefaultProps = {
  title: "categoría",
  resource: "categories",
  addButtonText: "Nueva categoría",
  formPath: "categories",
};

const CategoriesForm = ({
  customDismiss,
  getRecords,
}: {
  customDismiss?: any;
  getRecords?: any;
}) => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const { createdSlug, setCreatedSlug } = useSlugCreator();
  const [fullName, setFullName] = useState<string | undefined>(undefined);
  const [result, setResult] = useState<CategoryType>({
    name: "",
    description: "",
  });

  useEffect(() => {
    getRecord().then();
  }, []);

  const getRecord = async () => {
    if (params.uid) {
      const { category } = await fetchRecord(
        componentDefaultProps.resource,
        params.uid
      );
      setValue("name", category.name);
      setValue("description", category.description);
      setValue("slug", category.slug);
      setResult(category);
    }
  };

  const navigate = useNavigate();

  const submitForm = async (data: any) => {
    setLoading(true);
    let result: any;

    if (params.uid) {
      result = await updateRecord(
        componentDefaultProps.resource,
        data,
        params.uid
      ); //If updating
    } else {
      result = await postRecord(componentDefaultProps.resource, data); //If creating
    }

    if (result) {
      getRecords && getRecords();
      setLoading(false);
      customDismiss ? customDismiss() : navigate("/dashboard/categories");
    }
  };

  return (
    <form id={__FORM_ID__} onSubmit={handleSubmit(submitForm)}>
      <Grid spacing={2} container>
        <Grid xs={12} item>
          <Controller
            name="name"
            defaultValue={result ? result.name : undefined}
            control={control}
            rules={{
              required: "El titulo es requerido.",
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                onBlur={(e) => setFullName(e.target.value)}
                onChange={onChange}
                value={value}
                label="Nombre"
              />
            )}
          />
          {errors.name && (
            <Typography variant="caption" sx={{ color: "red" }}>
              {errors.name.message}
            </Typography>
          )}
        </Grid>
        <Grid xs={12} item>
          <SlugCreator
            disabled={loading}
            fullName={fullName}
            createdSlug={createdSlug}
            setCreatedSlug={setCreatedSlug}
            control={control}
            errors={errors}
            setValue={setValue}
            placeholder={`nombre-categoria`}
          />
        </Grid>
        <Grid xs={12} item>
          <Controller
            name="description"
            defaultValue={result ? result.description : undefined}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                onChange={onChange}
                value={value}
                multiline
                label="Descripción"
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} sx={{ my: 2, justifyContent: "center" }} container>
        <Grid
          sx={{
            order: {
              xs: 2,
              md: 1,
            },
          }}
          xs={12}
          sm={4}
          item
        >
          <StyledButton
            fullWidth
            variant="outlined"
            onClick={
              customDismiss
                ? customDismiss
                : () => navigate("/dashboard/categories")
            }
          >
            Cancelar
          </StyledButton>
        </Grid>
        <Grid
          sx={{
            order: {
              xs: 1,
              md: 2,
            },
          }}
          xs={12}
          sm={4}
          item
        >
          <StyledButton
            fullWidth
            variant="contained"
            disabled={loading}
            startIcon={
              loading ? (
                <CircularProgress color="inherit" size={12} />
              ) : (
                <Check />
              )
            }
            onClick={handleSubmit(submitForm)}
            type="button"
          >
            {loading ? "Creando categoría..." : "Crear categoría"}
          </StyledButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default CategoriesForm;
